import { BaseResourceModel } from "./base-resource.model";

export class HistoricoSolicitacaoClassificacaoOculta extends BaseResourceModel {

    static readonly MAX_LENGTH = 1000;

    constructor(
        public id?: number,
        public idSolicitacaoClassificacao?: number,
        public dsJustificativa?: string,
        public isVisivel?: boolean,
        public dataHistorico?: string,
        public loggedUserName?: string,
        public loggedUsernameKeycloack?: string
    ) {
        super(id);
    }

    static fromJson(jsonData: any): HistoricoSolicitacaoClassificacaoOculta {
        return Object.assign(new HistoricoSolicitacaoClassificacaoOculta(), jsonData);
    }
}
