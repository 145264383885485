import { GlobalValues } from "../../shared-values/global-values";

export const ValidatorMessageValues = {
    validator: {
        required: GlobalValues.msg.validador.required,
        minlength: GlobalValues.msg.validador.minlength,
        maxlength: GlobalValues.msg.validador.maxlength,
        pattern: GlobalValues.msg.validador.pattern,
        email: GlobalValues.msg.validador.email,
        cep: GlobalValues.msg.validador.cep,
        invalidDate: GlobalValues.msg.validador.invalidDate,
        maximumNumberSelectedOptionsExceeded: GlobalValues.msg.validador.maximumNumberSelectedOptionsExceeded,
        link: GlobalValues.msg.validador.link
    }
};

