import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MensagemRetorno } from '../shared-models/model/mensagem-retorno';
import { BaseService } from './base-service';
import { ProtocolarSeiDTO } from '../shared-models/dto/protocolar-sei-dto';
import { PublicacaoPortariaSei } from '../shared-models/model/publicacao-portaria-sei.model';
@Injectable({
    providedIn: 'root'
})
export class IntegracaoSeiService extends BaseService {
    constructor(protected http: HttpClient) {
        super(http);
        this.urlBase += '/integracao-sei';
    }
    criarNumeroProtocolo(protocolarSeiDto: ProtocolarSeiDTO): Observable<MensagemRetorno> {
        return this.http.post<MensagemRetorno>(`${this.urlBase}/protocolar`, protocolarSeiDto);
    }
    publicarPortaria(publicacaoPortariaSei: PublicacaoPortariaSei): Observable<MensagemRetorno> {
        return this.http.post<MensagemRetorno>(`${this.urlBase}/publicar`, publicacaoPortariaSei);
    }
    getTiposProcedimentos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${this.urlBase}/procedimentos`);
    }
    getTiposDocumentos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${this.urlBase}/tiposDocumentos`);
    }
    getUnidades(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${this.urlBase}/unidades`);
    }
}
