import { BaseResourceModel } from "./base-resource.model";
import { SolicitacaoClassificacao } from "./solicitacao-classificacao.model";
import { RequerimentoReconsideracao } from './requerimento-reconsideracao.model';
import { Classificacao } from './classificacao.model';
import { DescritorConteudo } from './descritor-conteudo.model';

export class Recurso extends BaseResourceModel {

    constructor(
        public id?: number,
        public solicitacaoClassificacao?: SolicitacaoClassificacao,
        public requerimentoReconsideracao?: RequerimentoReconsideracao,
        public dataCriacao?: Date,
        public statusDeferimento?: any,
        public justificativaAnalise?: string,        
        public classificacaoAtribuida?: Classificacao,
        public descritoresConteudoAtribuidos?: DescritorConteudo[],
    ) {
        super();
    }

    static fromJson(jsonData: any): Recurso {
        return Object.assign(new Recurso(), jsonData);
    }

}
