import { Injectable } from '@angular/core';
import { ClassindDTO } from '../shared-models/model/classind-dto.model';
import { BaseService } from './base-service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PATH } from '../shared-utils/consts/const-path-endpoints-util';
import { ServicePessoa } from '../shared-models/class/service-pessoa';
import { MensagemRetorno } from '../shared-models/model/mensagem-retorno';

@Injectable({
    providedIn: 'root'
})
export class PessoaGenericService<T extends ClassindDTO> extends BaseService implements ServicePessoa {

    protected option = 'option';
    protected full = 'full';
    protected paramLabel = '?label=';
    protected endpointPessoaEmail = 'pessoaemails';

    constructor(protected http: HttpClient) {
        super(http);
        this.path = PATH.PESSOAS;
    }

    buscarTodosPorLabel(label: string, pathEndPoint: string): Observable<T[]> {
        return this.http.get<T[]>(`${this.urlBase}/${pathEndPoint}/${this.option}${this.paramLabel}${label}`);
    }

    buscarPorId(id: string, pathEndPoint: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${pathEndPoint}/${id}`);
    }

    buscarTodosLista(pathEndPoint: string): Observable<T[]> {
        return this.http.get<T[]>(`${this.urlBase}/${pathEndPoint}`);
    }

    buscarListaPorIdELabel(id: number, label: string, pathEndPoint: string): Observable<T[]> {
        return this.http.get<T[]>(`${this.urlBase}/${pathEndPoint}/${this.option}/${id}${this.paramLabel}${label}`);
    }

    buscarPorSigla(sigla: string, pathEndPoint: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${pathEndPoint}/${sigla}`);
    }

    buscarPorNome(nome: string, pathEndPoint: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${pathEndPoint}/${nome}`);
    }

    buscarPorCodigo(codigo: number, pathEndPoint: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${pathEndPoint}/${codigo}`);
    }

    buscarPorCEP(nome: string, pathEndPoint: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${pathEndPoint}/${this.option}/${nome}`);
    }

    removerPorId(id: number) {
        return this.http.delete(`${this.urlBase}/${this.path}/${id}`);
    }

    salvarPorObjeto(obj: T) {
        return this.http.post(`${this.urlBase}/${this.path}`, obj);
    }

    editarPorIdPorObjeto(id: number, obj: T) {
        return this.http.put(`${this.urlBase}/${this.path}/${id}`, obj);
    }

    buscarPorNumeroDocumento(numeroDocumento: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${this.path}/${numeroDocumento}`);
    }

    buscarFullPorNumeroDocumento(numeroDocumento: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${this.path}/${this.full}/${numeroDocumento}`);
    }

    buscarPorCPF(cpf: string, pathEndPoint: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${pathEndPoint}/${cpf}`);
    }

    salvarTermoPorObjeto(pathEndPoint: string, obj: T): Observable<MensagemRetorno> {
        return this.http.post<MensagemRetorno>(`${this.urlBase}/${pathEndPoint}`, obj);
    }

    /**
     * Busca a Pessoa pelo E-mail.
     * @param email email
     */
    buscarPorEmail(email: string): Observable<T> {
        return this.http.get<T>(`${this.urlBase}/${this.endpointPessoaEmail}/${email}`);
    }

    getTodasPessoas(): Observable<T[]> {
        return this.http.get<T[]>(`${this.urlBase}/${this.path}`);
    }
}

