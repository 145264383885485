<div class="title">
    <h1 mat-dialog-title>{{ 'TRADUCAO' | translate: translationValues.emailRegistration }}</h1>
    <span>{{ 'TRADUCAO' | translate: translationValues.receiveNewsSubscriptionInstruction }}</span>
</div>

<div mat-dialog-content>
    <form [formGroup]="form">
        <app-input-text title="{{ 'TRADUCAO' | translate: translationValues.name }}" 
            [formGroup]="form" controlName="nome" [maxlength]="MAX_LENGHT.NAME">
        </app-input-text>

        <app-input-email title="{{ 'TRADUCAO' | translate: translationValues.email }}" 
            [formGroup]="form" controlName="email" [maxlength]="MAX_LENGHT.EMAIL">
        </app-input-email>

        <app-input-email title="{{ 'TRADUCAO' | translate: translationValues.confirmEmail }}" 
            [formGroup]="form" controlName="emailConfirm" [maxlength]="MAX_LENGHT.EMAIL" [blockPaste]="true">
        </app-input-email>

        <app-input-multi-select titulo="{{ 'TRADUCAO' | translate: translationValues.groupOfWorks }}" 
            [formGroup]="form" controlName="grupos" 
            [opcoes]="grupoSegmentoMercadoOptions" [atributo]="grupoAttribute">
        </app-input-multi-select>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="primary">Cancelar</button>
    <button mat-raised-button (click)="subscribe()" color="primary">Confirmar</button>
</div>