import { BaseResourceModel } from './base-resource.model';

export class Emissora extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
        public situacao?: string
    ) {
        super();
    }

    static fromJson(jsonData: any): Emissora {
        return Object.assign(new Emissora(), jsonData);
    }
}
