import { StatusSolicitacaoClassificacaoEnum } from 'src/app/shared/shared-models/enums/status-solicitacao-classificacao.enum';
import { MensagemRetornoKeys } from 'src/app/shared/shared-values/mensagem-retorno-keys';
import { GerenciadorProcessoItemButtonTypeEnum, GerenciadorProcessoListData } from '../shared-components/gerenciador-processo/model/gerenciador-processo-list-data.model';
import { ROLES } from "../shared-utils/consts/const-roles";

export const TriagemValues = {

    header: {
        title: 'Triagem',
    },

    lists: [
        {
            title: 'Inscrições processuais',
            statusId: StatusSolicitacaoClassificacaoEnum.TRIAGEM,
            permissoes: {
                editar:     ROLES.TRIAGEM_INSCRICAO_PROCESSUAL_EDITAR,
                visualizar: ROLES.TRIAGEM_INSCRICAO_PROCESSUAL_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
        } as GerenciadorProcessoListData,

        {
            title: 'Pendências',
            statusId: StatusSolicitacaoClassificacaoEnum.DEVOLVIDO,
            permissoes: {
                editar:     ROLES.TRIAGEM_PENDENCIAS_EDITAR,
                visualizar: ROLES.TRIAGEM_PENDENCIAS_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
        } as GerenciadorProcessoListData,

        {
            title: 'Sobrestados',
            statusId: StatusSolicitacaoClassificacaoEnum.SOBRESTADO,
            permissoes: {
                editar:     ROLES.TRIAGEM_SOBRESTADO_EDITAR,
                visualizar: ROLES.TRIAGEM_SOBRESTADO_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
        } as GerenciadorProcessoListData,

        {
            title: 'Reciclados',
            statusId: StatusSolicitacaoClassificacaoEnum.TRIAGEM,
            permissoes: {
                editar:     ROLES.TRIAGEM_INSCRICAO_PROCESSUAL_EDITAR,
                visualizar: ROLES.TRIAGEM_INSCRICAO_PROCESSUAL_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
            hideIfEmpty: true,
            isOrigemLegado: true,
        } as GerenciadorProcessoListData,
    ],

    btn: {
        action: {
            label: 'Conferência Documental',
            tooltip: 'Conferência Documental',
            icon: 'grading'
        },

        urlLinkMidiaExterna: {
            icon: 'touch_app',
            tooltip: 'Acessar Link'
        }
    },

    formBtn: {
        save: {
            icon: 'save',
            title: 'salvar'
        },
        clear: {
            icon: 'undo',
            title: 'Recuperar'
        },
        cancel: {
            icon: 'clear',
            title: 'cancelar'
        },
        archive: {
            icon: 'archive',
            title: 'Incluir temporariamente'
        }
    },

    conferenciaDocumental: {
        devolucao: {
            titulo: {
                pt: `Devolução de inscrição processual`,
                en: `Return of procedural registration`,
                es: `Devolución de registro procesal`
            },
            mensagem: {
                pt: `Esta mensagem é temporária, neste momento do processo estamos devolvendo
            ao requerente a sua inscrição processual com uma ou mais justificativas com o porquê do retorno.
            Nesta etapa futuramente será criado um sistema de envio de email para notificação da movimentação.

            O status da solicitação irá para "devolvido" e ficará na lista de pendências, até que o requente corrija
            o problema e reencaminhe para lista de triagem novamente.`,

                en: `This message is temporary, at this point in the process we are returning
                to the applicant his procedural registration with one or more justifications for the reason for the return.
                In this step, an email sending system will be created in the future to notify the movement.`,

                es: `Este mensaje es temporal, en este punto del proceso estamos regresando
                al solicitante su registro procesal con una o más justificaciones del motivo de la devolución.
                En este paso, se creará un sistema de envío de correo electrónico en el futuro para notificar el movimiento.`

            },
            btnTitulo: {
                pt: `Retornar a lista de triagem`,
                en: `Return to screening list`,
                es: `Regresar a la lista de cribado`
            }
        }
    },

    errorText: {
        incorrectExtension: MensagemRetornoKeys.INCORRET_EXTENSION,
    }
};
