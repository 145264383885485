import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthTokenService } from './auth/auth-token.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { MaterialDesignModule } from './material-design/material-design.module';
import { LocalStorageService } from './services/local-storage.service';
import { RedirectService } from './services/redirect.service';
import { SessionStorageService } from './services/session-storage.service';
import { TokenGuardService } from './services/token-guard.service';
import { TokenUtilService } from './services/token-util.service';


@NgModule({
    imports: [
        CommonModule,
        MaterialDesignModule
    ],
    exports: [
        MaterialDesignModule
    ],
    providers: [
        SessionStorageService,
        TokenGuardService,
        LocalStorageService,
        AuthService,
        TokenUtilService,
        RedirectService,
        AuthTokenService,
        AuthGuard
    ]
})
export class CoreModule { }
