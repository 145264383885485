import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-dialogo-informacao',
    templateUrl: './dialogo-informacao.component.html',
    styleUrls: ['./dialogo-informacao.component.scss']
})
export class DialogoInformacaoComponent {
    titulo: string;
    mensagem: string;
    btnTitulo: string;

    constructor(
        public dialogRef: MatDialogRef<DialogoInformacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogoInformacaoModel) {
        dialogRef.disableClose = true;
        this.titulo = data.titulo;
        this.mensagem = data.mensagem;
        this.btnTitulo = data.btnTitulo;
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}

export class DialogoInformacaoModel {
    constructor(public titulo: string, public mensagem: string, public btnTitulo: string) {
    }
}
