<div *ngIf="!flag.dadosCarregados else view">
    <app-spinner></app-spinner>
</div>
<ng-template #view>
    <div [hidden]="flag.editMode" class="view-mode-content">
        <div class="btn-editor" *ngIf="true">
            <button *ngIf="flag.editModeView && !onlyRead" mat-icon-button type="button" mat-button color="primary"
                (click)="ativateModeEdition()" matTooltip="editar" matTooltipPosition="below">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <ckeditor [editor]="editorLib" [disabled]="readonly" [config]="{}" [data]="data"
            (ready)="onlyView($event, editor)">
        </ckeditor>
    </div>
    <div [hidden]="!flag.editMode">
        <div class="header-inputs">
            <mat-radio-group aria-label="Seletor idioma" class="radio-btns" (change)="chosenLanguage($event.value)"
                [(ngModel)]="languageAcronym">
                <mat-radio-button value="pt" color="primary">PT</mat-radio-button>
                <mat-radio-button value="en" color="primary">EN</mat-radio-button>
                <mat-radio-button value="es" color="primary">ES</mat-radio-button>
            </mat-radio-group>

            <div class="btns">
                <button *ngIf="!isTermoUso()" mat-icon-button type="button" mat-button color="primary"
                    (click)="salvarModeloTextoCustomizado()" matTooltip="{{values.component.btn.save.title.pt}}"
                    matTooltipPosition="below">
                    <mat-icon>{{values.component.btn.save.icon}}</mat-icon>
                </button>
                <button *ngIf="isTermoUso()" mat-icon-button type="button" mat-button color="primary"
                    (click)="updateTermoUso()" matTooltip="{{values.component.btn.updateTermoUso.title.pt}}"
                    matTooltipPosition="below">
                    <mat-icon>{{values.component.btn.updateTermoUso.icon}}</mat-icon>
                </button>

                <button mat-icon-button type="button" mat-button color="primary" (click)="clear()"
                    matTooltip="{{values.component.btn.clear.title.pt}}" matTooltipPosition="below">
                    <mat-icon>{{values.component.btn.clear.icon}}</mat-icon>
                </button>

                <button mat-icon-button type="button" mat-button color="primary" (click)="cancel()"
                    matTooltip="{{values.component.btn.cancel.title.pt}}" matTooltipPosition="below">
                    <mat-icon>{{values.component.btn.cancel.icon}}</mat-icon>
                </button>
            </div>
        </div>
        <ckeditor class="editor" [editor]="editorLib" [config]="config" [data]="data" (ready)="onReady($event, editor)">
        </ckeditor>
    </div>
</ng-template>
