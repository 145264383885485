import { Observable } from 'rxjs';
import { HistoricoClassificacao } from './../../shared-models/model/historico-classificacao.model';
import { BaseResourceService } from './base-resource.service';
import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HistoricoClassificacaoResourceService extends BaseResourceService<HistoricoClassificacao> {

    constructor(protected injector: Injector) {
        super('/historico-classificacoes', injector, HistoricoClassificacao.fromJson);
    }

    getAllBySolicitacaoClassificacao(idSolicitacaoClassificacao: number): Observable<HistoricoClassificacao[]> {
        return this.http.get<HistoricoClassificacao[]>(
            `${this.urlResource}/solicitacao-classificacao/${idSolicitacaoClassificacao}`
        );
    }

}
