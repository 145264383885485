import { EditorCustomTemplateValues } from './../../shared-components/editor/editor-custom-template/editor-custom-template-values';
import { BaseResourceModel } from "./base-resource.model";

export class ModeloTextoCustomizado extends BaseResourceModel {

    constructor(
        public tipoModelo?: number,
        public pt = EditorCustomTemplateValues.templateDefault.pt,
        public en = EditorCustomTemplateValues.templateDefault.en,
        public es = EditorCustomTemplateValues.templateDefault.es
    ) {
        super();
    }

    static fromJson(jsonData: any): ModeloTextoCustomizado {
        return Object.assign(new ModeloTextoCustomizado(), jsonData);
    }
}
