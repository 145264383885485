import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from '../shared-utils/auth-helper';
import { AnaliseService } from 'src/app/views/private/analise/analise.service';
import { PublicacaoService } from 'src/app/views/private/publicacao/publicacao.service';
import { CONST_ROUTER_UTIL } from '../shared-utils/consts/const-router-util';
import { StatusSolicitacaoClassificacaoEnum } from './../shared-models/enums/status-solicitacao-classificacao.enum';
import { TriagemService } from 'src/app/views/private/triagem/triagem.service';


@Injectable({
    providedIn: 'root'
})
export class NavegacaoUtilService {

    constructor(
        private router: Router,
        private authHelper: AuthHelper,
        private triagemService: TriagemService,
        private analiseTecnicaService: AnaliseService,
        private publicacaoService: PublicacaoService,
    ) { }

    public toLogin() {
        this.authHelper.logout();
    }

    public toHome() {
        this.router.navigate([CONST_ROUTER_UTIL.ROTA_RAIZ]);
    }

    public toCadastroAcesso() {
        this.router.navigate([CONST_ROUTER_UTIL.CADASTRO_ACESSO]);
    }

    public toAvisoCadastroCPF() {
        this.router.navigate([CONST_ROUTER_UTIL.AVISO_CADASTRO_CPF]);
    }

    public toAvisoCadastroEstrageiro() {
        this.router.navigate([CONST_ROUTER_UTIL.AVISO_CADASTRO_ESTRANGEIRO]);
    }

    public toProcessos() {
        this.router.navigate([CONST_ROUTER_UTIL.PROCESSO]);
    }

    public toTriagem() {
        this.router.navigate([CONST_ROUTER_UTIL.TRIAGEM]);
    }

    public toAcessoRestrito() {
        this.router.navigate(['acesso-restrito']);
    }

    public toTriagemByStatus(statusSolicitacao: StatusSolicitacaoClassificacaoEnum) {
        this.triagemService.goToTriagemByStatus(statusSolicitacao);
    }

    public toAnaliseTecnica(idSegmentoMercado: number, statusSolicitacao: StatusSolicitacaoClassificacaoEnum) {
        return this.analiseTecnicaService.goToAnaliseTecnica(idSegmentoMercado, statusSolicitacao);
    }

    public toPublicacao(statusSolicitacao: StatusSolicitacaoClassificacaoEnum) {
        return this.publicacaoService.goToPublicacaoByStatus(statusSolicitacao);
    }

}
