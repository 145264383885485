import { BaseResourceModel } from './base-resource.model';
import { SegmentoMercado } from './segmento-mercado.model';

export class Categoria extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
        public segmentosMercado?: SegmentoMercado[]
    ) {
        super();
    }

    static fromJson(jsonData: any): Categoria {
        return Object.assign(new Categoria(), jsonData);
    }


}
