import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { MensagemRetornoKeys } from 'src/app/shared/shared-values/mensagem-retorno-keys';
import { Values } from '../shared-models/class/values';
import { HttpStatus } from '../shared-models/enums/http-status.enum';
import { InformacaoSistema } from '../shared-models/model/informacao-sistema';
import { MensagemRetornoValues } from '../shared-values/mensagem-retorno-values';
import { MessageStatusValues } from '../shared-values/messages/message-status.values';
import { MessageStatusEnum } from './../shared-values/messages/message-status.enum';

export enum NotFoundErrorHandling {
    ERROR,
    ALERT,
    IGNORE
}

enum StyleClass {
    SUCCESS = 'style-success',
    ERROR = 'style-error',
    WARNING = 'style-warn',
    INFO = 'style-info'
}

@Injectable({
    providedIn: 'root'
})
export class MensageriaService {

    idioma$: Observable<any>;
    flag: any;
    informacoes: InformacaoSistema;
    values: Values;

    constructor(
        @Inject('InformacaoSistema') private sistema: BehaviorSubject<InformacaoSistema>,
        private snackBar: MatSnackBar,
        public translate: TranslateService
    ) {
        this.sistema.subscribe(infos => {
            this.informacoes = infos;
            this._initFlags();
            this.values = {
                component: MensagemRetornoValues,
                custom: {
                    status: MessageStatusValues
                }
            };
        });
    }

    private _initFlags() {
        this.flag = {
            siglaIdioma: 'pt'
        };
    }

    private _checkIdioma() {
        if (this.informacoes.isAcessoInterno()) {
            return;
        }

        this.idioma$ = this.translate.get('TRADUCAO_TS');
        this.idioma$
            .pipe(first())
            .subscribe(sigla => {
                // tratamento caso nao tenha carregado o translate (ex: backend off)
                if (sigla == 'TRADUCAO_TS') {
                    sigla = 'pt';
                }
                this.flag.siglaIdioma = sigla;
            });
    }

    getLanguage(): string {
        this._checkIdioma();
        return this.flag.siglaIdioma;
    }

    getTranslation(messageObj: any) {
        this._checkIdioma();
        return messageObj[this.flag.siglaIdioma];
    }

    showSaveSuccess(messageKey = MensagemRetornoKeys.SAVE_SUCCESS) {
        this.showMensagemSucesso(messageKey);
    }

    showMensagemSucesso(messageKey = MensagemRetornoKeys.SUCCESS) {
        this._showMessageKey(messageKey, MessageStatusEnum.SUCCESS, StyleClass.SUCCESS);
    }

    showMensagemErro(messageKey = MensagemRetornoKeys.UNEXPECTED_ERROR) {
        this._showMessageKey(messageKey, MessageStatusEnum.ERROR, StyleClass.ERROR);
    }

    showMensagemAlerta(messageKey: string) {
        this._showMessageKey(messageKey, MessageStatusEnum.ALERT, StyleClass.WARNING);
    }

    showMensagemInformativa(messageKey: string) {
        this._showMessageKey(messageKey, MessageStatusEnum.INFO, StyleClass.INFO);
    }

    private _showMessageKey(messageKey: string, statusKey: MessageStatusEnum, style: StyleClass) {
        this._checkIdioma();

        let messageObj = this.values.component[messageKey];
        if (!messageObj) {
            messageObj = this.values.component[MensagemRetornoKeys.UNEXPECTED_ERROR];
        }
        let message = messageObj[this.flag.siglaIdioma];
        let status = this.values.custom.status[statusKey][this.flag.siglaIdioma];

        this.snackBar.open(message, status, {
            panelClass: [style],
            duration: 10000
        });
    }

    showError(message: string) {
        let status = this.values.custom.status[MessageStatusEnum.ERROR][this.flag.siglaIdioma];
        this.snackBar.open(message, status, {
            panelClass: [StyleClass.ERROR],
            duration: 10000
        });
    }


    handleApiError(error: any, notFoundErrorHandling: NotFoundErrorHandling = NotFoundErrorHandling.ERROR) {
        let message = error.error;

        if (Array.isArray(message)) {
            message = message[0].msg;
        }

        if (!message || typeof message !== 'string') {
            message = MensagemRetornoKeys.UNEXPECTED_ERROR;
        }

        if (message === MensagemRetornoKeys.NO_RESULT_FOUND) {
            if (notFoundErrorHandling == NotFoundErrorHandling.ALERT) {
                this.showMensagemAlerta(message);
                return;
            }
            if (notFoundErrorHandling == NotFoundErrorHandling.IGNORE) {
                return;
            }
        }

        this.showMensagemErro(message);
    }

    /* API Error Check */

    isApiBusinessError(error: any) {
        return error.status === HttpStatus.CONFLICT;
    }

    isSpecificApiBusinessError(error: any, expectedKeyMsg: string) {
        return this.isApiBusinessError(error) && error.error === expectedKeyMsg;
    }

    isAlreadyRegistered(error: any) {
        return this.isSpecificApiBusinessError(error, MensagemRetornoKeys.ITEM_ALREADY_REGISTERED);
    }

}
