import { Injectable, Injector } from '@angular/core';
import { ModuloAnaliseSegmentoMercado } from '../../shared-models/model/modulo-analise-segmento-mercado.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class ModuloAnaliseSegmentoMercadoResourceService extends BaseResourceService<ModuloAnaliseSegmentoMercado> {

    constructor(protected injector: Injector) {
        super('/modulos-analise-segmentos-mercado', injector, ModuloAnaliseSegmentoMercado.fromJson);
    }

    getAllSegmentosMercadoByModulo(idModulo: number) {
        return this.http.get<any>(`${this.urlResource}/get-segmentos-by-modulo/${idModulo}`);
    }

    getModuloAnaliseIdBySegmento(idSegmentoMercado: number) {
        return this.http.get<number>(`${this.urlResource}/get-modulo-by-segmento/${idSegmentoMercado}`);
    }
    
}
