import { Injectable, Injector } from '@angular/core';
import { Classificacao } from '../../shared-models/model/classificacao.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class ClassificacaoResourceService extends BaseResourceService<Classificacao> {

    constructor(protected injector: Injector) {
        super('/classificacoes', injector, Classificacao.fromJson);
    }
}
