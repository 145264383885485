import { AdaptiveDialogValues } from "src/app/shared/shared-components/dialog/adaptive-dialog/adaptive-dialog.component";
import { ROLES } from "src/app/shared/shared-utils/consts/const-roles";
import { GlobalAttributeValues } from "src/app/shared/shared-values/global-attribute.values";
import { GlobalValues } from "src/app/shared/shared-values/global-values";

const global = GlobalValues;
const globalAttribute = GlobalAttributeValues;
const enderecoMJ = `<p>Ministério da Justiça e Segurança Pública</p>
                <p>Esplanada dos Ministérios, Palácio da Justiça, Bloco T, Anexo II 3º andar, sala 313.</p>
                <p>Cep: 70064-900 / Brasília-DF.</p>`;

export const ProcessoValues = {

    permissoes: {
        requerente: ROLES.COMPOSITE_REQUERENTE,
        cadastrar: ROLES.SOLICITACAO_CLASSIFICACAO_CADASTRAR,
        editar: ROLES.SOLICITACAO_CLASSIFICACAO_EDITAR,
        excluir: ROLES.SOLICITACAO_CLASSIFICACAO_EXCLUIR,
        visualizar: ROLES.SOLICITACAO_CLASSIFICACAO_VISUALIZAR,
        autorizarPublicacao: ROLES.SOLICITACAO_CLASSIFICACAO_AUTORIZAR_PUBLICACAO,
        reconsideracaoSolicitar: ROLES.REQUERIMENTO_RECONSIDERACAO_CADASTRAR,
    },
    
    table: {
        columnsToTitle: [
            globalAttribute.segmentoMercado.title,
            globalAttribute.tituloNacional.title,
            globalAttribute.tituloOriginal.title,
        ]
    },

    tableEmpty: {
        msg: 'Nenhum registro encontrado.',
        docs: 'Nenhum documento encontrado.'
    },

    header: {
        title: {
            pt: 'Processos',
            en: 'Processes',
            es: 'Procesos'
        },

        titleOcultarObras: {
            pt: 'Ocultar Obras',
            en: '',
            es: ''
        },

        searchBy: {
            pt: `${global.searchBy.pt}`,
            en: `${global.searchBy.en}`,
            es: `${global.searchBy.es}`
        },

        input: {
            segmentoMercadoSearch: {
                formName: 'idSegmento',
                title: {
                    pt: `${globalAttribute.segmentoMercado.title.pt}`,
                    en: `${globalAttribute.segmentoMercado.title.en}`,
                    es: `${globalAttribute.segmentoMercado.title.es}`
                },
            },

            protocoloSearch: {
                formName: 'protocolo',
                title: {
                    pt: `Protocolo`,
                    en: `Protocol`,
                    es: `Protocolo`
                },
            },

            tituloBrasilSearch: {
                formName: 'tituloBrasil',
                title: {
                    pt: `${globalAttribute.tituloNacional.title.pt}`,
                    en: `${globalAttribute.tituloNacional.title.en}`,
                    es: `${globalAttribute.tituloNacional.title.es}`,
                },
            },

            tituloOriginalSearch: {
                formName: 'tituloOriginal',
                title: {
                    pt: `${globalAttribute.tituloOriginal.title.pt}`,
                    en: `${globalAttribute.tituloOriginal.title.en}`,
                    es: `${globalAttribute.tituloOriginal.title.es}`,
                },
            },

            tituloSerieSearch: {
                formName: 'tituloObraSerie',
                title: {
                    pt: `${globalAttribute.nmTituloObraSerie.title.pt}`,
                    en: `${globalAttribute.nmTituloObraSerie.title.en}`,
                    es: `${globalAttribute.nmTituloObraSerie.title.es}`,
                },
            },

            statusSearch: {
                formName: 'idStatus',
                title: {
                    pt: `${global.status.pt}`,
                    en: `${global.status.en}`,
                    es: `${global.status.es}`
                },
            },

            visibilidadeSearch: {
                formName: 'visibilidade',
                title: {
                    pt: `visibilidade`,
                    en: `visibility`,
                    es: `visibilidad`
                },
            },

            dataDouInicial: {
                formName: 'dataDouInicial',
                title: {
                    pt: `${globalAttribute.dataDou.title.pt} inicial`,
                    en: `start ${globalAttribute.dataDou.title.en}`,
                    es: `${globalAttribute.dataDou.title.es} de inicio`
                },
            },

            dataDouFinal: {
                formName: 'dataDouFinal',
                title: {
                    pt: `${globalAttribute.dataDou.title.pt} final`,
                    en: `end ${globalAttribute.dataDou.title.en}`,
                    es: `${globalAttribute.dataDou.title.es} de finalización`
                },
            },
        },

        btn: {
            create: {
                icon: 'add_circle',
                tooltip: {
                    pt: 'Cadastrar solicitação',
                    en: 'Register request',
                    es: 'Nueva solicitud'
                }
            },
            descricao: {
                pt: "Cadastrar",
                en: "Register",
                es: "Registrar"
            },
            skipCreate: {
                icon: 'view_list',
                tooltip: 'Lista de classificações'
            },
            editSolicitation: {
                icon: 'history_edu',
                tooltip: 'editar Solicitação'
            },

            goBack: {
                icon: 'arrow_back',
                tooltip: {
                    pt: 'Voltar',
                    en: 'Go back',
                    es: 'volver'
                }
            }
        },
    },

    solicitation: {
        title: {
            pt: `Inscrição processual`,
            en: `Procedural registration`,
            es: `Inscripción procesal`
        },
        titleInstruction: {
            pt: 'Instruções de uso',
            en: 'Instructions for use',
            es: 'Instrucciones de uso'
        },
        subTitleInstruction: {
            pt: 'Formulário de inscrição',
            en: 'Application form',
            es: 'Formulario de inscripción'
        },
        edit: { icon: 'create' },
        viewDetails: GlobalValues.btn.viewDetails,
        complete: {
            icon: 'check_circle',
            tooltip: {
                pt: 'Dados preenchidos',
                en: 'Data filled in',
                es: 'Datos rellenados'
            }
        },
        incomplete: {
            icon: 'report',
            tooltip: {
                pt: 'Dados incompletos',
                en: 'Incomplete data',
                es: 'Datos incompletos'
            }
        },
        modoJustificativa: {
            icon: 'report_problem',
            tooltip: {
                pt: 'Pendências a serem resolvidas',
                en: 'Pending issues to be resolved',
                es: 'Asuntos pendientes por resolver'
            },
            itemLista: {
                icon: 'campaign',
                original: {
                    pt: 'Conteúdo Original:  ',
                    en: 'Original Content:  ',
                    es: 'Contenido original:  '
                },
                justificativa: {
                    pt: 'Justificativa:  ',
                    en: 'Justification:  ',
                    es: 'Justificación:  '
                }
            }
        },

        form: {
            infoEssenciais: {
                icon: 'auto_stories',
                title: {
                    pt: 'Informações essenciais',
                    en: 'Essential information',
                    es: 'Información esencial'
                },
                placeholder: {
                    dsComplementoIdentificacao: 'ex. Trailer'
                }
            },

            infoEspecificas: {
                icon: 'map',
                title: {
                    pt: 'Informações específicas',
                    en: 'Specific information',
                    es: 'Información específica'
                },
            },

            uploadDocumentos: {
                icon: 'file_download',
                title: {
                    pt: 'Anexo de documentos',
                    en: 'Document Attachment',
                    es: 'Adjunto de documento'
                },
                table: {
                    action: GlobalValues.action,
                    delete: GlobalValues.delete,
                    tipoDocumento: GlobalAttributeValues.tipoDocumento.title,
                    viewDocument: GlobalValues.viewDocument
                }
            },

            requerente: {
                icon: 'account_circle',
                title: {
                    pt: 'Informações do requerente',
                    en: 'Applicant Information',
                    es: 'Información del aplicante',
                },
            },

            infoProcesso: {
                icon: 'verified',
                title: {
                    pt: 'Informações do processo',
                    en: 'Process information',
                    es: 'Procesar informacion'
                },
                noInfo: {
                    pt: 'No momento não há informações disponíveis.',
                    en: 'There is currently no information available.',
                    es: 'Actualmente no hay información disponible.'
                }
            },

            justificativas: {
                icon: 'feedback',
                title: {
                    pt: 'Justificativas pendentes',
                    en: 'Pending justifications',
                    es: 'Pendiente de justificaciones'
                },
            },
        },

        dialog: {
            titulo: {
                pt: 'Inscrição processual completa',
                en: 'Full procedural registration',
                es: 'Registro procesal completo',

            },
            mensagem: {
                pt: `Obrigado por completar as etapas e enviar os dados solicitados.
            Em breve estaremos analisando a documentação e assim que terminarmos.
            entraremos em contato.`,
                en: `Thank you for completing the steps and submitting the requested data.
                We'll be reviewing the documentation soon and as soon as we're done.
                We will be in touch.`,
                es: `Gracias por completar los pasos y enviar los datos solicitados.
                Revisaremos la documentación pronto y tan pronto como hayamos terminado.
                Estaremos en contacto.`
            },
            btnTitulo: {
                pt: `Estou ciente`,
                en: `I know`,
                es: `Estoy consciente`
            }
        },

        btn: {
            enviarInscricao: {
                pt: `Enviar inscrição`,
                en: `Send registration`,
                es: `Enviar la inscripción`
            },

            return: {
                icon: ' assignment_return',
                tooltip: 'retornar para processos'
            },
            modeEditionUseInstruction: {
                icon: 'edit',
                tooltip: 'Habilitar modo de edição'
            },
            sendRevisao: {
                pt: 'Enviar revisão',
                en: 'Send Review',
                es: 'Enviar revisión'
            },
        },

        limit: {
            input: {
                max: {
                    textArea: 400,
                    text: 150
                }
            }
        }
    },

    justificativa: {
        dialog: {
            titulo: {
                pt: `Reenvio de Inscrição processual`,
                en: `Submission of procedural application`,
                es: `Presentación de solicitud procesal`
            },
            mensagem: {
                pt: `Obrigado por corrigir as observações solicitadas.
            Sua inscrição voltou para a triagem para uma nova avaliação.`,
                en: `Thank you for correcting the requested remarks.
                Your application has returned to screening for a re-evaluation.`,
                es: `Gracias por corregir los comentarios solicitados.
                Su solicitud ha vuelto a ser examinada para una nueva evaluación.`
            },
            btnTitulo: {
                pt: `OK`,
                en: `OK`,
                es: `OK`
            }
        },

        btn: {
            urlLinkMidiaExterna: {
                icon: 'touch_app',
                tooltip: {
                    pt: 'Acessar Link',
                    en: 'Access Link',
                    es: 'Enlace de acceso'
                }
            }
        }
    },

    infoMidiasFisicas: {
        dialog: {
            titulo: {
                pt: `<p>Endereço para envio de mídias físicas</p><p>(DVD, CD, Blu-ray)</p>`,
                en: `<p>Address for shipping physical media</p><p>(DVD, CD, Blu-ray)</p>`,
                es: `<p>Dirección para el envío de medios físicos</p><p>(DVD, CD, Blu-ray)</p>`
            },
            mensagem: {
                pt: enderecoMJ,
                en: enderecoMJ,
                es: enderecoMJ
            },
            btnTitulo: {
                pt: `OK`,
                en: `OK`,
                es: `OK`
            }
        }
    },

    createContext: {
        title: 'Cadastro',
        actionMode: 'cadastrar'
    },

    editContext: {
        title: 'Editar',
        actionMode: 'editar'
    },

    item: {
        title: 'Edição',
        btnDelete: {
            icon: 'delete',
            tooltip: 'deletar classificação'
        },
        edition: {
            actionMode: 'editar'
        },
        msg: {
            deleteConfirmation: {
                text: 'Tem certeza que deseja excluir',
                title: 'Confirmar exclusão'
            },
            visualizationInfo: {
                text: 'Espaço reservado para implentação futura da visualização de dados da inscrição processual. Podemos retornar ao sistema?',
                title: 'Módulo visualização'
            }
        }
    },

    formInputs: {
        name: {
            title: 'Nome da Solicitação',
            input: 'nome',
            qtdValid: 4
        },
        description: {
            title: 'Descrição',
            input: 'descricao'
        },
        situation: {
            title: 'Situação',
            input: 'situacao',
            state: {
                active: 'Ativo',
                inactive: 'Inatvo'
            }
        }
    },

    formBtn: {
        save: {
            icon: 'save',
            title: {
                pt: 'Salvar',
                en: 'Save',
                es: 'Ahorrar'
            }
        },

        clear: {
            icon: 'cleaning_services',
            title: {
                pt: 'Limpar',
                en: 'Clean',
                es: 'Limpiar'
            }
        },
        cancel: {
            icon: 'clear',
            title: {
                pt: 'Cancelar',
                en: 'Cancel',
                es: 'Cancelar'
            }
        },

        previous: {
            icon: 'first_page',
            title: {
                pt: 'Formulário anterior',
                en: 'Previous form',
                es: 'Formulario anterior'
            }
        },

        next: {
            icon: 'last_page',
            title: {
                pt: 'Próximo formulário',
                en: 'Next form',
                es: 'Siguiente formulario'
            }
        },
        localMidiasFisicas: {
            icon: 'not_listed_location',
            title: {
                pt: 'Informação para postagem de mídias físicas',
                en: 'Information for posting physical media',
                es: 'Información para publicar medios físicos'
            }
        }
    },

    informationText: {
        instructionUseP1: {
            pt: `Solicitamos que leia as informações abaixo para uma melhor experiência com o envio dos dados para solicitação de classificação indicativa.`,
            en: 'We ask that you read the information below for a better experience with the submission of data to request a parental rating.',
            es: 'Le pedimos que lea la información a continuación para una mejor experiencia con el envío de datos para solicitar una calificación parental.'
        },
        instructionUseP2: {
            pt: `O formulário que será apresentado, é dividido em grupos. Conforme a escolha do segmento de mercado, a visualização dos campos mudaram se adaptando ao cenário. Cada grupo é uma etapa, que pode ter seus dados salvos de forma incremental.`,
            en: 'The form that will be presented is divided into groups. According to the choice of the market segment, the visualization of the fields changed, adapting to the scenario. Each group is a step, which can have its data saved incrementally.',
            es: 'El formulario que se presentará se divide en grupos. Según la elección del segmento de mercado, la visualización de los campos cambió, adaptándose al escenario. Cada grupo es un paso, cuyos datos se pueden guardar de forma incremental.'
        },
        instructionUseP3: {
            pt: `A troca do segmento de mercado fica disponível até o fim do preenchimento do primeiro grupo.`,
            en: 'The market segment exchange is available until the end of filling the first group.',
            es: 'El intercambio de segmento de mercado está disponible hasta que finalice el llenado del primer grupo.'
        },
        instructionUseP4: {
            pt: `Inicie sua inscrição processural, selecionando qual "Segmento de Mercado" se encaixa no seu interesse.`,
            en: 'Start your procedural application by selecting which "Market Segment" fits your interest.',
            es: 'Inicie su solicitud de procedimiento seleccionando qué "Segmento de mercado" se adapta a sus intereses.'
        }
    },

    autorizarPublicacao: {
        mensagemSucesso: {
            pt: `Enviado para publicação`,
            en: `Submitted for publication`,
            es: `Enviado para publicación`
        },
        dialog: {
            titulo: {
                pt: `Autorizar processo para publicação`,
                en: `Authorize process for publication`,
                es: `Autorizar proceso para publicación`
            },
            mensagem: {
                pt: `Deseja realizar a liberação do processo para publicação no DOU e divulgação no portal de consultas externas de Classificações indicativas?`,
                en: `Would you like to release the process for publication in the DOU and dissemination on the ratings external consultation portal?`,
                es: `¿Le gustaría dar a conocer el proceso de publicación en el DOU y difusión en el portal de consulta externa de ratings?`
            }
        },
    },

    revisaoClassificacao: {
        tooltip: {
            viewOriginalProcesso: {
                pt: 'Visualizar processo original',
                en: 'View original process',
                es: 'Ver proceso original'
            }
        },
        creationSuccessDialog: {
            title: {
                pt: 'Revisão de classificação salva com sucesso',
                en: 'Rating review successfully saved',
                es: 'Revisión de calificación guardada con éxito',
            },
            message: {
                pt: 'Revisão enviada para Análise Técnica',
                en: 'Review sent to Technical Analysis',
                es: 'Revisión enviada a Análisis Técnico',
            }
        },
    },

    complementoIdentificacaoRequiredDialog: {
        nonExists: {
            title: {
                pt: 'Ativação do complemento de identificação',
                en: 'Activation of the identification complement',
                es: 'Activación del complemento de identificación'
            },
            content: {
                pt: `<p>Já existe(m) obra(s) cadastrada(s) com:</p>
                <p><strong>Título original</strong> e <strong>Ano de produção</strong> informados.</p>
                <p>Para permanecer com estes valores, é obrigatório preencher o campo
                <strong><em>Complemento de identificação</em></strong>  para que seja possível diferenciar esta das demais obras.<p/>
                </p>Deseja prosseguir?</div>`,
    
                en: `<p>There is already work(s) registered with:</p>
                <p><strong>Original Title</strong> and <strong>Year of production</strong> informed.</p>
                <p>To remain with these values, it is mandatory to fill in the field
                <strong><em>Identification Complement</em></strong>  so that it is possible to differentiate this one from other works.<p/>
                </p>Do you wish to proceed?</div>`,
    
                es: `<p>Ya hay una(s) obra(s) registrada(s) en:</p>
                <p><strong>Título original</strong> y <strong>Año de producción</strong> informado.</p>
                <p>Para permanecer con estos valores, es obligatorio rellenar el campo
                <strong><em>Complemento de identificación</em></strong>  para que sea posible diferenciarla de otras obras.<p/>
                </p>¿Quiere continuar?</div>`
            },
    
            btnSubmit: {
                pt: 'OK',
                en: 'OK',
                es: 'OK'
            },
    
            btnCancel: {
                pt: 'Cancelar',
                en: 'Cancel',
                es: 'Anular'
            }
        } as AdaptiveDialogValues,

        alreadyExists: {
            title: {
                pt: 'Complemento de identificação já utilizado',
                en: 'Identification complement already used',
                es: 'Complemento de identificación ya utilizado'
            },
            content: {
                pt: `<p>Já existe(m) obra(s) cadastrada(s) com:</p>
                <p><strong>Título original</strong>, <strong>Ano de produção</strong> e <strong>Complemento de identificação</strong> informados.</p>
                <p>Para permanecer com <strong><em>Título original</em></strong> e <strong><em>Ano de produção</em></strong> selecionados, 
                é obrigatório preencher o campo
                <strong><em>Complemento de identificação</em></strong> com <u>outro valor</u> para que seja possível diferenciar esta das demais obras.<p/>
                </p>Deseja prosseguir?</div>`,
    
                en: `<p>There is already work(s) registered with:</p>
                <p><strong>Original title</strong>, <strong>Production year</strong> 
                and <strong>Identification complement</strong> informed.</p> 
                <p>To remain with <strong><em>Original title</em></strong> and <strong> 
                <em>Year of production</em></strong> selected, it is mandatory to fill in the field<strong> 
                <em>Identifier complement</em></strong> with <u>another value</u> 
                so that it is possible to differentiate this from the other works.<p/>
                </p>Do you want to proceed?</div>`,
    
                es: `<p>Ya hay obra(s) registrada(s) con:</p>
                <p><strong>Título original</strong>, <strong>Año de producción</strong> 
                e <strong>Complemento de identificación</strong> informado.</p> 
                <p>Permanecer con <strong><em>Título original</em></strong> y <strong> 
                <em>Año de producción</em></strong> seleccionados, es obligatorio completar el campo<strong> 
                <em>Complemento de identificador</em></strong> con <u>otro valor</u> 
                por lo que es posible diferenciar esta de las otras obras.<p/>
                </p>¿Desea continuar?</div>`
            },
    
            btnSubmit: {
                pt: 'OK',
                en: 'OK',
                es: 'OK'
            },
    
            btnCancel: {
                pt: 'Cancelar',
                en: 'Cancel',
                es: 'Anular'
            }
        } as AdaptiveDialogValues
    },

};
