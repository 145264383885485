export const RepublicaValues = {
    header: [
        { title: "Ministério da Justiça e Segurança Pública" },
        { title: "Secretaria Nacional de Justiça" },
        { title: "Coordenação de Política de Classificação Indicativa" },
    ],
    footer: [
        { title: "Esplanada dos Ministérios, Bloco T, Anexo II, 3º Andar, Sala 321 - Bairro Zona Cívico Administrativa, Brasília/ DF, CEP 70064 - 900" },
        { title: "Telefone: (61) 2025-9061 / 9245" }
    ]
}
