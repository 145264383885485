export enum SegmentoMercadoEnum {
    CINEMA_COMERCIAL = 1,
    MOSTRAS_FESTIVAL_CINEMA = 2,
    VIDEO_ON_DEMAND_OTT = 3,
    TV_CABO = 4,
    HOME_VIDEO = 5,
    OUTROS = 6,
    JOGO_ELETRONICO = 7,
    RPG = 8,
    TV_ABERTA = 9,
    ESPETACULO_PUBLICO = 10,
    RADIO = 11,
}

export class SegmentoMercadoHelper {

    static isJogosOrRPG(segmentoId: number) {
        return ([
            SegmentoMercadoEnum.JOGO_ELETRONICO,
            SegmentoMercadoEnum.RPG,
        ]).includes(segmentoId);
    }
    
}
