import { SpinnerService } from './spinner-vinheta/spinner.service';
import { Injectable } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { from, Observable } from 'rxjs';
import { RepublicaValues } from '../../shared-components/template/republica/republica-values';
import { SpinnerVinhetaKeys } from './spinner-vinheta/spinner-vinheta-keys';

export interface ArtefatosConverterPDF {
    templateID?: string;
    fileNamePDF?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConverterPdfService {

    constructor(

        private _spinnerService: SpinnerService
    ) { }

    downloadElementIdAsPDF(artefatos: ArtefatosConverterPDF, flags: {}) {
        this._spinnerService.startSpinnerVinheta(SpinnerVinhetaKeys.GERANDO_ARQUIVO);
        flags['isOfficialDoc'] = true;

        let options = {
            margin: [5, 5, 30, 5],
            filename: artefatos.fileNamePDF,
            enableLinks: false,
            pagebreak: { mode: ['avoid-all', 'css'] },
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait', compressPDF: true }
        };
        const content: Element = document.getElementById(artefatos.templateID);

        html2pdf().from(content).set(options).toPdf().get('pdf')
            .then(pdf => {
                this.incluirRepublicaFooter(pdf)
                this._spinnerService.endSpinnerVinheta(0);
                flags['isOfficialDoc'] = false;
            }).save()
    }

    convertElementIdToPDF(elementId: string, flags: any = {}): Observable<any> {
        return this.converterElementEmPDF(document.getElementById(elementId), flags);
    }

    converterElementEmPDF(content: Element, flags: any = {}): Observable<any> {
        flags['isOfficialDoc'] = true;
        let options = {
            margin: [5, 5, 30, 5],
            enableLinks: false,
            pagebreak: { mode: ['avoid', 'css'] },
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait', compressPDF: true }
        };

        return from(html2pdf().from(content).set(options).toPdf().get('pdf')
            .then(pdf => {
                this.incluirRepublicaFooter(pdf);
                flags['isOfficialDoc'] = false;
            })
            .outputPdf());
    }

    incluirRepublicaFooter(pdf) {
        let totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(50);
            pdf.text(
                `Página ${i} de ${totalPages}`,
                pdf.internal.pageSize.getWidth() - 155,
                pdf.internal.pageSize.getHeight() - 20
            );
            pdf.text(
                RepublicaValues.footer[0].title,
                pdf.internal.pageSize.getWidth() - 230,
                pdf.internal.pageSize.getHeight() - 15
            );
            pdf.text(
                RepublicaValues.footer[1].title,
                pdf.internal.pageSize.getWidth() - 165,
                pdf.internal.pageSize.getHeight() - 10
            );
        }
    }
}


