import { Injectable, Injector } from '@angular/core';
import { ElementoInterativo } from '../../shared-models/model/elemento-interativo.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class ElementoInterativoResource extends BaseResourceService<ElementoInterativo> {

    constructor(protected injector: Injector) {
        super('/elementos-interativos', injector, ElementoInterativo.fromJson);
    }

    getAll(dto: any) {
        return this.getAllByBody(dto);
    }
    
}
