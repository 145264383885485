import { BaseResourceService } from './base-resource.service';
import { Injectable, Injector } from '@angular/core';
import { GeneroPrograma } from '../../shared-models/model/genero-programa.model';

@Injectable({
    providedIn: 'root'
})
export class GeneroProgramaResourceService extends BaseResourceService<GeneroPrograma> {

    constructor(protected injector: Injector) { 
        super('/generos-programa', injector, GeneroPrograma.fromJson);
    }
}
