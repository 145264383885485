import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceService } from './base-resource.service';
import { SolicitacaoClassificacao } from '../../shared-models/model/solicitacao-classificacao.model';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';
import { RevisaoClassificacaoDTO } from './../../shared-models/dto/revisao/revisao-classificacao.dto';
import { HistoricoSolicitacaoClassificacaoOculta } from '../../shared-models/model/historico-solicitacao-classificacao-oculta.model';

@Injectable({
    providedIn: 'root'
})
export class SolicitacaoClassificacaoResourceService extends BaseResourceService<SolicitacaoClassificacao> {

    constructor(protected injector: Injector) {
        super('/solicitacoes-classificacao', injector, SolicitacaoClassificacao.fromJson);
    }

    getAllByStatus(dto: any): Observable<any> {
        return this.http.post<any>(`${this.urlResource}/triagem`, dto);
    }

    getAllByModuloAnalise(dto: any): Observable<any> {
        return this.http.post<any>(`${this.urlResource}/analise`, dto);
    }

    getNormalizadoById(id: number): Observable<MensagemRetorno> {
        return this.http.get<MensagemRetorno>(`${this.urlResource}/normalizado/${id}`);
    }

    updateEssentialData(dto: SolicitacaoClassificacao) {
        return this.http.put<SolicitacaoClassificacao>(`${this.urlResource}/update-essential-data`, dto);
    }

    updateSpecificData(dto: SolicitacaoClassificacao) {
        return this.http.put<any>(`${this.urlResource}/update-specific-data`, dto);
    }

    submitInscricao(solicitacaoId: number) {
        return this.http.put<any>(`${this.urlResource}/submit-inscricao/${solicitacaoId}`, {});
    }

    resubmitInscricaoDevolvida(dto: SolicitacaoClassificacao) {
        return this.http.put<any>(`${this.urlResource}/resubmit-inscricao-devolvida`, dto);
    }

    atribuirClassificacao(dto: SolicitacaoClassificacao) {
        return this.http.put<MensagemRetorno>(`${this.urlResource}/atribuir-classificacao`, dto);
    }

    autorizePublicacao(idSolicitacao: number) {
        return this.http.put<MensagemRetorno>(`${this.urlResource}/autorize-publicacao/${idSolicitacao}`, {});
    }

    createRevisao(dto: RevisaoClassificacaoDTO) {
        return this.http.post<SolicitacaoClassificacao>(`${this.urlResource}/create-revisao-classificacao`, dto);
    }

    getArquivadaByRevisao(idSolicitacaoRevisao: number) {
        return this.http.get<SolicitacaoClassificacao>(`${this.urlResource}/arquivada-by-revisao/${idSolicitacaoRevisao}`);
    }

    changeVisibilidade(justificativa: HistoricoSolicitacaoClassificacaoOculta) {
        return this.http.put<MensagemRetorno>(`${this.urlResource}/change-visibilidade`, justificativa);
    }

}
