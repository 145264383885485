import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Values } from '../../../shared-models/class/values';
import { GlobalValues } from '../../../shared-values/global-values';

@Component({
    selector: 'app-dialogo-confirmacao',
    templateUrl: './dialogo-confirmacao.component.html',
    styleUrls: ['./dialogo-confirmacao.component.scss']
})
export class DialogoConfirmacaoComponent {

    titulo: string;
    mensagem: string;
    values: Values;

    constructor(
        public dialogRef: MatDialogRef<DialogoConfirmacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogoConfirmacaoModel
    ) {
        dialogRef.disableClose = true;
        this.titulo = data.titulo;
        this.mensagem = data.mensagem;
        this.values = {
            global: GlobalValues
        };
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}

export class DialogoConfirmacaoModel {
    constructor(
        public titulo: string,
        public mensagem: string,
        public isHtmlText: boolean = false
    ) { }
}
