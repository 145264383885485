import { Injectable, Injector } from "@angular/core";
import { DiaSemana } from "../../shared-models/model/dia-semana.model";
import { BaseResourceService } from "./base-resource.service";

@Injectable({
    providedIn: 'root'
})
export class DiaSemanaResourceService extends BaseResourceService<DiaSemana> {
    constructor(protected injector: Injector) {
        super('/dias-semana', injector, null);
    }
}
