import { Injectable, Injector } from '@angular/core';
import { StatusSolicitacaoClassificacao } from '../../shared-models/model/status-solicitacao-classificacao.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class StatusSolicitacaoClassificacaoResourceService extends BaseResourceService<StatusSolicitacaoClassificacao> {
    constructor(protected injector: Injector) {
        super('/status', injector, StatusSolicitacaoClassificacao.fromJson);
    }
}
