import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { DialogoConfirmacaoModel } from "src/app/shared/shared-components/dialog/dialogo-confirmacao/dialogo-confirmacao.component";
import { ArquivoDTO } from "src/app/shared/shared-models/dto/arquivo-dto";
import { SolicitacaoClassificacaoPaginacaoDTO } from "src/app/shared/shared-models/dto/processo-paginacao/solicitacao-classificacao-paginacao-dto";
import { AnexoPublicacaoDou } from "src/app/shared/shared-models/model/anexo-publicacao-dou.model";
import { InformacaoSistema } from "src/app/shared/shared-models/model/informacao-sistema";
import { MensagemRetorno } from "src/app/shared/shared-models/model/mensagem-retorno";
import { Pessoa } from "src/app/shared/shared-models/model/pessoa.model";
import { Processo } from "src/app/shared/shared-models/model/processo.model";
import { PublicacaoDou } from "src/app/shared/shared-models/model/publicacao-dou.model";
import { PublicacaoPortariaSei } from "src/app/shared/shared-models/model/publicacao-portaria-sei.model";
import { SolicitacaoClassificacao } from "src/app/shared/shared-models/model/solicitacao-classificacao.model";
import { ApoioFacadeService } from "src/app/shared/shared-services/facades/apoio-facade.service";
import { IntegracaoSeiService } from "src/app/shared/shared-services/integracao-sei.service";
import { PublicacaoDouResourceService } from "src/app/shared/shared-services/resources/publicacao-dou-resource.service";
import { MensageriaService } from 'src/app/shared/shared-services/mensageria-service';
import { SpinnerService } from "src/app/shared/shared-services/utilitarios/spinner-vinheta/spinner.service";
import { SpinnerVinhetaKeys } from 'src/app/shared/shared-services/utilitarios/spinner-vinheta/spinner-vinheta-keys';
import { AnexoPublicacaoDouResourceService } from 'src/app/shared/shared-services/resources/anexo-publicacao-dou-resource.service';
import { DialogoInformacaoModel } from "../../../shared/shared-components/dialog/dialogo-informacao/dialogo-informacao.component";
import { PublicacaoValues } from "./publicacao.values";
import { StatusSolicitacaoClassificacaoEnum } from 'src/app/shared/shared-models/enums/status-solicitacao-classificacao.enum';
import { CONST_ROUTER_UTIL } from 'src/app/shared/shared-utils/consts/const-router-util';
import { DialogService } from "src/app/shared/shared-services/utilitarios/dialog.service";

const SKIP_LOCATION = { skipLocationChange: true };

@Injectable({ 
    providedIn: 'root'
})
export class PublicacaoService {

    componentRef: any;
    values: any;
    visualizacaoPublicado = false;

    constructor(
        private router: Router,
        private apoioFacadeService: ApoioFacadeService,
        private integracaoSeiService: IntegracaoSeiService,
        private publicacaoDouResourceService: PublicacaoDouResourceService,
        private dialogService: DialogService,
        private mensageriaService: MensageriaService,
        private spinnerService: SpinnerService,
        private anexoPublicacaoDouResourceService: AnexoPublicacaoDouResourceService,
        @Inject('InformacaoSistema') sistema: BehaviorSubject<InformacaoSistema>,
    ) {
        sistema.subscribe(() => {
            this.values = PublicacaoValues;
        });
    }

    buscarProcessos(dto: SolicitacaoClassificacaoPaginacaoDTO) {
        return this.apoioFacadeService.getProcessosPorStatus(dto);
    }

    goToProcesso(processo: Processo) {
        this.router.navigate([`/publicacoes/${processo.id}`], SKIP_LOCATION);
    }

    getSegmentosMercadoByModuloID() {
        return this.apoioFacadeService.getSegmentosMercado()
            .pipe(pluck('itens'));
    }

    goToVisualizar(processo: Processo) {
        this.componentRef.goToSolicitacaoView(processo.id);
    }

    goToAcaoPorStatus(statusId: number, solicitacaoId: number) {
        switch (statusId) {
            case StatusSolicitacaoClassificacaoEnum.AGUARDANDO_DOCUMENTO:
                this._goToCriadorPortaria(solicitacaoId);
                break;
            case StatusSolicitacaoClassificacaoEnum.AGUARDANDO_PUBLICACAO:
                this._goToPublicacaoDou(solicitacaoId);
                break;
        }
    }

    private _goToCriadorPortaria(solicitacaoID: number) {
        this.router.navigate([`/${CONST_ROUTER_UTIL.PUBLICACAO}/criacao-portaria/${solicitacaoID}`], SKIP_LOCATION);
    }

    private _goToPublicacaoDou(solicitacaoID: number) {
        this.router.navigate([`/${CONST_ROUTER_UTIL.PUBLICACAO}/diario/${solicitacaoID}`], SKIP_LOCATION);
    }

    getSolicitacaoClassificacao(id) {
        return this.apoioFacadeService.getSolicitacaoClassificacao(id);
    }

    getSolicitacaoClassificacaoArquivadaByRevisao(idSolicitacaoRevisao: number) {
        return this.apoioFacadeService.getSolicitacaoClassificacaoArquivadaByRevisao(idSolicitacaoRevisao);
    }

    getSolicitacaoClassificacaoNormalizado(id): Observable<MensagemRetorno> {
        return this.apoioFacadeService.getSolicitacaoClassificacaoNormalizado(id);
    }

    getModelosPortaria() {
        return this.apoioFacadeService.getModelosPortaria()
            .pipe(pluck('itens'));
    }

    getModelosPortariaBySegmento(id: number) {
        return this.apoioFacadeService.getModelosPortariaBySegmento(id);
    }

    publicarPortaria(publicacaoPortariaSei: PublicacaoPortariaSei): Observable<MensagemRetorno> {
        return this.integracaoSeiService.publicarPortaria(publicacaoPortariaSei);
    }

    getUsuarioAtual(): Observable<Pessoa> {
        return this.apoioFacadeService.getUsuarioAtual();
    }

    getAllPublicacaoDouByIdSolicitacao(idSolicitacao: number): Observable<PublicacaoDou[]> {
        return this.publicacaoDouResourceService.getAllByIdSolicitacao(idSolicitacao);
    }

    savePublicacaoDou(formValue: any, publicacaoDou: PublicacaoDou): Observable<MensagemRetorno> {
        let newPublicacaoDou = new PublicacaoDou();
        if (publicacaoDou) {
            newPublicacaoDou = { ...publicacaoDou };
        }
        newPublicacaoDou.dataDou = formValue.dataDou;
        newPublicacaoDou.dataPortaria = formValue.dataPortaria;
        newPublicacaoDou.numeroPortaria = formValue.numeroPortaria;
        newPublicacaoDou.secaoIPagina = formValue.secaoIPagina;
        if (!newPublicacaoDou.idSolicitacaoClassificacao) {
            newPublicacaoDou.idSolicitacaoClassificacao = formValue.idSolicitacaoClassificacao;
        }

        return (publicacaoDou && publicacaoDou.id ?
            this.publicacaoDouResourceService.update(newPublicacaoDou) :
            this.publicacaoDouResourceService.create(newPublicacaoDou)
        );
    }

    deletePublicacaoDou(id: number): Observable<MensagemRetorno> {
        return this.publicacaoDouResourceService.delete(id);
    }

    getAllTipoDocumentoByIds(ids: number[]): Observable<any> {
        return this.apoioFacadeService.getAllTipoDocumentoByIds(ids, true);
    }

    anexarArquivo(anexo: AnexoPublicacaoDou): Observable<MensagemRetorno> {
        return this.anexoPublicacaoDouResourceService.create(anexo);
    }

    getAnexoPublicacaoDou(idAnexo: number): Observable<ArquivoDTO> {
        return this.anexoPublicacaoDouResourceService.getArquivoById(idAnexo);
    }

    deleteAnexoPublicacaoDou(anexoPublicacaoID: number): Observable<MensagemRetorno> {
        return this.anexoPublicacaoDouResourceService.delete(anexoPublicacaoID);
    }

    openNoAnexoPortariaDialog() {
        this.dialogService.openInfoDialog(
            new DialogoInformacaoModel(
                this.values.dialogo.advertencia.titulo, 
                this.values.dialogo.advertencia.mensagem, 
                this.values.dialogo.advertencia.btnTitulo
            )
        );
    }

    openFinalizePublicacaoDouDialog(idPublicacaoDou: number, processo: SolicitacaoClassificacao): void {
        let title = this.values.dialogo.finalizacao.titulo.pt;
        let message = `${this.values.dialogo.finalizacao.mensagem.pt} ${processo.protocolo} ?`;
        this.dialogService.openConfirmationDialogWithCallback(
            new DialogoConfirmacaoModel(title, message),
            this._finalizePublicacaoDou,
            [idPublicacaoDou]
        );
    }

    private _finalizePublicacaoDou = (args: any[]): void => {
        let idPublicacaoDou: number = args[0];
        this.spinnerService.startSpinnerVinheta(SpinnerVinhetaKeys.FINALIZANDO_PROCESSO);
        this.publicacaoDouResourceService.finalizePublicacaoDou(idPublicacaoDou)
            .subscribe(
                () => {
                    this.spinnerService.endSpinnerVinheta(0);
                    this._openDialogOfSuccessFinalizePublicacao();
                },
                error => {
                    this.spinnerService.endSpinnerVinheta(0);
                    this.mensageriaService.handleApiError(error);
                }
            );
    }

    private _openDialogOfSuccessFinalizePublicacao(): void {
        let dialogRef = this.dialogService.openInfoDialog(
            new DialogoInformacaoModel(
                this.values.dialogo.publicacaoConcluida.titulo, 
                this.values.dialogo.publicacaoConcluida.mensagem, 
                this.values.dialogo.publicacaoConcluida.btnTitulo
            )
        );

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (!dialogResult) {
                return;
            }
            this.goToPublicacao();
        });
    }

    goToPublicacao() {
        this.router.navigate([CONST_ROUTER_UTIL.PUBLICACAO], SKIP_LOCATION);
    }

    goToPublicacaoByStatus(status: StatusSolicitacaoClassificacaoEnum) {
        let listIndex = PublicacaoValues.lists.findIndex(obj => obj.statusId === status);

        if(listIndex < 0) {
            let statusName = StatusSolicitacaoClassificacaoEnum[status].replace(/_/g, ' ');
            alert(`Status ${statusName} sem destino de Publicacao para redirecionar`);
            return;
        }

        this.router.navigate([CONST_ROUTER_UTIL.PUBLICACAO], { state: { listIndex: listIndex } });
    }

}
