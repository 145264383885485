<div [formGroup]="formGroup">
    <mat-form-field class="full-width">
        <mat-label>
            {{title}}<span class="required">{{ isRequired ? ' *' : ''}}</span>
        </mat-label>

        <input matInput 
            [maxlength]="maxlength" 
            [readonly]="readOnly" 
            [placeholder]="placeholder" 
            [name]="controlName"
            [formControlName]="controlName"
            (change)="sendChange($event)"
        >
            
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl,'required')">
            {{ 'TRADUCAO' | translate: validatorMessageValues.validator.required }}
        </mat-error>

        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl, 'minlength')">
            {{validatorMessageValues.validator.minlength}} {{fieldValidator.lengthValidate(formControl, 'minlength')}}
        </mat-error>

        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl, 'maxlength')">
            {{'TRADUCAO'|translate:validatorMessageValues.validator.maxlength}}
            {{fieldValidator.lengthValidate(formControl,'maxlength')}}
        </mat-error>

        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl, 'pattern')">
            {{ 'TRADUCAO' | translate: validatorMessageValues.validator.pattern}}
        </mat-error>
    </mat-form-field>
</div>
