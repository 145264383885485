export const SpinnerVinhetaValues = {

    login: {
        pt: "Login",
        en: "Login",
        es: "Login",
    },

    acessoDados: {
        pt: "Acessando dados",
        en: "Accessing data",
        es: "Acceder a datos"
    },

    enviandoDados: {
        pt: "Enviando dados",
        en: "Sending data",
        es: "Enviando datos"
    },

    excluindoDados: {
        pt: "Excluindo dados",
        en: "Deleting data",
        es: "Eliminación de datos"
    },

    acessandoArquivos: {
        pt: "Acessando arquivos",
        en: "Accessing files",
        es: "Acceder a archivos"
    },

    enviandoArquivo: {
        pt: "Enviando arquivo",
        en: "Sending file",
        es: "Enviando archivo"
    },

    excluindoArquivo: {
        pt: "Excluindo arquivo",
        en: "Deleting file",
        es: "Borrando archivo"
    },

    finalizandoProcesso: {
        pt: "Finalizando processo",
        en: "Finalizing process",
        es: "Proceso de finalización"
    },

    buscandoEndereco: {
        pt: 'Buscando endereço',
        en: 'Searching address',
        es: 'Buscando dirección'
    },

    sincronizandoMecanismo: {
        pt: 'Sincronizando mecanismo',
        en: 'Synchronizing engine',
        es: 'Motor sincronizador'
    },

    adicionandoRepresentante: {
        pt: 'Adicionando representante',
        en: 'Adding representative',
        es: 'Agregar representante'
    },

    configurandoUsuarioADM: {
        pt: 'Configurando usuário ADM',
        en: 'Configuring ADM user',
        es: 'Configuración de usuario ADM'
    },

    solicitacaoEmAndamento: {
        pt: 'Solicitação em andamento',
        en: 'Request in progress',
        es: 'Solicitud en curso'
    },

    atualizandoStatus: {
        pt: 'Atualizando status',
        en: 'updating status',
        es: 'estado de actualización'
    },

    protocolandoSEI: {
        pt: 'Gerando protocolo no SEI',
        en: 'Generating protocol in SEI',
        es: 'Generando protocolo en SEI'
    },

    gerandoPortaria: {
        pt: 'Gerando portaria...',
        en: 'Generating concierge...',
        es: 'Generando conserje...'
    },

    atualizandoPublicacao: {
        pt: 'Atualizando publicação',
        en: 'Updating Publication',
        es: 'Publicación actualizada'
    },

    keycloakAusente: {
        pt: 'Ausência ID keycloak',
        en: 'Missing ID keycloak',
        es: 'Llavero Ausência ID'
    },

    gerandoArquivo: {
        pt: 'Gerando arquivo',
        en: 'Generating file',
        es: 'Generar archivo'
    },

    enviandoCorrecao: {
        pt: 'Enviando correção',
        en: 'sending correction',
        es: 'enviando corrección'
    }

};
