import { Observable } from 'rxjs';
import { EditorCustomTemplateResourceService } from './../../../shared-services/resources/editor-custom-template-resource.service';
import { Injectable } from '@angular/core';
import { ModeloTextoCustomizado } from 'src/app/shared/shared-models/model/modelo-texto-customizado.model';
import { MensagemRetorno } from 'src/app/shared/shared-models/model/mensagem-retorno';
import { TermoService } from 'src/app/shared/shared-services/termo.service';

@Injectable({
    providedIn: 'root'
})
export class EditorCustomTemplateService {

    constructor(
        private editorCustomTemplateResourceService: EditorCustomTemplateResourceService,
        private termoService: TermoService,
    ) { }

    persist(modeloTextoCustomizado: ModeloTextoCustomizado): Observable<MensagemRetorno> {
        if (modeloTextoCustomizado.id) {
            return this.editorCustomTemplateResourceService.update(modeloTextoCustomizado);
        } else {
            return this.editorCustomTemplateResourceService.create(modeloTextoCustomizado);
        }
    }


    getModeloTextoCustomizadoByTipoModelo(tipoModelo: number) {
        return this.editorCustomTemplateResourceService.getModeloTextoCustomizadoByTipoModelo(tipoModelo);
    }

    dropTermoAceiteTable(): Observable<MensagemRetorno> {
        return this.termoService.deleteAll();
    }
}
