export const MensagemRetornoValues = {

    INTERNAL_SERVER_ERROR: {
        pt: 'Foi detectado uma anomalia interna no servidor',
        en: 'An internal server anomaly was detected',
        es: 'Se detectó una anomalía interna en el servidor'
    },

    UNAUTHORIZED: {
        pt: `Não autorizado`,
        en: `Unauthorized`,
        es: `No autorizado`
    },

    UNEXPECTED_ERROR: {
        pt: 'Erro inesperado, por favor, contate o administrador do sistema',
        en: 'Enexpected error, please contact your system administrator',
        es: 'Error inesperado, comuníquese con el administrador del sistema'
    },

    GET_LOGGED_USER_ACCESS_TYPE_ERROR: {
        pt: 'Erro ao obter tipo de acesso do usuário logado.',
        en: 'Error getting access type from logged in user.',
        es: 'Error al obtener el tipo de acceso del usuario que inició sesión.',
    },

    GET_LOGGED_USER_INFO: {
        pt: 'Erro ao obter dados do usuário logado.',
        en: 'Error at get logged user data',
        es: 'Error al iniciar sesión en los datos del usuario'
    },

    LOGGED_USER_ORIGIN_UNKNOWN: {
        pt: 'Usuário de origem desconhecida',
        en: 'Logged user origin unknown',
        es: 'Usuario de origen desconocido'
    },

    USER_NOT_FOUND: {
        pt: 'Usuário não encontrado.',
        en: 'User not found.',
        es: 'Usuario no encontrado.'
    },

    USER_EMAIL_NOT_VERIFIED: {
        pt: 'E-mail do usuário não verificado.',
        en: 'User email not verified.',
        es: 'Correo electrónico del usuario no verificado.'
    },

    RESOURCE_ACCESS_NOT_ALLOWED: {
        pt: 'Usuário não tem permissão para acessar este recurso.',
        en: 'User does not have permission to access this resource.',
        es: 'El usuario no tiene permiso para acceder a este recurso.',
    },

    TOKEN_NAO_RETORNA_EMPRESAS_VINCULADAS: {
        pt: 'Token inválido, empresas vinculadas não podem sem acessadas.',
        en: 'Invalid token, linked companies cannot be accessed',
        es: 'Token no válido, no se puede acceder a las empresas vinculadas'
    },


    SAVE_FILE_ERROR: {
        pt: 'Não foi possível anexar arquivo, por favor, contate o administrador do sistema.',
        en: 'Unable to attach file, please contact your system administrator.',
        es: 'No se puede adjuntar el archivo, comuníquese con el administrador del sistema.',
    },

    GET_FILE_ERROR: {
        pt: 'Arquivo não localizado, por favor, contate o administrador do sistema.',
        en: 'File not found, please contact your system administrator.',
        es: 'Archivo no encontrado, comuníquese con el administrador del sistema.',
    },

    DELETE_FILE_ERROR: {
        pt: 'Erro ao excluir o arquivo.',
        en: 'Error deleting file.',
        es: 'Error al eliminar el archivo.',
    },

    SAVE_SUCCESS: {
        pt: 'Dados salvos com sucesso',
        en: 'Data saved successfully',
        es: 'Datos guardados exitosamente'
    },

    SAVE_ERROR: {
        pt: 'Erro ao salvar.',
        en: 'Error saving.',
        es: 'Error al guardar.',
    },

    DELETE_SUCCESS: {
        pt: 'Exclusão concluída',
        en: 'Deletion completed',
        es: 'Eliminación completada'
    },

    DELETE_ERROR: {
        pt: 'Erro ao excluir.',
        en: 'Error deleting.',
        es: 'Error al eliminar.',
    },

    NO_RESULT_FOUND: {
        pt: 'Nenhum registro encontrado.',
        en: 'No records found.',
        es: 'No se encontraron registros.',
    },

    ITEM_ALREADY_REGISTERED: {
        pt: 'Dado já cadastrado no sistema.',
        en: 'Data already registered in the system.',
        es: 'Datos ya registrados en el sistema.'
    },

    NON_UNIQUE_RESULT: {
        pt: 'Foram encontrados mais de um registro com os dados informados. Refine a pesquisa.',
        en: 'More than one record with the reported data was found. Refine the search.',
        es: 'Se encontró más de un registro con los datos reportados. Refinar la búsqueda.',
    },

    INCONSISTENT_DATA: {
        pt: 'Dados inconsistentes',
        en: 'Inconsistent Data',
        es: 'Datos inconsistentes',
    },


    INVALID_DATA_FOR_REQUEST: {
        pt: 'Dados inválidos para a operação.',
        en: 'Invalid data for the operation.',
        es: 'Datos no válidos para la operación.',
    },

    INVALID_DATA_FOR_INSERT: {
        pt: 'Dados inválidos para cadastro.',
        en: 'Invalid data for registration.',
        es: 'Datos no válidos para el registro.',
    },

    INVALID_DATA_FOR_SAVE: {
        pt: 'Dados inválidos para salvar.',
        en: 'Invalid data for save.',
        es: 'Datos no válidos para guardar.',
    },

    MISSING_DATA_FOR_REQUEST: {
        pt: 'Faltam dados para concluir a operação.',
        en: 'Data is missing to complete the operation.',
        es: 'Faltan datos para completar la operación.',
    },

    MISSING_DATA_FOR_SAVE: {
        pt: 'Faltam dados para concluir a operação.',
        en: 'Missing data to save.',
        es: 'Faltan datos para guardar.',
    },

    MISSING_ANEXO_FOR_SAVE: {
        pt: 'Faltam anexos para concluir a operação.',
        en: 'Attachments are missing to complete the operation.',
        es: 'Faltan archivos adjuntos para completar la operación.',
    },

    FIELD_NOT_SUPPORTED: {
        pt: 'Campo não suportado.',
        en: 'Field not supported.',
        es: 'Campo no admitido.',
    },

    FORMATO_CAMPO_NOME_INVALIDO: {
        pt: 'Formato do campo Nome inválido.',
        en: 'Invalid Name field format.',
        es: 'Formato de campo de nombre no válido.',
    },

    ANO_PRODUCAO_MENOR_ANO_CORRENTE: {
        pt: 'Ano de produção deve ser menor ou igual ao ano corrente.',
        en: 'Year of production must be less than or equal to the current year.',
        es: 'El año de producción debe ser menor o igual al año en curso.',
    },

    INSCRICAO_NAO_PODE_SER_EXCLUIDA: {
        pt: 'O formulário de inscrição processual não pode ser excluído, pois possui vínculos.',
        en: 'The procedural application form cannot be deleted as it has links.',
        es: 'El formulario de solicitud de trámite no se puede eliminar ya que tiene enlaces.',
    },

    INSERT_URL_AS_TIPO_MIDIA: {
        pt: 'Favor inserir a URL conforme Tipo de Mídia selecionada.',
        en: 'Please enter the URL according to the Media Type selected.',
        es: 'Ingrese la URL de acuerdo con el tipo de medio seleccionado.',
    },

    NAO_EXISTE_TERMO_ASSINADO: {
        pt: 'Não existe termo assinado.',
        en: 'There is no signed term.',
        es: 'No hay término firmado.',
    },

    PORTARIA_GERADA_COM_SUCESSO: {
        pt: 'Portaria gerada com sucesso.',
        en: 'Successfully generated ordinance.',
        es: 'Ordenanza generada con éxito.',
    },

    CAN_NOT_SAVE_PUBLICACAO_WITH_ANOTHER_UNFINALIZED: {
        pt: 'Não é possível salvar a publicação pois existe outra não finalizada.',
        en: 'It is not possible to save the publication as there is another unfinished one.',
        es: 'No es posible guardar la publicación ya que hay otra sin terminar.'
    },

    PUBLICACAO_WITH_INVALID_DATES_FOR_SAVE: {
        pt: 'Publicação contém data(s) inválida(s) para salvar.',
        en: '',
        es: '',
    },

    CAN_NOT_UPDATE_FINALIZED_PUBLICACAO: {
        pt: 'Não é possível editar publicação finalizada',
        en: '',
        es: ''
    },

    CAN_NOT_FINALIZE_PUBLICACAO_ALREADY_FINALIZED: {
        pt: 'Não é possível finalizar publicação já finalizada',
        en: '',
        es: ''
    },

    PUBLICACAO_FINISHED_SUCCESS: {
        pt: 'Publicação finalizada com sucesso.',
        en: 'Publication completed successfully.',
        es: 'Publicación completada con éxito.',
    },

    PROCESSO_CHANGE_REQUEST_CHOOSE_DEFERIMENTO_SIM: {
        pt: 'As opções selecionadas são iguais ao requerimento. Selecione Sim para o deferimento.',
        en: 'The selected options are the same as the application. Select Yes for deferral.',
        es: 'Las opciones seleccionadas son las mismas que las de la aplicación. Seleccione Sí para el aplazamiento.',
    },

    REQUERIMENTO_RECONSIDERACAO_MUST_CONTAIN_SOME_CHANGE: {
        pt: 'Para solicitar reconsideracao, deve haver alteração em relação ao processo publicado.',
        en: 'To request reconsideration, there must be a change from the published process.',
        es: 'Para solicitar la reconsideración, debe haber un cambio del proceso publicado.',
    },

    REQUERIMENTO_RECONSIDERACAO_SUBMISSION_SUCCESS: {
        pt: 'Solicitação de requerimento de reconsideração enviada para análise com sucesso, favor aguardar.',
        en: 'Request for reconsideration request submitted for review successfully, please wait.',
        es: 'Solicitud de reconsideración enviada para revisión con éxito, espere.',
    },

    ANALISE_RECONSIDERACAO_MUST_CONTAIN_SOME_CHANGE: {
        pt: 'Para deferir parcialmente a reconsideração, deve haver alteração em relação ao requerimento.',
        en: 'To partially grant the reconsideration, there must be a change in relation to the application.',
        es: 'Para conceder parcialmente la reconsideración, debe haber un cambio en relación con la solicitud.',
    },

    ANALISE_RECONSIDERACAO_SUBMISSION_SUCCESS: {
        pt: 'Análise de requerimento de reconsideração realizado com sucesso.',
        en: 'Review of reconsideration request successfully performed.',
        es: 'Revisión de la solicitud de reconsideración realizada con éxito.',
    },

    DATA_EXTRACTION_TOO_LARGE_OUT_MEMORY_ERROR: {
        pt: 'A quantidade de dados para a extração conforme filtros selecionados é muito grande. Refine mais a pesquisa.',
        en: 'The amount of data for extracting as per selected filters is too large. Refine your search further.',
        es: 'La cantidad de datos para extraer según los filtros seleccionados es demasiado grande. Refina aún más tu búsqueda.',
    },

    SOLICITACAO_BY_PROTOCOL_NOT_FOUND: {
        pt: 'Não é possível encontrar o processo pelo número informado!',
        en: 'It is not possible to find the process by the number provided!',
        es: 'No es posible encontrar el proceso por el número proporcionado!',
    },

    REABERTURA_PRAZO_OPENED: {
        pt: 'já existe reabertura com o número do processo informado em aberto!',
        en: 'there is already a reopening with the process number informed open!',
        es: 'ya hay reapertura con el numero de proceso informado abierto!',
    },

    START_DATE_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_THE_END_DATE: {
        pt: 'A data inicial não pode ser maior ou igual a data final!',
        en: 'The start date cannot be greater than or equal to the end date!',
        es: '¡La fecha de inicio no puede ser mayor o igual a la fecha de finalización!',
    },

    PROCESSO_LEGADO_NOT_AUDITED: {
        pt: 'Processos legados não são auditados.',
        en: 'Legacy processes are not audited.',
        es: 'Los procesos heredados no se auditan.',
    },

    EMAIL_ALREADY_REGISTERED: {
        pt: 'Email já cadastrado',
        en: 'Email already registered',
        es: 'Correo electrónico ya registrado',
    },

    SUBSCRIBE_EMAIL_SUCCESS: {
        pt: 'Dados salvos com sucesso. Foi enviada uma confirmação para o email informado para finalizar o cadastro.',
        en: 'Data saved successfully. A confirmation has been sent to the email provided to complete the registration.',
        es: 'Datos guardados exitosamente. Se ha enviado una confirmación al correo electrónico proporcionado para completar el registro.',
    },

    SUBSCRIBE_RESEND_CONFIRMATION_EMAIL_SUCCESS: {
        pt: 'Operação realizada com sucesso. Foi reenviada confirmação para o email informado para finalizar o cadastro.',
        en: 'Operation performed successfully. A new confirmation has been sent to the email provided to complete the registration.',
        es: 'Operación realizada con éxito. Se ha enviado una nueva confirmación al correo electrónico proporcionado para completar el registro.',
    },

    INVALID_TOKEN_ACCESS: {
        pt: 'URL inválida.',
        en: 'Invalid URL.',
        es: 'URL invalida.',
    },

    EXPIRED_SUBSCRIPTION_EMAIL_TOKEN: {
        pt: 'URL expirada. Efetue novo cadastro dentro de alguns minutos.',
        en: 'Expired URL. Register again in a few minutes',
        es: 'URL caducada. Regístrese nuevamente en unos minutos.',
    },

    CAN_NOT_SAVE_CORRETOR_REGISTRO_ITEM_WITH_NO_CHANGE: {
        pt: 'Não é possivel salvar registro com item(s) sem alteração.',
        en: 'Unable to save record with item(s) without change.',
        es: 'No se puede guardar el registro con los artículos sin cambios.'
    },

    PROCESSO_ORIGIN_IS_NOT_LEGADO: {
        pt: 'O processo não é originalmente Legado',
        en: '',
        es: ''
    },

    LEGADO_ALREADY_RECYCLED: {
        pt: 'Processo Legado já reciclado',
        en: '',
        es: ''
    },

    LEGADO_WITH_NO_RESPONSAVEL: {
        pt: 'O processo não possui Responsável Legado pela inscrição.',
        en: '',
        es: ''
    },

    RESPONSAVEL_LEGADO_WITH_NO_NOME_OR_NUMERO_DOCUMENTO: {
        pt: 'O processo possui Responsável Legado sem nome e/ou número de documento',
        en: '',
        es: ''
    },

    // Front end messages

    SUCCESS: {
        pt: 'Operação realizada com sucesso',
        en: 'Operation performed successfully',
        es: 'Operación realizada con éxito'
    },

    INCORRET_EXTENSION: {
        pt: `Só é permitido anexar arquivos nos formatos PDF, JPEG, PNG`,
        en: `It is only allowed to attach files in PDF, JPEG, PNG formats`,
        es: `Solo se permite adjuntar archivos en formato PDF, JPEG, PNG`
    },

    FORM_INVALID: {
        pt: 'Preencha todos os campos obrigatórios.',
        en: 'Fill in all required fields.',
        es: 'Rellene todos los campos obligatorios.',
    },

    EMPTY_TEMPLATE: {
        pt: 'Ação interrompida: Template vazio detectado',
        en: 'Action stopped: Empty template detected.',
        es: 'Acción detenida: plantilla vacía detectada.',
    },

    CAN_NOT_DELETE_DATA_IN_USE: {
        pt: 'Dado em uso, remoção não permitida.',
        en: 'Data in use, removal not allowed.',
        es: 'Datos en uso, eliminación no permitida.',
    },

    EMAILS_DOES_NOT_MATCH: {
        pt: 'Emails não conferem',
        en: "Emails don't match",
        es: 'Los correos electrónicos no coinciden',
    },

    NO_CHANGES_FOR_SAVE: {
        pt: 'Atenção: formulário inválido (ausência de mudanças ou itens não permitidos).',
        en: 'Attention: invalid form (no changes or items not allowed).',
        es: 'Atención: formulario no válido (no se permiten cambios ni artículos).'
    },

    LINK_FTP_COPY_SUCCESS: {
        pt: 'Link FTP copiado para área de transferência.',
        en: 'FTP link copied to clipboard',
        es: 'Enlace FTP copiado en el portapapeles'
    }

};

