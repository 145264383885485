import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { switchMap, take } from "rxjs/operators";
import { SolicitacaoClassificacaoResourceService } from 'src/app/shared/shared-services/resources/solicitacao-classificacao-resource.service';
import { DialogoConfirmacaoModel } from "../shared-components/dialog/dialogo-confirmacao/dialogo-confirmacao.component";
import { AutorizarProcessoValues } from "../shared-values/autorizar-processo-values";
import { MensageriaService } from "./mensageria-service";
import { DialogService } from "./utilitarios/dialog.service";
import { SpinnerVinhetaKeys } from './utilitarios/spinner-vinheta/spinner-vinheta-keys';
import { SpinnerService } from "./utilitarios/spinner-vinheta/spinner.service";

@Injectable({
    providedIn: 'root'
})
export class AutorizarProcessoService {

    constructor(
        public translate: TranslateService,
        private dialogService: DialogService,
        private spinnerService: SpinnerService,
        private mensageriaService: MensageriaService,
        private solicitacaoClassificacaoResource: SolicitacaoClassificacaoResourceService,
    ) { }

    autorizePublicacao(idSolicitacao: number, successCallback: Function, errorCallback?: Function) {
        this._openDialogAutorizePublicacaoPublicacao().subscribe(dialogResult => {
            if (!dialogResult) {
                return;
            }

            this._autorizePublicacaoCallback(idSolicitacao, successCallback, errorCallback);            
        })
    }

    private _openDialogAutorizePublicacaoPublicacao() {
        return this.translate.get('TRADUCAO_TS')
            .pipe(switchMap(sigla => {
                let dialogRef = this.dialogService.openConfirmationDialog(
                    new DialogoConfirmacaoModel(
                        AutorizarProcessoValues.autorizarPublicacao.dialog.titulo[sigla],
                        AutorizarProcessoValues.autorizarPublicacao.dialog.mensagem[sigla]
                    )
                );
                return dialogRef.afterClosed().pipe(take(1));
            }
        ));
    }

    private _autorizePublicacaoCallback(idSolicitacao: number, successCallback: Function, errorCallback?: Function) {
        this.spinnerService.startSpinnerVinheta(SpinnerVinhetaKeys.ENVIANDO_DADOS);
        
        this.solicitacaoClassificacaoResource.autorizePublicacao(idSolicitacao)
            .subscribe(
                () => {
                    this.mensageriaService.showSaveSuccess();
                    this.spinnerService.endSpinnerVinheta(0);
                    successCallback();
                }, 
                error => {
                    this.mensageriaService.handleApiError(error);
                    this.spinnerService.endSpinnerVinheta(0);
                    if (errorCallback) {
                        errorCallback();
                    }
                }
            );
    }
    
}
