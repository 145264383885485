import { BaseResourceModel } from "./base-resource.model";

export class ElementoInterativo extends BaseResourceModel {

    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
        public permanente?: boolean,
    ) {
        super(id);
    }

    static fromJson(jsonData: any): ElementoInterativo {
        return Object.assign(new ElementoInterativo(), jsonData);
    }

}