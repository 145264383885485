import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FieldValidatorService {

    hasErrorValidate(control: AbstractControl, errorName: string): boolean {
        if ((control.dirty || control.touched) && this.hasError(control, errorName)) {
            return true;
        }
        return false;
    }

    hasError(control: AbstractControl, errorName: string): boolean {
        return control.hasError(errorName);
    }

    lengthValidate(control: AbstractControl, errorName: string): number {
        const error = control.errors[errorName];
        return error.requiredLength || error.min || error.max || 0;
    }

    hasErrorInvalidDate(control: AbstractControl): boolean {
        if ((control.dirty || control.touched)) {
            return control.hasError('invalidDate');
        }
    }
}
