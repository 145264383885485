import { BaseResourceModel } from './base-resource.model';
import { HorarioRecomendado } from './horario-recomendado.model';

export class Classificacao extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
        public horarioRecomendado?: HorarioRecomendado
    ) {
        super();
    }

    static fromJson(jsonData: any): Classificacao {
        return Object.assign(new Classificacao(), jsonData);
    }
}
