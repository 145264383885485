import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceService } from './base-resource.service';
import { ArquivoDTO } from '../../shared-models/dto/arquivo-dto';
import { AnexoProcessoDTO } from 'src/app/shared/shared-models/dto/anexo-processo-dto';

@Injectable({
    providedIn: 'root'
})
export class AnexoProcessoResourceService extends BaseResourceService<any> {
    
    constructor(protected injector: Injector) {
        super('/anexos-processo', injector, null);
    }

    getAllBySolicitacaoClassificacao(solicitacaoId: number): Observable<AnexoProcessoDTO[]> {
        return this.http.get<AnexoProcessoDTO[]>(`${this.urlResource}/list-all-by-solicitacao/${solicitacaoId}`);
    }

    getArquivo(anexoDTO: AnexoProcessoDTO) {
        return this.http.post<ArquivoDTO>(`${this.urlResource}/arquivo`, anexoDTO);
    }
}
