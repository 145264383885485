import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { DialogEmpresasVinculadasComponent, DialogEmpresasVinculadasModel } from "../shared-components/dialog/dialog-empresas-vinculadas/dialog-empresas-vinculadas.component";

const endpointEmpresasVinculadas = 'empresas-vinculadas';

@Injectable({
    providedIn: 'root'
})

export class EmpresasVinculadasService {
    protected urlBase = environment.apiUrl;
    constructor(
        protected http: HttpClient,
        private dialog: MatDialog
    ) { }

    getCnpjs(): Observable<any> {
        return this.http.get<any>(`${this.urlBase}/${endpointEmpresasVinculadas}`);
    }

    openDialogEmpresasVinculadas(cnpjs: any) {
        let dialogEmpresasVinculadasModel = new DialogEmpresasVinculadasModel(cnpjs);
        return this.dialog.open(DialogEmpresasVinculadasComponent, {
            disableClose: true,
            data: dialogEmpresasVinculadasModel
        }).afterClosed();
    }

}
