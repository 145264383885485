export enum InputTypeEnum {
    TEXT = 'text',
    TEXT_AREA = 'text-area',
    NUMBER = 'number',
    DATE = 'date',
    DATE_ONLY_YEAR = 'date-only-year',
    SINGLE_SELECT = 'single-select',
    MULTI_SELECT = 'multi-select',
    MULTI_SELECT_WITH_DESACTIVE_MODE = 'multi-select-with-desactive-mode',
    URL_LINK = 'url-link',
}

export class InputTypeEnumHelper {

    static isSomeSelect(inputType: InputTypeEnum) {
        return ([
            InputTypeEnum.SINGLE_SELECT,
            InputTypeEnum.MULTI_SELECT,
            InputTypeEnum.MULTI_SELECT_WITH_DESACTIVE_MODE,
        ]).includes(inputType)
    }

}
