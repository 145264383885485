import { ROLES } from "../shared-utils/consts/const-roles";

export const AutorizarProcessoValues = {
    autorizarPublicacao: {
        mensagemSucesso:{
            pt: `Enviado para publicação`,
            en: `Submitted for publication`,
            es: `Enviado para publicación`
        },
        dialog: {
            titulo: {
                pt: `Autorizar processo para publicação`,
                en: `Authorize process for publication`,
                es: `Autorizar proceso para publicación`
            },
            mensagem: {
                pt: `Deseja realizar a liberação do processo para publicação no DOU e divulgação no portal de consultas externas de Classificações indicativas?`,
                en: `Would you like to release the process for publication in the DOU and dissemination on the ratings external consultation portal?`,
                es: `¿Le gustaría dar a conocer el proceso de publicación en el DOU y difusión en el portal de consulta externa de ratings?`
            }
        },
    },
    permissoes: {
        autorizarPublicacao: ROLES.SOLICITACAO_CLASSIFICACAO_AUTORIZAR_PUBLICACAO
    }
}
