<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
    <form [formGroup]="form" class="form-line">
        <app-input-text-area class="form-item" title="Justificativa" 
            [formGroup]="form" controlName="justificativa"
            [rows]="data.rows"  [maxlength]="data.maxLength">
        </app-input-text-area>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="primary">Cancelar</button>
    <button mat-raised-button (click)="save()" color="primary">{{ data.saveButtonLabel }}</button>
</div>
