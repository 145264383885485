import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { InformacaoSistema } from "../shared-models/model/informacao-sistema";
import { UserNotification } from "../shared-models/model/notification.model";

@Injectable({
    providedIn: 'root'
})

export class PendenciasUsuario {

    private http: HttpClient;
    private urlResource: string;

    constructor(private injector: Injector,
        @Inject('InformacaoSistema') private sistema: BehaviorSubject<InformacaoSistema>,) {
        this._setHttp();
        this.urlResource = environment.apiUrl + '/pedencias-usuario';
    }

    private _setHttp() {
        this.http = this.injector.get(HttpClient);
    }

    getPendenciasUsuario(idUsuairio: string) {
        return this.http.get<UserNotification>(`${this.urlResource}/notificacao/"${idUsuairio}"`);
    }

    updatePendenciasUsuario() {
        this.getPendenciasUsuario("").subscribe(notificacoes => {
            this.sistema.getValue().notificacoes = notificacoes;
            this.sistema.next(this.sistema.getValue());
        })

    }

}
