import { Injectable } from '@angular/core';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { InformacaoSistemaService } from 'src/app/shared/shared-services/informacaoSistema.service';
import { AuthHelper } from 'src/app/shared/shared-utils/auth-helper';

@Injectable({
    providedIn: 'root'
})
export class AuthKeycloakService {
    constructor(
        private keycloak: KeycloakService,
        private authHelper: AuthHelper,
        private informacaoSistemaService: InformacaoSistemaService
    ) {
        this.keycloak.keycloakEvents$.subscribe(async (event: KeycloakEvent) => {
            switch (event.type) {
                case (KeycloakEventType.OnAuthSuccess): {
                    this.informacaoSistemaService.configurarInformacoesSistema(await this.keycloak.loadUserProfile());
                    break;
                }
                case (KeycloakEventType.OnTokenExpired): {
                    if (this.keycloak.getKeycloakInstance().refreshToken) {
                        this.keycloak.updateToken();
                    } else {
                        this.authHelper.logout();
                    }
                    break;
                }
                case (KeycloakEventType.OnAuthError): {
                    this.authHelper.logout();
                    break;
                }
                case (KeycloakEventType.OnAuthRefreshError): {
                    this.authHelper.logout();
                    break;
                }
            }
        });
    }

}
