import { BaseResourceModel } from "./base-resource.model";

export class AtributoSolicitacaoClassificacao extends BaseResourceModel {
    constructor(
        public titulo?: string,
        public sigla?: string,
    ) {
        super();
    }

    static fromJson(jsonData: any): AtributoSolicitacaoClassificacao {
        return Object.assign(new AtributoSolicitacaoClassificacao(), jsonData);
    }
}
