import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { take } from 'rxjs/operators';
import { VisualizacaoEnum } from '../../../shared-models/enums/visualizacao.enum';
import { InformacaoSistema } from '../../../shared-models/model/informacao-sistema';

@Component({
    selector: 'app-dialogo-preparacao',
    templateUrl: './dialogo-preparacao.component.html',
    styleUrls: ['./dialogo-preparacao.component.scss']
})
export class DialogoPreparacaoComponent {
    private informacaoSistema: InformacaoSistema;

    constructor(
        public dialogRef: MatDialogRef<DialogoPreparacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogoPreparacaoModel,
        @Inject('InformacaoSistema') public sistema: BehaviorSubject<InformacaoSistema>
    ) {
        dialogRef.disableClose = true;
        sistema
            .pipe(take(1))
            .subscribe((res) => {
                this.informacaoSistema = res;
                if (this.informacaoSistema['sso']) {
                    this._configurarTipoVisualizacao();
                }
            });
    }

    private _configurarTipoVisualizacao() {
        this.data['visualizacao'] = (this.informacaoSistema.isAcessoInterno()) ?
            VisualizacaoEnum.INTERNA : VisualizacaoEnum.EXTERNA;
    }
}
export class DialogoPreparacaoModel {
    constructor(
        public mensagem: any
    ) { }
}
