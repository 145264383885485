import { ArquivoDTO } from "../dto/arquivo-dto";
import { TipoDocumentoDTO } from "../dto/tipo-documento-dto";
import { BaseResourceModel } from "./base-resource.model";

export class AnexoPublicacaoDou extends BaseResourceModel {
    constructor(
        public idPublicacaoDou?: number,
        public tipoDocumento?: TipoDocumentoDTO,
        public arquivoDTO?: ArquivoDTO
    ) {
        super();
    }

    static fromJson(jsonData: any): AnexoPublicacaoDou {
        return Object.assign(new AnexoPublicacaoDou(), jsonData);
    }
}
