import { BaseResourceModel } from "../base-resource.model";
import { NotificacaoGrupoSegmentoMercado } from "./notificacao-grupo-segmento-mercado.model";
import { NotificacaoInscricaoOrigemEnum } from "../../enums/notificacao/notificacao-inscricao-origem.enum";

export class NotificacaoInscricao extends BaseResourceModel {

    constructor(
        public id?: number,
        public grupoSegmentoMercado?: NotificacaoGrupoSegmentoMercado,
        public nome?: string,
        public email?: string,
        public idioma?: string,
        public origem?: NotificacaoInscricaoOrigemEnum,
    ) {
        super(id);
    }

    static fromJson(data: any): NotificacaoInscricao {
        return Object.assign(new NotificacaoInscricao(), data);
    }

}