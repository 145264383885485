import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-empresas-vinculadas',
    templateUrl: './dialog-empresas-vinculadas.component.html',
    styleUrls: ['./dialog-empresas-vinculadas.component.scss']
})
export class DialogEmpresasVinculadasComponent {

    public cnpjs: any;
    public titulo: string;
    public mensagem: string;

    constructor(
        private dialogRef: MatDialogRef<DialogEmpresasVinculadasComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEmpresasVinculadasModel) {
        this.cnpjs = data.cnpjs;
        this.titulo = 'Empresas vinculadas ao usuário';
        this.mensagem = 'Selecione uma empresa para prosseguir';
    }

    onChangeFlag(event) {
        this.dialogRef.close(event.value);
    }

    formatarMascara(value: string) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    }
}

export class DialogEmpresasVinculadasModel {
    constructor(public cnpjs: any) {
    }
}
