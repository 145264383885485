import { BaseResourceModel } from "./base-resource.model";
import { Classificacao } from 'src/app/shared/shared-models/model/classificacao.model';
import { SolicitacaoClassificacao } from 'src/app/shared/shared-models/model/solicitacao-classificacao.model';
import { RequerimentoReconsideracaoDescritorConteudo } from './requerimento-reconsideracao-descritor-conteudo.model';
import { StatusClassificacaoEnum } from './../enums/status-classificacao.enum';

export class RequerimentoReconsideracao extends BaseResourceModel {

    constructor(
        public id?: number,
        public solicitacaoClassificacao?: SolicitacaoClassificacao,
        public classificacaoRequerimento?: Classificacao,
        public justificativaRequerimento?: string,
        public descritorConteudoRelations: RequerimentoReconsideracaoDescritorConteudo[] = [],
        public statusDeferimento?: any,
        public classificacaoAtribuida?: Classificacao,
        public justificativaAnalise?: string,
        public conteudoDocumentoRequerimento?: string,
        public dataCriacao?: Date,

    ) {
        super();
    }

    static fromJson(jsonData: any): RequerimentoReconsideracao {
        return Object.assign(new RequerimentoReconsideracao(), jsonData);
    }    

    static extractDescritoresRequerimento(data: RequerimentoReconsideracao) {
        return this.extractDescritoresRequerimentoByStatus(
            data, StatusClassificacaoEnum.PRETENDIDA_RECONSIDERACAO);
    }

    static extractDescritoresRequerimentoByStatus(
        data: RequerimentoReconsideracao, statusClassificacao: StatusClassificacaoEnum
    ) {
        return data.descritorConteudoRelations
            .filter(obj => obj.statusClassificacao.id == statusClassificacao)
            .map( obj => obj.descritorConteudo)
    }

}
