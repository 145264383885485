export const PATH = {
    CODIGO: '/codigo',
    PESQUISAR_NOME: '/pesquisar-nome',
    TERMOS: 'termos',
    PESSOAS: 'pessoas',
    PAISES: 'paises',
    MUNICIPIOS: 'municipios',
    ESTADOS: 'estados',
    CODIGO_POSTAL: 'codigopostal',
    EMAILS: 'emails',
    GENEROS: 'generos',
    MATERIAIS: 'materiais',
    CLASSIFICACOES: 'classificacoes',
    CATEGORIAS: 'categorias',
    EMISSORAS: 'emissoras',

};
