import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { SessionStorageKey } from '../../shared/shared-models/enums/session-storage-key.enum';
import { AuthToken } from '../class/auth-token';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';

const TOKEN = 'token';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService {
    constructor(
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService
    ) { }

    get(): AuthToken {
        return new AuthToken(this.localStorageService.get(TOKEN));
    }

    isExpired(): boolean {
        const token: string = this.localStorageService.get(TOKEN);
        if (token) {
            return moment().isBefore(moment(jwt_decode(token).exp * 1000));
        }
        return false;
    }

    revoke(): void {
        this.localStorageService.remove(TOKEN);
    }

    setarUsuarioDaLocalStorage(): any {
        const token = this.localStorageService.get(TOKEN);
        if (this.validarToken(token)) {
            this.setarTokenNaSessionStorage(token);
            this.getTokenNaSessionStorage();
            return this.decodificarToken(token);
        }
        return undefined;
    }

    validarToken(token) {
        return token && token.trim().toUpperCase();
    }

    getTokenNaSessionStorage() {
        this.sessionStorageService.getItem(SessionStorageKey.TOKEN);
    }

    setarTokenNaSessionStorage(token) {
        this.sessionStorageService.storage(SessionStorageKey.TOKEN, token);
    }

    decodificarToken(token): any {
        const parsedToken = jwt_decode(token);
        const usuario: any = {
            matricula: parsedToken.sub,
            nome: parsedToken.name,
            id: parsedToken.id,
            cnpjs: parsedToken.cnpj,
            perfis: parsedToken.perfis
        };
        usuario.nomeExibido = `${usuario.matricula} - ${usuario.nome}`;

        return usuario;
    }
}
