export const EditorCustomTemplateValues = {
    btn: {
        save: {
            icon: 'save',
            title: {
                pt: 'Salvar',
                en: 'Save',
                es: 'Ahorrar'
            }
        },
        clear: {
            icon: 'cleaning_services',
            title: {
                pt: 'Limpar',
                en: 'Clean',
                es: 'Limpio'
            }
        },
        cancel: {
            icon: 'clear',
            title: {
                pt: 'Cancelar',
                en: 'Cancel',
                es: 'Cancelar'
            }
        },
        updateTermoUso: {
            icon: 'save',
            title: {
                pt: 'Atualizar',
                en: 'Update',
                es: 'Actualizar'
            }
        }
    },
    dialog: {
        save: {
            title: "Confirmar criação de modelo",
            msg: "Deseja criar novo contéudo?"
        },
        update: {
            title: "Confirmar atualização de modelo",
            msg: "Deseja realmente sobrescrever os dados existentes?"
        },
        clear: {
            title: "Confirmar restauração de modelo",
            msg: "Deseja realmente restaurar o conteúdo original?"
        },
        cancel: {
            title: "Cancelar edição",
            msg: "Deseja realmente sair do modo de edição?"
        },
        updateTermoUso: {
            title: "Atualizar termo de uso",
            msg: "Esta nova versão, requisitará aceite a todos usuarios novamente. Deseja realmente continuar?"
        }
    },

    templateDefault: {
        pt: "<p>Ausência de conteúdo. Aguardando a confecção do mesmo na linguagem determinada por parte do administrador.</p>",
        en: "<p>Absence of content. Waiting for the preparation of the same in the language determined by the administrator.</p>",
        es: "<p>Ausencia de contenido. Esperando la elaboración del mismo en el idioma que determine el administrador.</p>"
    }

}
