import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseResourceModel } from '../../shared-models/model/base-resource.model';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';

export class BaseResourceService<T extends BaseResourceModel> {

    protected http: HttpClient;
    protected urlBase = environment.apiUrl;
    protected urlResource: string;

    constructor(
        protected apiPath: string,
        protected injector: Injector,
        protected jsonDataToResourceFn: (jsonData: any) => T
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource = environment.apiUrl + apiPath;
    }

    getAll(dto: any): Observable<MensagemRetorno> {
        let searchPage = `?currentPage=${dto.currentPage}&situacao=${dto.situacao}&nome=${dto.nome}&descricao=${dto.descricao}&pageSize=${dto.pageSize}`;
        return this.http.get<MensagemRetorno>(`${this.urlResource}${searchPage}`);
    }

    getAllByBody(dto: any): Observable<any> {
        return this.http.post<MensagemRetorno>(`${this.urlResource}/paged-list`, dto);
    }

    getByIdSegmentoMercado(id: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.urlResource}/idsegmento/${id}`);
    }

    create(contexto): Observable<any> {
        return this.http.post<any>(`${this.urlResource}`, contexto);
    }

    update(contexto): Observable<MensagemRetorno> {
        return this.http.put<MensagemRetorno>(`${this.urlResource}`, contexto);
    }

    delete(id: number): Observable<MensagemRetorno> {
        return this.http.delete<MensagemRetorno>(`${this.urlResource}/${id}`);
    }

    getById(id: number): Observable<any> {
        return this.http.get<any>(`${this.urlResource}/${id}`);
    }

    // PROTECTED METHODS
    protected jsonDataToResources(jsonData: any[]): T[] {
        const resources: T[] = [];
        jsonData.forEach(
            element => resources.push(this.jsonDataToResourceFn(element))
        );
        return resources;
    }

    protected jsonDataToResource(jsonData: any): T {
        return this.jsonDataToResourceFn(jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
