import { BaseResourceModel } from './base-resource.model';

export class TipoMidia extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
        public situacao?: string
    ) {
        super();
    }

    static fromJson(jsonData: any): TipoMidia {
        return Object.assign(new TipoMidia(), jsonData);
    }
}
