import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceService } from './base-resource.service';
import { Pessoa } from '../../shared-models/model/pessoa.model';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';

@Injectable({
    providedIn: 'root'
})
export class PessoaResourceService extends BaseResourceService<Pessoa> {

    constructor(protected injector: Injector) {
        super('/pessoas', injector, Pessoa.fromJson);
    }

    saveLoggedUser(dto: any) {
        return this.http.post<any>(`${this.urlResource}/save-logged-user`, dto);
    }

    // override
    getAll(dto: any): Observable<MensagemRetorno> {
        delete dto.filtros;
        return this.http.post<MensagemRetorno>(`${this.urlResource}/paged-list`, dto);
    }

    getByLoggedUserKeycloakId() {
        return this.http.get<Pessoa>(`${this.urlResource}/get-by-logged-user-keycloak-id`);
    }

    getPessoaByNrDocumento(nrDocumento: string): Observable<Pessoa> {
        return this.http.get<Pessoa>(`${this.urlResource}/numero-documento/${nrDocumento}`);
    }

    getByLoggedUserEmail() {
        return this.http.get<Pessoa>(`${this.urlResource}/get-by-logged-user-email`);
    }
}
