<div [formGroup]="formGroup">
    <mat-form-field class="full-width">
        <mat-label>
            {{titulo}}<span class="required">{{ isRequired ? ' *' : ''}}</span>
        </mat-label>

        <mat-select multiple [compareWith]="comparePerfis" [formControlName]="controlName"
            (selectionChange)="onChangeSelection($event.value)">

            <mat-option #allOption *ngIf="allOptionLabel" [value]="getOptionValue(allOption)" id="{{controlName}}-all">
                {{ allOptionLabel }}
            </mat-option>

            <mat-option #matOption *ngFor="let opcao of opcoes" [value]="getOptionValue(opcao)"
                [disabled]="allOptionSelected" (click)="captureMatOptionClicked(matOption)">
                {{ opcao[atributo] }}
            </mat-option>
        </mat-select>

        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl,'required')">
            {{ 'TRADUCAO' | translate: validatorMessageValues.validator.required }}
        </mat-error>
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl,'maximumNumberSelectedOptionsExceeded')">
            {{ 'TRADUCAO' | translate: validatorMessageValues.validator.maximumNumberSelectedOptionsExceeded }}
            {{ maxNumSelectedOptions }}
        </mat-error>
    </mat-form-field>
</div>
