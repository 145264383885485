<div class="termo-responsabilidade">
    <h2 class="text-center" mat-dialog-title>{{ 'HOME.TITLE' | translate }}</h2>

    <mat-dialog-content class="mat-typography text-justify">
        <app-editor-custom-template [modeloTextoEscolhido]="TERMO_RESPONSABILIDADE_REF"
            [languageAcronym]="'TRADUCAO' | translate: values.global.editorCustomTemplate.language"
            (editorPreparadoEmmiter)="onFinishEditor()" [onlyRead]="true">
        </app-editor-custom-template>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <div *ngIf="!allDataLoaded else showButtons">
            <mat-spinner class="mr-3 mb-5" [diameter]=30></mat-spinner>
        </div>
        <ng-template #showButtons>
            <button mat-raised-button color="accent" class="btn" (click)="deny()">
                {{ 'LOGIN.BUTTON-DENY' | translate }}
            </button>
            <button mat-raised-button color="primary" class="btn" (click)="accept()">
                {{ 'LOGIN.BUTTON-ACCEPT' | translate }}
            </button>
        </ng-template>
    </mat-dialog-actions>
</div>
