import { FormGroup, Validators } from "@angular/forms";

export class FormUtil {

    static markFormAsTouched(form: FormGroup) {
        Object.values(form.controls).forEach(control => control.markAsTouched());
    }

    static setFormControlAsRequired(form: FormGroup, controlName: string, required: boolean) {
        let control = form.controls[controlName];
        if (required) {
            control.setValidators([Validators.required]);
        } else {
            control.clearValidators();
        }
        control.updateValueAndValidity();
    }

    static resetFormControlAsRequired(form: FormGroup, controlName: string, required: boolean) {
        let control = form.controls[controlName];
        if (required) {
            control.setValidators([Validators.required]);
        } else {
            control.clearValidators();
            control.reset();
        }
        control.updateValueAndValidity();
    }
}