import { GlobalAttributeValues } from "./global-attribute.values";

const Status = {
    pt: 'Status',
    en: 'Status',
    es: 'Estado'
}

const Visualize = {
    pt: 'Visualizar',
    en: 'Visualize',
    es: 'Visualizar'
}

export const GlobalValues = {

    status: Status,

    action: {
        pt: 'Ação',
        en: 'Action',
        es: 'Acción'
    },

    attachFile: {
        pt: 'Anexar arquivo',
        en: 'Attach file',
        es: 'Adjuntar archivo'
    },

    active: {
        pt: 'Ativo',
        en: 'Active',
        es: 'Activo'
    },

    inactive: {
        pt: 'Inativo',
        en: 'Inactive',
        es: 'Inactivo'
    },

    cancel: {
        pt: 'Cancelar',
        en: 'Cancel',
        es: 'Cancelar'
    },

    confirm: {
        pt: 'Confirmar',
        en: 'Confirm',
        es: 'Confirmar'
    },

    clean: {
        pt: 'Limpar',
        en: 'Clean',
        es: 'Limpiar'
    },

    description: {
        pt: 'Descrição',
        en: 'Description',
        es: 'Descripción '
    },

    send: {
        pt: 'Enviar',
        en: 'Send',
        es: 'Enviar'
    },

    name: {
        pt: 'Nome',
        en: 'Name',
        es: 'Nombre'
    },

    search: {
        pt: 'Pesquisar',
        en: 'Search',
        es: 'Buscar'
    },

    searchBy: {
        pt: 'Pesquisar por',
        en: 'Search by',
        es: 'Buscar por'
    },

    situation: {
        pt: 'Situação',
        en: 'Situation',
        es: 'Situación',
    },

    visualize: Visualize,

    edit: {
        pt: 'Editar',
        en: 'Edit',
        es: 'Editar'
    },

    delete: {
        pt: 'Excluir',
        en: 'Delete',
        es: 'Borrar'
    },

    viewDocument: {
        pt: 'Visualizar Documento',
        en: 'View Document',
        es: 'Ver documento'
    },

    authorizePublicacao: {
        pt: 'Autorizar publicação',
        en: 'Authorize publication',
        es: 'autorizar publicación'
    },

    reconsideracao: {
        pt: 'Reconsideração',
        en: 'Reconsideration',
        es: 'Reconsideración'
    },

    analyseReconsideracao: {
        pt: 'Analisar reconsideração',
        en: 'Analyse Reconsideration',
        es: 'Analizar reconsideración'
    },

    corretorRegistro: {
        pt: 'Corretor de registros',
        en: 'Registry corrector',
        es: 'Corredor de registros'
    },

    emailRegistration: {
        pt: 'Cadastro de email',
        en: 'Email registration',
        es: 'Registro de email'
    },

    receiveNewsSubscriptionInstruction: {
        pt: 'Ao cadastrar seu e-mail você irá receber as últimas obras publicadas',
        en: 'By registering your email you will receive the latest published works',
        es: 'Al registrar tu correo electrónico recibirás los últimos trabajos publicados'
    },

    email: {
        pt: 'Email',
        en: 'Email',
        es: 'Correo electrónico'
    },

    confirmEmail: {
        pt: 'Confirme email',
        en: 'Confirm email',
        es: 'Confirmar correo electrónico'
    },

    groupOfWorks: {
        pt: 'Grupo de obras',
        en: 'Group of works',
        es: 'Grupo de obras'
    },

    filmesSeriesProgramasNovelas: {
        pt: 'Filmes, séries, programas e novelas',
        en: 'Movies, series, programs and soap operas',
        es: 'Películas, series, programas y telenovelas'
    },

    jogosEletronicosAppsRPG: {
        pt: 'Jogos eletrônicos, aplicativos e RPG',
        en: 'Video games, apps and RPG',
        es: 'Videojuegos, aplicaciones y RPG'
    },

    revisaoClassificacao: {
        pt: 'Revisão de Classificação',
        en: 'Classification Review',
        es: 'Revisión de Clasificación'
    },

    recycle: {
        pt: 'Reciclar',
        en: 'Recycle',
        es: 'Reciclar'
    },

    justificativa: {
        pt: 'Justificativa',
        en: 'Justification',
        es: 'Justificación'
    },

    justificativaAnalise: {
        pt: 'Justificativa da Análise',
        en: 'Justification of the Analysis',
        es: 'Justificación del Análisis'
    },

    deferimentoResult: {
        INDEFERIDO: {
            pt: 'INDEFERIDO',
            en: 'REJECTED',
            es: 'RECHAZADA'
        },

        DEFERIDO: {
            pt: 'DEFERIDO',
            en: 'GRANTED',
            es: 'OTORGADA'
        },

        PARCIALMENTE_DEFERIDO: {
            pt: 'PARCIALMENTE DEFERIDO',
            en: 'PARTIALLY GRANTED',
            es: 'OTORGADA PARCIALMENTE'
        },
    },

    btn: {
        viewDetails: {
            icon: 'zoom_in',
            label: Visualize,
            tooltip: Visualize
        },

        goBack: {
            icon: 'arrow_back',
            tooltip: {
                pt: 'Voltar',
                en: 'Go back',
                es: 'volver'
            }
        }
    },

    generatePDF: {
        pt: 'Gerar PDF',
        en: 'Generate pdf',
        es: 'Generar pdf',
        icon: 'picture_as_pdf'
    },

    tabela: {
        vazia: {
            doc: {
                pt: 'Nenhum documento encontrado.',
                en: 'No documents found.',
                es: 'No se encontraron documentos.'
            },
            registro: {
                pt: 'Nenhum registro encontrado.',
                en: 'No records found.',
                es: 'No se encontraron registros.'
            }
        },
        colunas: {
            acao: {
                pt: 'Ação',
                en: 'Action',
                es: 'Acción'
            },

            protocolo: GlobalAttributeValues.protocolo.title,

            tituloBrasil: GlobalAttributeValues.tituloNacional.title,

            tituloOriginal: GlobalAttributeValues.tituloOriginal.title,

            status: Status,

            historico: {
                pt: 'Histórico',
                en: 'Historic',
                es: 'Histórico'
            }
        }
    },

    header: {
        reloadListIcon: 'autorenew',
        reloadListTooltip: {
            pt: 'Atualizar pesquisa',
            en: 'Update search',
            es: 'actualizar la búsqueda'
        }
    },

    msg: {
        deleteConfirmation: {
            title: {
                pt: 'Confirmar exclusão',
                en: 'Confirm deletion',
                es: 'Confirmar la eliminación'
            },
            text: {
                pt: 'Tem certeza que deseja excluir',
                en: 'Are you sure you want to delete',
                es: 'Estas seguro que quieres borrarlo'
            }
        },
        spinner: [
            {
                pt: 'Carregando dados...',
                en: 'Loading data',
                es: 'Cargando datos'
            },
            {
                pt: 'Aguarde... Gerando protocolo no SEI'
            },
            {
                pt: 'Acessando SEI...'
            },
            {
                pt: 'Gerando portaria...'
            },

            {
                pt: 'Verificando usuario Classind',
                en: 'Verifying Classind User',
                es: 'Verificación de usuario de Classind'
            }
        ],

        validador: {
            required: {
                pt: 'Campo de preenchimento obrigatório',
                en: 'Required field',
                es: 'Campo requerido'
            },
            minlength: {
                pt: 'Campo precisa ter no mínimo o valor:',
                en: 'Field must have at least the value:',
                es: 'El campo debe tener al menos el valor:'
            },
            maxlength: {
                pt: 'Campo pode ter no máximo o valor:',
                en: 'Field can have at most the value:',
                es: 'El campo puede tener como máximo el valor:'
            },
            pattern: {
                pt: 'Formato de informação inválido',
                en: 'Invalid information format',
                es: 'Formato de información no válido'
            },
            email: {
                pt: 'Formato Inválido de email',
                en: 'Invalid email format',
                es: 'Formato de correo inválido'
            },
            cep: {
                pt: 'CEP com Formato inválido',
                en: 'ZIP Code with Invalid Format',
                es: 'Código postal con formato no válido'
            },

            invalidDate: {
                pt: 'Data inválida',
                en: 'Invalid date',
                es: 'Fecha invalida'
            },

            maximumNumberSelectedOptionsExceeded: {
                pt: 'Número máximo de opções selecionadas: ',
                en: 'Maximum number of options selected: ',
                es: 'Número máximo de opciones seleccionadas: '
            },

            link: {
                pt: 'Formato Inválido de link ex.(ftp | http | https://www.classind.com.br)',
                en: 'Invalid link format ex.(ftp | http | https://www.classind.com.br)',
                es: 'Formato de enlace inválido ex.(ftp | http | https://www.classind.com.br)'
            }
        }
    },

    editorCustomTemplate: {
        language: {
            pt: 'pt',
            en: 'en',
            es: 'es'
        }
    },

    icon: {
        processoOrigemEstrangeiro: {
            icon: 'public',
            tooltip: 'Inscrição processual solicitada por estrangeiro'
        }
    },

};
