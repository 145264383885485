import { BaseResourceModel } from './base-resource.model';

export class TipoClassificacao extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(jsonData: any): TipoClassificacao {
        return Object.assign(new TipoClassificacao(), jsonData);
    }
}
