import { Injectable, Injector } from '@angular/core';
import { Genero } from '../../shared-models/model/genero.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class GeneroResourceService extends BaseResourceService<Genero> {

    constructor(protected injector: Injector) {
        super('/generos', injector, Genero.fromJson);
    }
}
