import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MensagemRetorno } from 'src/app/shared/shared-models/model/mensagem-retorno';
import { CategoriaResourceService } from 'src/app/shared/shared-services/resources/categoria-resource.service';
import { ClassificacaoResourceService } from 'src/app/shared/shared-services/resources/classificacao-resource.service';
import { DiaSemanaResourceService } from 'src/app/shared/shared-services/resources/dia-semana-resource.service';
import { ElementoInterativoResource } from 'src/app/shared/shared-services/resources/elemento-interativo-resource.service';
import { EmissoraResourceService } from 'src/app/shared/shared-services/resources/emissora-resource.service';
import { GeneroResourceService } from 'src/app/shared/shared-services/resources/genero-resource.service';
import { NaturezaAnaliseResourceService } from 'src/app/shared/shared-services/resources/natureza-analise-resource.service';
import { PaisOrigemResourceService } from 'src/app/shared/shared-services/resources/pais-origem-resource.service';
import { PeriodicidadeResourceService } from 'src/app/shared/shared-services/resources/periodicidade-resource.service';
import { PlataformaResourceService } from 'src/app/shared/shared-services/resources/plataforma-resource.service';
import { PracaExibicaoResourceService } from 'src/app/shared/shared-services/resources/praca-exibicao-resource.service';
import { SegmentoMercadoResourceService } from 'src/app/shared/shared-services/resources/segmento-mercado-resource.service';
import { StatusSolicitacaoClassificacaoResourceService } from 'src/app/shared/shared-services/resources/status-solicitacao-classificacao-resource.service';
import { TipoAnuncioResourceService } from 'src/app/shared/shared-services/resources/tipo-anuncio-resource.service';
import { TipoClassificacaoResourceService } from 'src/app/shared/shared-services/resources/tipo-classificacao-resource.service';
import { TipoDocumentoResourceService } from 'src/app/shared/shared-services/resources/tipo-documento-resource.service';
import { TipoMidiaResourceService } from 'src/app/shared/shared-services/resources/tipo-midia-resource.service';

@Injectable()
export class InputBuilderService {

    constructor(
        private categoriaResourceService: CategoriaResourceService,
        private generoResourceService: GeneroResourceService,
        private tipoMidiaResourceService: TipoMidiaResourceService,
        private classificacaoResourceService: ClassificacaoResourceService,
        private naturezaAnaliseResourceService: NaturezaAnaliseResourceService,
        private segmentoMercadoResourceService: SegmentoMercadoResourceService,
        private tipoClassificacaoResourceService: TipoClassificacaoResourceService,
        private paisOrigemResourceService: PaisOrigemResourceService,
        private emissoraResourceService: EmissoraResourceService,
        private pracaExibicaoResourceService: PracaExibicaoResourceService,
        private periodicidadeResourceService: PeriodicidadeResourceService,
        private statusSolicitacaoClassificacaoResourceService: StatusSolicitacaoClassificacaoResourceService,
        private plataformaResourceService: PlataformaResourceService,
        private tipoAnuncioResourceService: TipoAnuncioResourceService,
        private tipoDocumentoResourceService: TipoDocumentoResourceService,
        private diaSemanaResourceService: DiaSemanaResourceService,
        private elementoInterativoResource: ElementoInterativoResource,
    ) { }

    private _buildPaginationDTO(): any {
        return {
            nome: '',
            descricao: '',
            situacao: 'A',
            currentPage: 0,
            pageSize: 1000,
            sortItem: null,
            totalResults: null,
            itens: null,
        };
    }

    getCategoriasByIdSegmentoMercado(segmentoMercadoId: number): Observable<any> {
        return this.categoriaResourceService.getByIdSegmentoMercado(segmentoMercadoId);
    }

    getGeneros(): Observable<any> {
        return this.generoResourceService.getAll(this._buildPaginationDTO());
    }

    getTipoMidias(): Observable<MensagemRetorno> {
        return this.tipoMidiaResourceService.getAll(this._buildPaginationDTO());
    }

    getClassificacoes(): Observable<MensagemRetorno> {
        return this.classificacaoResourceService.getAll(this._buildPaginationDTO());
    }

    getNaturezaAnalises(): Observable<any> {
        return this.naturezaAnaliseResourceService.getAll(this._buildPaginationDTO());
    }

    getSegmentosMercado(): Observable<any> {
        return this.segmentoMercadoResourceService.getAll(this._buildPaginationDTO());
    }

    getTipoClassificacoes(): Observable<any> {
        return this.tipoClassificacaoResourceService.getAll(this._buildPaginationDTO());
    }

    getPaisesOrigem(): Observable<any> {
        return this.paisOrigemResourceService.getAll(this._buildPaginationDTO());
    }

    getEmissoras(): Observable<any> {
        return this.emissoraResourceService.getAll(this._buildPaginationDTO());
    }

    getPracasExibicao(): Observable<any> {
        return this.pracaExibicaoResourceService.getAll(this._buildPaginationDTO());
    }

    getTiposDocumento(filterByTipoVisualizacao: boolean): Observable<any> {
        let paginacaoDTO = this._buildPaginationDTO();
        paginacaoDTO.filterByTipoVisualizacao = filterByTipoVisualizacao;
        return this.tipoDocumentoResourceService.getAllByBody(paginacaoDTO);
    }

    getStatusSolicitacaoClassificacao(): Observable<any> {
        return this.statusSolicitacaoClassificacaoResourceService.getAll(this._buildPaginationDTO());
    }

    getPeriodicidades(): Observable<any> {
        return this.periodicidadeResourceService.getAll(this._buildPaginationDTO());
    }

    getDiasSemana(): Observable<any> {
        return this.diaSemanaResourceService.getAll(this._buildPaginationDTO());
    }

    getPlataformasByIdSegmentoMercado(segmentoMercadoId: number): Observable<any> {
        return this.plataformaResourceService.getByIdSegmentoMercado(segmentoMercadoId);
    }

    getTiposAnuncios(): Observable<any> {
        return this.tipoAnuncioResourceService.getAll(this._buildPaginationDTO());
    }

    getAllElementosInterativos() {
        return this.elementoInterativoResource.getAll(this._buildPaginationDTO());
    }
}
