import { Injectable } from '@angular/core';
import { MatDialogRef, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdaptiveDataModel } from 'src/app/shared/shared-components/dialog/adaptive-dialog/adaptive-dialog.component';
import { DialogoInformacaoModel } from 'src/app/shared/shared-components/dialog/dialogo-informacao/dialogo-informacao.component';
import { SolicitacaoClassificacaoPaginacaoDTO } from 'src/app/shared/shared-models/dto/processo-paginacao/solicitacao-classificacao-paginacao-dto';
import { LegadoRecycleResultDTO } from 'src/app/shared/shared-models/dto/legado/legado-recycle-result.dto';
import { PaginacaoDTO } from 'src/app/shared/shared-models/dto/paginacao-dto';
import { RevisaoClassificacaoDTO } from 'src/app/shared/shared-models/dto/revisao/revisao-classificacao.dto';
import { TipoBuscaSolicitacaoClassificacaoEnum } from 'src/app/shared/shared-models/enums/tipo-busca-solicitacao-classificacao.enum';
import { HistoricoSolicitacaoClassificacaoOculta } from 'src/app/shared/shared-models/model/historico-solicitacao-classificacao-oculta.model';
import { InformacaoSistema } from 'src/app/shared/shared-models/model/informacao-sistema';
import { MensagemRetorno } from 'src/app/shared/shared-models/model/mensagem-retorno';
import { Pessoa } from 'src/app/shared/shared-models/model/pessoa.model';
import { SolicitacaoClassificacao } from 'src/app/shared/shared-models/model/solicitacao-classificacao.model';
import { AutorizarProcessoService } from 'src/app/shared/shared-services/autorizar-processo-service';
import { ApoioFacadeService } from 'src/app/shared/shared-services/facades/apoio-facade.service';
import { AnexoSolicitacaoClassificacaoResourceService } from 'src/app/shared/shared-services/resources/anexo-solicitacao-classificacao-resource.service';
import { EditorCustomTemplateResourceService } from 'src/app/shared/shared-services/resources/editor-custom-template-resource.service';
import { RequerimentoReconsideracaoResourceService } from 'src/app/shared/shared-services/resources/requerimento-reconsideracao-resource.service';
import { SolicitacaoClassificacaoResourceService } from 'src/app/shared/shared-services/resources/solicitacao-classificacao-resource.service';
import { DialogService } from 'src/app/shared/shared-services/utilitarios/dialog.service';
import { AuthHelper } from 'src/app/shared/shared-utils/auth-helper';
import { PROCESSO_ROUTE } from './processo-routes.values';
import { ProcessoValues } from './processo-values';

const SKIP_LOCATION = { skipLocationChange: true };

@Injectable({
    providedIn: 'root',
})
export class ProcessoService {

    informacoes: InformacaoSistema;
    titulo: string;
    mensagem: string;
    values: any;
    user: any;

    constructor(
        private router: Router,
        private authHelper: AuthHelper,
        private dialogService: DialogService,
        private apoioFacadeService: ApoioFacadeService,
        private solicitacaoClassificacaoResource: SolicitacaoClassificacaoResourceService,
        private anexoSolicitacaoClassificacaoResource: AnexoSolicitacaoClassificacaoResourceService,
        private autorizarProcessoService: AutorizarProcessoService,
        private requerimentoReconsideracaoResourceService: RequerimentoReconsideracaoResourceService,
        private editorCustomTemplateResourceService: EditorCustomTemplateResourceService,
    ) {
        this.values = ProcessoValues;
    }

    hasPermissao(key: string) {
        return this.authHelper.hasPermissao(this.values.permissoes[key]);
    }

    isRequerente() {
        return this.hasPermissao('requerente');
    }

    getNovaSolicitacaoClassificacao() {
        return new SolicitacaoClassificacao();
    }

    montarDadosProcessoPaginacaoDTO(paginator: MatPaginator, tipoBusca: TipoBuscaSolicitacaoClassificacaoEnum): SolicitacaoClassificacaoPaginacaoDTO {
        return this.apoioFacadeService.montarDadosProcessoPaginacaoDTO(paginator, tipoBusca);
    }

    buscarProcessos(dto: PaginacaoDTO): Observable<MensagemRetorno> {
        this._prepararSolicitacaoConsultaDTO(dto);
        return this.apoioFacadeService.getProcessos(dto);
    }

    private _prepararSolicitacaoConsultaDTO(dto: PaginacaoDTO): void {
        delete dto['nome'];
        delete dto['ordenacao'];
        delete dto['situacao'];
    }

    buscarProcessosPorStatus(dto: SolicitacaoClassificacaoPaginacaoDTO) {
        return this.apoioFacadeService.getProcessosPorStatus(dto);
    }

    getSolicitacaoClassificacao(id): Observable<MensagemRetorno> {
        return this.apoioFacadeService.getSolicitacaoClassificacao(id);
    }

    getCategorias(): Observable<any> {
        return this.apoioFacadeService.getCategorias();
    }

    getCategoriasByIdSegmentoMercado(segmentoMercadoId: number): Observable<any> {
        return this.apoioFacadeService.getCategoriasByIdSegmentoMercado(segmentoMercadoId);
    }

    getGeneros(): Observable<any> {
        return this.apoioFacadeService.getGeneros();
    }

    getTipoMidias(): Observable<MensagemRetorno> {
        return this.apoioFacadeService.getTipoMidias();
    }

    getClassificacoes(): Observable<MensagemRetorno> {
        return this.apoioFacadeService.getClassificacoes();
    }

    getNaturezaAnalises(): Observable<any> {
        return this.apoioFacadeService.getNaturezaAnalises();
    }

    getSegmentosMercado(): Observable<any> {
        return this.apoioFacadeService.getSegmentosMercado();
    }

    getTipoClassificacoes(): Observable<any> {
        return this.apoioFacadeService.getTipoClassificacoes();
    }

    getPaisesOrigem(): Observable<any> {
        return this.apoioFacadeService.getPaisesOrigem();
    }

    getEmissoras(): Observable<any> {
        return this.apoioFacadeService.getEmissoras();
    }

    getPracasExibicao(): Observable<any> {
        return this.apoioFacadeService.getPracasExibicao();
    }

    getAllTipoDocumentoByIds(ids: number[], filterByTipoVisualizacao: boolean = true): Observable<any> {
        return this.apoioFacadeService.getAllTipoDocumentoByIds(ids, filterByTipoVisualizacao);
    }

    getStatusSolicitacaoClassificacao(): Observable<any> {
        return this.apoioFacadeService.getStatusSolicitacaoClassificacao();
    }

    getPeriodicidades(): Observable<any> {
        return this.apoioFacadeService.getPeriodicidades();
    }

    getDiasSemana(): Observable<any> {
        return this.apoioFacadeService.getDiasSemana();
    }

    getUsuarioAtual(): Observable<Pessoa> {
        return this.apoioFacadeService.getUsuarioAtual();
    }

    getPlataformas(): Observable<MensagemRetorno> {
        return this.apoioFacadeService.getPlataformas();
    }

    getPlataformasByIdSegmentoMercado(segmentoMercadoId: number): Observable<any> {
        return this.apoioFacadeService.getPlataformasByIdSegmentoMercado(segmentoMercadoId);
    }

    getTiposAnuncios(): Observable<any> {
        return this.apoioFacadeService.getTiposAnuncios();
    }

    getAllAnexosBySolicitacaoClassificacao(solicitacaoId: number): Observable<any> {
        return this.apoioFacadeService.getAllAnexoSolicitacaoClassificacao(solicitacaoId);
    }

    getUltimoHistoricoBySolicitacaoClassificacaoID(id: string) {
        return this.apoioFacadeService.getUltimoHistoricoBySolicitacaoClassificacaoID(id);
    }

    submitInscricao(solicitacaoId: number) {
        return this.solicitacaoClassificacaoResource.submitInscricao(solicitacaoId);
    }

    resubmitInscricaoDevolvida(solicitacao: SolicitacaoClassificacao) {
        return this.solicitacaoClassificacaoResource.resubmitInscricaoDevolvida(solicitacao);
    }

    saveAnexo(anexo): Observable<MensagemRetorno> {
        return this.anexoSolicitacaoClassificacaoResource.save(anexo);
    }

    goToProcessos() {
        this.router.navigate([`/processo`], SKIP_LOCATION);
    }

    goToSolicitacaoForm(id: number) {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.SOLICITACAO_FORM}/${id}`], SKIP_LOCATION);
    }

    goToSolicitacaoView(id: number) {
        this.apoioFacadeService.goToSolicitacaoView(id);
    }

    goToNewSolicitacaoForm() {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.SOLICITACAO_FORM}`], SKIP_LOCATION);
    }

    goToRequerimentoReconsideracao(idSolicitacao: number) {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.REQUERIMENTO_RECONSIDERACAO}/${idSolicitacao}`], SKIP_LOCATION);
    }

    goToRecurso(idSolicitacao: number) {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.RECURSO}/${idSolicitacao}`], SKIP_LOCATION);
    }

    goToCorretorRegistro(idSolicitacao: number) {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.CORRETOR_REGISTRO}/${idSolicitacao}`], SKIP_LOCATION);
    }

    goToRevisaoClassificacao(idSolicitacao: number) {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.REVISAO_CLASSIFICACAO}/${idSolicitacao}`], SKIP_LOCATION);
    }

    goToLegadoCorretorRegistro(idSolicitacao: number, dto: LegadoRecycleResultDTO) {
        this.router.navigateByUrl(
            `/processo/${PROCESSO_ROUTE.CORRETOR_REGISTRO}/${idSolicitacao}`,
            {
                ...SKIP_LOCATION,
                state: { legadoRecycleResultDTO: dto }
            }
        );
    }

    openInfoDialogValue(dialogValue: any) {
        return this.openInfoDialog(
            dialogValue.titulo,
            dialogValue.mensagem,
            dialogValue.btnTitulo
        );
    }

    openInfoDialogAndGoToProcessos(dialogValue: any) {
        const dialogRef = this.openInfoDialogValue(dialogValue);
        this._goToProcessosAfterCloseInfoDialog(dialogRef);
    }

    openInfoDialog(title: string, message: string, btnTitle: string) {
        return this.dialogService.openInfoDialog(
            new DialogoInformacaoModel(title, message, btnTitle)
        );
    }

    private _goToProcessosAfterCloseInfoDialog(dialogRef: MatDialogRef<DialogoInformacaoModel, any>) {
        dialogRef.afterClosed().subscribe(dialogResult => {
            if (!dialogResult) {
                return;
            }
            this.goToProcessos();
        });
    }

    openItemAlreadyRegistedDialog(
        callback: Function, withTraduction: boolean, hasComplementoIdentificacao: boolean = false
    ) {
        let data = new AdaptiveDataModel();
        data.dialogValues = this.values.complementoIdentificacaoRequiredDialog.nonExists;
        if (hasComplementoIdentificacao) {
            data.dialogValues = this.values.complementoIdentificacaoRequiredDialog.alreadyExists;
        }
        data.callback = callback;
        data.isTraduction = withTraduction;

        this.dialogService.openAdaptativeDialog(data);
    }

    delete(id: number): Observable<MensagemRetorno> {
        return this.solicitacaoClassificacaoResource.delete(id);
    }

    autorizePublicacao(idSolicitacao: number, successCallback: Function, errorCallback?: Function) {
        this.autorizarProcessoService.autorizePublicacao(idSolicitacao, successCallback, errorCallback);
    }

    getRequerimentoReconsideracaoBySolicitacao(idSolicitacao: number) {
        return this.requerimentoReconsideracaoResourceService.getBySolicitacaoId(idSolicitacao);
    }

    createRequerimentoReconsideracao(dto: any) {
        return this.requerimentoReconsideracaoResourceService.create(dto);
    }

    sendAnalyseOfRequerimentoReconsideracao(dto: any) {
        return this.requerimentoReconsideracaoResourceService.update(dto);
    }

    getModeloTextoCustomizadoByTipoModelo(tipoModelo: number) {
        return this.editorCustomTemplateResourceService.getModeloTextoCustomizadoByTipoModelo(tipoModelo);
    }

    getGenerosPrograma(): Observable<any> {
        return this.apoioFacadeService.getGenerosPrograma();
    }

    createRevisaoClassificacao(dto: RevisaoClassificacaoDTO) {
        return this.solicitacaoClassificacaoResource.createRevisao(dto);
    }

    changeVisibilidade(justificativa: HistoricoSolicitacaoClassificacaoOculta) {
        return this.solicitacaoClassificacaoResource.changeVisibilidade(justificativa);
    }

    getAllElementosInterativos() {
        return this.apoioFacadeService.getAllElementosInterativos();
    }

}
