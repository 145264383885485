import { BaseResourceModel } from './base-resource.model';

export class Periodicidade extends BaseResourceModel {
    constructor(
        public nmPeriodicidade?: string,
        public dsPeriodicidade?: string,
    ) {
        super();
    }

    static fromJson(jsonData: any): Periodicidade {
        return Object.assign(new Periodicidade(), jsonData);
    }
}
