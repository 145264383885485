import { Injectable, Injector } from '@angular/core';
import { SegmentoMercado } from '../../shared-models/model/segmento-mercado.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class SegmentoMercadoResourceService extends BaseResourceService<SegmentoMercado> {
    
    constructor(protected injector: Injector) {
        super('/segmentos', injector, SegmentoMercado.fromJson);
    }
    
}
