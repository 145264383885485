import { BaseResourceModel } from "./base-resource.model";
import { SolicitacaoClassificacao } from './solicitacao-classificacao.model';
import { TipoDocumento } from './tipo-documento.model';

export class AnexoSolicitacaoClassificacao extends BaseResourceModel {
    constructor(
        public idPublicacaoDou?: number,
        public solicitacaoClassificacao?: SolicitacaoClassificacao,
        public dsAnexo?: string,
        public dsLocalAnexo?: string,
        public tipoDocumento?: TipoDocumento,
        public dataAnexo?: number,
        public idDocumentoSei?: string
    ) {
        super();
    }

    static fromJson(jsonData: any): AnexoSolicitacaoClassificacao {
        return Object.assign(new AnexoSolicitacaoClassificacao(), jsonData);
    }
}
