import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PATH } from '../shared-utils/consts/const-path-endpoints-util';
import { PessoaGenericService } from './pessoa-generic-service';
import { TermoAceiteResourceService } from './resources/termo-aceite-resource.service';

@Injectable({
    providedIn: 'root'
})
export class TermoService extends PessoaGenericService<any> {

    constructor(
        private resource: TermoAceiteResourceService,
        public http: HttpClient
    ) {
        super(http);
        this.path = PATH.TERMOS;
    }

    createForLoggedUser(cnpj: string) {
        return this.resource.createForLoggedUser(cnpj);
    }

    existsByLoggedUser(cnpj: string) {
        return this.resource.existsByLoggedUser(cnpj);
    }

    deleteAll() {
        return this.resource.deleteAll();
    }

}
