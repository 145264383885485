import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthKeycloakService } from './core/auth/auth-keycloak.service';
import { CoreModule } from './core/core.module';
import { MaterialDesignModule } from './core/material-design/material-design.module';
import { InternacionalizacaoComponent } from './shared/shared-components/internacionalizacao/internacionalizacao.component';
import { SharedComponentsModule } from './shared/shared-components/shared-components.module';
import { PhoneMaskAddDirective } from './shared/shared-directives/phone-mask-add.directive';
import { PhoneMaskEditDirective } from './shared/shared-directives/phone-mask-edit.directive';
import { InformacaoSistema } from './shared/shared-models/model/informacao-sistema';
import { getPortuguesPaginatorIntl } from './shared/shared-utils/portuguese-paginator-intl.util';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function initializeKeycloak(keycloak: KeycloakService) {
    return function () {
        return keycloak.init({
            config: {
                url: environment.ssoUrl,
                realm: environment.ssoRealm,
                clientId: environment.ssoClientId,
            },
            bearerExcludedUrls: ['/assets']
        });
    }
}

const maskConfig: Partial<IConfig> = {
    validation: true,
};

@NgModule({
    declarations: [
        AppComponent,
        PhoneMaskAddDirective,
        PhoneMaskEditDirective,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        HttpClientModule,
        SharedComponentsModule,
        KeycloakAngularModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxMaskModule.forRoot(maskConfig),
    ],
    providers: [
        { provide: 'InformacaoSistema', useValue: new BehaviorSubject(new InformacaoSistema()) },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, AuthKeycloakService]
        },
        { provide: MatPaginatorIntl, useValue: getPortuguesPaginatorIntl() }
    ],
    exports: [
        TranslateModule,
        InternacionalizacaoComponent,
        MaterialDesignModule

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
