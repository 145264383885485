export enum SituacaoPadraoEnum {
    ATIVO = 'A',
    INATIVO = 'I'
}

export const SituacaoPadraoEnumMapper = {
    [SituacaoPadraoEnum.ATIVO]: 'Ativo',
    [SituacaoPadraoEnum.INATIVO]: 'Inativo'
};

export const SituacaoPadraoEnumOptions = [
    { value: SituacaoPadraoEnum.ATIVO, label: SituacaoPadraoEnumMapper.A },
    { value: SituacaoPadraoEnum.INATIVO, label: SituacaoPadraoEnumMapper.I },
];
