import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PessoaDTO } from 'src/app/shared/shared-models/dto/pessoa-dto';
import { PessoaTipoInsercao } from 'src/app/shared/shared-models/enums/pessoa-tipo-insercao.enum';
import { SituacaoPadraoEnum } from 'src/app/shared/shared-models/enums/situacao-padrao.enum';
import { Pessoa } from 'src/app/shared/shared-models/model/pessoa.model';
import { MensageriaService } from 'src/app/shared/shared-services/mensageria-service';
import { NavegacaoUtilService } from 'src/app/shared/shared-services/navegacao-util.service';
import { PessoaResourceService } from 'src/app/shared/shared-services/resources/pessoa-resource.service';
import { SpinnerVinhetaKeys } from 'src/app/shared/shared-services/utilitarios/spinner-vinheta/spinner-vinheta-keys';
import { SpinnerService } from 'src/app/shared/shared-services/utilitarios/spinner-vinheta/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class CadastroAcessoService {

    constructor(
        private pessoaResource: PessoaResourceService,
        private mensageriaService: MensageriaService,
        private spinnerService: SpinnerService,
        private navegacaoUtilService: NavegacaoUtilService
    ) { }

    saveLoggedUser(dto: PessoaDTO) {
        return this.pessoaResource.saveLoggedUser(dto);
    }

    getPessoaByLoggedUserKeycloakId() {
        return this.pessoaResource.getByLoggedUserKeycloakId();
    }

    getPessoaByNrDocumento(nrDocumento: string): Observable<Pessoa> {
        return this.pessoaResource.getPessoaByNrDocumento(nrDocumento);
    }

    getPessoaByLoggedUserEmail() {
        return this.pessoaResource.getByLoggedUserEmail();
    }

    atualizaPessoaInternaAutomaticamente(dadosPessoaInterna: Pessoa) {
        dadosPessoaInterna.tipoInsercao = PessoaTipoInsercao.EXTERNO
        this.spinnerService.startSpinnerVinheta(SpinnerVinhetaKeys.CONFIGURANDO_USUARIO_ADM);
        this.pessoaResource.update(dadosPessoaInterna)
            .pipe(take(1))
            .subscribe(() => {
                this.mensageriaService.showMensagemSucesso();
                this.navegacaoUtilService.toHome();
            }, erro => {
                this.mensageriaService.handleApiError(erro);
            })
            .add(() => this.spinnerService.endSpinnerVinheta(1000));
    }

    cadastrarPessoaInternaAutomaticamente(dadosPessoaInterna: any) {
        let pessoaInterna: PessoaDTO = {
            nome: this._concatenarNomePrincipal(dadosPessoaInterna),
            idKeycloak: dadosPessoaInterna.id,
            situacao: SituacaoPadraoEnum.ATIVO,
            email: dadosPessoaInterna.email,
            isRequerente: false,
            tipoInsercao: PessoaTipoInsercao.EXTERNO
        };

        this.spinnerService.startSpinnerVinheta(SpinnerVinhetaKeys.CONFIGURANDO_USUARIO_ADM);
        this.pessoaResource.create(pessoaInterna)
            .pipe(take(1))
            .subscribe(() => {
                this.mensageriaService.showMensagemSucesso();
                this.navegacaoUtilService.toHome();
            }, erro => {
                this.mensageriaService.handleApiError(erro);
            })
            .add(() => this.spinnerService.endSpinnerVinheta(1000));
    }

    private _concatenarNomePrincipal(dadosPessoaInterna: any): string {
        return `${dadosPessoaInterna.firstName} ${dadosPessoaInterna.lastName}`;
    }
}
