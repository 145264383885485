import { BaseResourceModel } from "./base-resource.model";
import { JustificativaDevolucao } from "./justificativa-devolucao.model";
import { Pessoa } from "./pessoa.model";
import { SolicitacaoClassificacao } from "./solicitacao-classificacao.model";

export class Historico extends BaseResourceModel {
    constructor(
        public acao?: string,
        public dataHistorico?: string,
        public idSolicitacaoClassificacao?: number,
        public justificativasDevolucao?: Array<JustificativaDevolucao>,
        public segmentoMercado?: string,
        public status?: string,
        public pessoa?: string,
        public nomeUsuarioSolicitacao?: string,
        public cpfUsuarioSolicitacao?: string
    ) {
        super();
    }

    static builder(pessoa: Pessoa, solicitacaoClassificacao: SolicitacaoClassificacao, justificativasDevolucao: JustificativaDevolucao[]) {
        let historico = new Historico();
        historico.idSolicitacaoClassificacao = solicitacaoClassificacao.id;
        historico.justificativasDevolucao = justificativasDevolucao;
        historico.segmentoMercado = solicitacaoClassificacao.segmentoMercado.id.toString();
        historico.pessoa = pessoa.id.toString();
        return historico;
    }

    static fromJson(jsonData: any): Historico {
        return Object.assign(new Historico(), jsonData);
    }


}
