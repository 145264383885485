export const ROLES: any = {
    
    COMPOSITE_REQUERENTE: 'requerente',

    AREAS_TECNICAS_CINEMA_HOME_VIDEO_VISUALIZAR: 'areas_tecnicas:cinema_home_video:visualizar',
    AREAS_TECNICAS_CINEMA_HOME_VIDEO_EDITAR: 'areas_tecnicas:cinema_home_video:editar',
    AREAS_TECNICAS_JOGOS_RPG_VISUALIZAR: 'areas_tecnicas:jogos_rpg:visualizar',
    AREAS_TECNICAS_JOGOS_RPG_EDITAR: 'areas_tecnicas:jogos_rpg:editar',
    AREAS_TECNICAS_SEAC_VOD_VISUALIZAR: 'areas_tecnicas:seac_vod:visualizar',
    AREAS_TECNICAS_SEAC_VOD_EDITAR: 'areas_tecnicas:seac_vod:editar',
    AREAS_TECNICAS_TV_ABERTA_VISUALIZAR: 'areas_tecnicas:tv_aberta:visualizar',
    AREAS_TECNICAS_TV_ABERTA_EDITAR: 'areas_tecnicas:tv_aberta:editar',
    AREAS_TECNICAS_RADIO_VISUALIZAR: 'areas_tecnicas:radio:visualizar',
    AREAS_TECNICAS_RADIO_EDITAR: 'areas_tecnicas:radio:editar',

    CATEGORIA_CADASTRAR: 'categoria:cadastrar',
    CATEGORIA_EDITAR: 'categoria:editar',
    CATEGORIA_EXCLUIR: 'categoria:excluir',

    CLASSIFICACAO_CADASTRAR: 'classificacao:cadastrar',
    CLASSIFICACAO_EDITAR: 'classificacao:editar',
    CLASSIFICACAO_EXCLUIR: 'classificacao:excluir',

    DESCRITOR_CONTEUDO_CADASTRAR: 'descritor_conteudo:cadastrar',
    DESCRITOR_CONTEUDO_EDITAR: 'descritor_conteudo:editar',
    DESCRITOR_CONTEUDO_EXCLUIR: 'descritor_conteudo:excluir',

    ELEMENTO_INTERATIVO_CADASTRAR: 'elemento_interativo:cadastrar',
    ELEMENTO_INTERATIVO_EDITAR: 'elemento_interativo:editar',
    ELEMENTO_INTERATIVO_EXCLUIR: 'elemento_interativo:excluir',

    EMISSORA_CADASTRAR: 'emissora:cadastrar',
    EMISSORA_EDITAR: 'emissora:editar',
    EMISSORA_EXCLUIR: 'emissora:excluir',

    HORARIO_RECOMENDADO_CADASTRAR: 'horario_recomendado:cadastrar',
    HORARIO_RECOMENDADO_EDITAR: 'horario_recomendado:editar',
    HORARIO_RECOMENDADO_EXCLUIR: 'horario_recomendado:excluir',

    LEGADO_RECICLAR: 'legado:reciclar',

    MENU_INICIO: 'menu_inicio:visualizar',
    MENU_ADMIN: 'menu_administracao:visualizar',
    MENU_ADMIN_CATEGORIA: 'menu_admin_categoria:visualizar',
    MENU_ADMIN_CLASSIFICACAO: 'menu_admin_classificacao:visualizar',
    MENU_ADMIN_DESCRITOR_CONTEUDO: 'menu_admin_descritor_conteudo:visualizar',
    MENU_ADMIN_ELEMENTOS_INTERATIVOS: 'menu_admin_elementos_interativos:visualizar',
    MENU_ADMIN_EMISSORAS: 'menu_admin_emissoras:visualizar',
    MENU_ADMIN_HORARIO_RECOMENDADO: 'menu_admin_horario_recomendado:visualizar',
    MENU_ADMIN_PLATAFORMAS: 'menu_admin_plataformas:visualizar',
    MENU_ADMIN_PORTARIA: 'menu_admin_portaria:visualizar',
    MENU_ADMIN_SEGMENTOS: 'menu_admin_segmentos:visualizar',
    MENU_ADMIN_TIPO_DOCUMENTO: 'menu_admin_tipo_documento:visualizar',
    MENU_ADMIN_TIPO_MIDIA: 'menu_admin_tipo_midia:visualizar',
    MENU_ADMIN_REPRESENTANTE_LEGAL: 'menu_admin_representante_legal:visualizar',
    MENU_ADMIN_REABERTURA_PRAZO: 'menu_reabertura_prazo:visualizar',
    MENU_ADMIN_OCULTAR_OBRAS: 'menu_admin_ocultar_obras:visualizar',

    MENU_PROCESSO: 'menu_processo:visualizar',
    MENU_TRIAGEM: 'menu_triagem:visualizar',
    MENU_ANALISE: 'menu_analise:visualizar',
    MENU_ANALISE_JOGOS_RPG: 'menu_analise_jogos_rpg:visualizar',
    MENU_ANALISE_CINEMA_HOMEVIDEO: 'menu_analise_cinema_homevideo:visualizar',
    MENU_ANALISE_SEAC_VOD: 'menu_analise_seac_vod:visualizar',
    MENU_ANALISE_TV_ABERTA: 'menu_analise_tv_aberta:visualizar',
    MENU_ANALISE_RADIO: 'menu_analise_radio:visualizar',
    MENU_PUBLICACAO: 'menu_publicacao:visualizar',
    MENU_RELATORIO: 'menu_relatorio:visualizar',
    MENU_RELATORIO_DINAMICO: 'menu_relatorio_dinamico:visualizar',
    MENU_USUARIO: 'menu_usuario:visualizar',
    MENU_EXTRACAO_DADOS: 'menu_extracao_dados:visualizar',
    MENU_AUDITORIA: 'menu_auditoria:visualizar',

    MODELO_PORTARIA_CADASTRAR: 'modelo_portaria:cadastrar',
    MODELO_PORTARIA_EDITAR: 'modelo_portaria:editar',
    MODELO_PORTARIA_EXCLUIR: 'modelo_portaria:excluir',

    PLATAFORMA_CADASTRAR: 'plataforma:cadastrar',
    PLATAFORMA_EDITAR: 'plataforma:editar',
    PLATAFORMA_EXCLUIR: 'plataforma:excluir',

    PUBLICACAO_AGUARDANDO_PUBLICACAO_VISUALIZAR: 'publicacao:aguardando_publicacao:visualizar',
    PUBLICACAO_AGUARDANDO_PUBLICACAO_EDITAR: 'publicacao:aguardando_publicacao:editar',
    PUBLICACAO_GERAR_PORTARIA_VISUALIZAR: 'publicacao:gerar_portaria:visualizar',
    PUBLICACAO_GERAR_PORTARIA_EDITAR: 'publicacao:gerar_portaria:editar',

    REPRESENTANTE_LEGAL_VISUALIZAR: 'pessoa:visualizar',
    REPRESENTANTE_LEGAL_CADASTRAR: 'pessoa:cadastrar',
    REPRESENTANTE_LEGAL_EDITAR: 'pessoa:editar',
    REPRESENTANTE_LEGAL_EXCLUIR: 'pessoa:excluir',

    REQUERIMENTO_RECONSIDERACAO_CADASTRAR: 'requerimento_reconsideracao:cadastrar',
    REQUERIMENTO_RECONSIDERACAO_ANALISAR: 'requerimento_reconsideracao:analisar',
    REQUERIMENTO_RECONSIDERACAO_VISUALIZAR: 'requerimento_reconsideracao:visualizar',

    REQUERIMENTO_RECURSO_ANALISAR: 'recurso:analisar',
    REQUERIMENTO_RECURSO_VISUALIZAR: 'recurso:visualizar',

    REVISAO_CLASSIFICACAO_VISUALIZAR: 'revisao_classificacao:visualizar',
    REVISAO_CLASSIFICACAO_ANALISAR: 'revisao_classificacao:analisar',

    SEGMENTO_MERCADO_CADASTRAR: 'segmento_mercado:cadastrar',
    SEGMENTO_MERCADO_EDITAR: 'segmento_mercado:editar',
    SEGMENTO_MERCADO_EXCLUIR: 'segmento_mercado:excluir',

    SOLICITACAO_CLASSIFICACAO_EDITAR: 'solicitacao_classificacao:editar',
    SOLICITACAO_CLASSIFICACAO_CADASTRAR: 'solicitacao_classificacao:cadastrar',
    SOLICITACAO_CLASSIFICACAO_VISUALIZAR: 'solicitacao_classificacao:visualizar',
    SOLICITACAO_CLASSIFICACAO_EXCLUIR: 'solicitacao_classificacao:excluir',
    SOLICITACAO_CLASSIFICACAO_AUTORIZAR_PUBLICACAO: 'solicitacao_classificacao:autorizar_publicacao',

    TIPO_DOCUMENTO_CADASTRAR: 'tipo_documento:cadastrar',
    TIPO_DOCUMENTO_EDITAR: 'tipo_documento:editar',
    TIPO_DOCUMENTO_EXCLUIR: 'tipo_documento:excluir',

    TIPO_MIDIA_CADASTRAR: 'tipo_midia:cadastrar',
    TIPO_MIDIA_EDITAR: 'tipo_midia:editar',
    TIPO_MIDIA_EXCLUIR: 'tipo_midia:excluir',

    TRIAGEM_INSCRICAO_PROCESSUAL_EDITAR: 'triagem_inscricao_processual:editar',
    TRIAGEM_INSCRICAO_PROCESSUAL_VISUALIZAR: 'triagem_inscricao_processual:visualizar',
    TRIAGEM_PENDENCIAS_EDITAR: 'triagem_pendencias:editar',
    TRIAGEM_PENDENCIAS_VISUALIZAR: 'triagem_pendencias:visualizar',
    TRIAGEM_SOBRESTADO_EDITAR: 'triagem_sobrestado:editar',
    TRIAGEM_SOBRESTADO_VISUALIZAR: 'triagem_sobrestado:visualizar',

    REABERTURA_PRAZO_CADASTRAR: 'reabertura_prazo:cadastrar',
    REABERTURA_PRAZO_EDITAR: 'reabertura_prazo:editar',
    REABERTURA_PRAZO_EXCLUIR: 'reabertura_prazo:excluir',

    CUSTOM_TEMPLATE_EDITAR: 'custom_template:editar',

    NOTIFICACAO_PENDECIAS_VISUALIZAR: 'notificacao_pendencias:visualizar',

    CORRETOR_REGISTRO_VISUALIZAR: 'corretor_registro:visualizar'
};
