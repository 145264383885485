import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialDesignModule } from 'src/app/core/material-design/material-design.module';
import { TableLegendComponent } from './table-legend/table-legend.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialDesignModule
    ],
    declarations: [
        TableLegendComponent,
    ],    
    exports: [
        TableLegendComponent,
    ]
})
export class TableModule { }
