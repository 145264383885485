<div fxLayoutAlign="center center">
    <h1 mat-dialog-title>{{titulo}}</h1>
</div>
<div mat-dialog-content class="mb-3">
    <p *ngIf="!data.isHtmlText else showTextAsHtml" class="dialogo-mensagem">{{mensagem}}</p>
    <ng-template #showTextAsHtml>
        <div [innerHtml]="mensagem"></div>
    </ng-template>
</div>
<div mat-dialog-actions fxLayoutAlign="space-around center">
    <button mat-button (click)="onDismiss()">
        {{'TRADUCAO'| translate: values.global.cancel}}
    </button>
    <button mat-raised-button color="primary" (click)="onConfirm()">
        {{'TRADUCAO'| translate: values.global.confirm}}
    </button>
</div>