import { BaseResourceModel } from './base-resource.model';

export class SegmentoMercado extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
        public permanente?: boolean,
        public idProcedimentoSei?: string,
        public idUnidadeSei?: string
    ) {
        super();
    }

    static fromJson(jsonData: any): SegmentoMercado {
        return Object.assign(new SegmentoMercado(), jsonData);
    }
}
