import { BaseResourceModel } from "./base-resource.model";
import { SolicitacaoClassificacao } from './solicitacao-classificacao.model';
import { Classificacao } from './classificacao.model';
import { StatusClassificacao } from './status-classificacao.model';

export class HistoricoClassificacao extends BaseResourceModel {

    constructor(
        public id?: number,
        public solicitacaoClassificacao?: SolicitacaoClassificacao,
        public classificacao?: Classificacao,
        public statusClassificacao?: StatusClassificacao
    ) {
        super(id);
    }

    static fromJson(jsonData: any): HistoricoClassificacao {
        return Object.assign(new HistoricoClassificacao(), jsonData);
    }

}