import { BaseResourceModel } from './base-resource.model';

export class StatusSolicitacaoClassificacao extends BaseResourceModel {
    
    constructor(
        public id?: number,
        public nome?: string
    ) {
        super(id);
    }

    static fromJson(jsonData: any): StatusSolicitacaoClassificacao {
        return Object.assign(new StatusSolicitacaoClassificacao(), jsonData);
    }
}
