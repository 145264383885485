import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class PaisOrigemResourceService extends BaseResourceService<any> {
    constructor(protected injector: Injector) {
        super('/paises', injector, null);
    }
}
