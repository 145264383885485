import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialDesignModule } from '../../../core/material-design/material-design.module';
import { AbstractInputComponent } from './abstract-input/abstract-input.component';
import { InputCpfCnpjComponent } from './input-cpf-cnpj/input-cpf-cnpj.component';
import { InputDateOnlyYearComponent } from './input-date-only-year/input-date-only-year.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputEmailComponent } from './input-email/input-email.component';
import { InputMultiSelectComponent } from './input-multi-select/input-multi-select.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { InputSimpleSelectComponent } from './input-simple-select/input-simple-select.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputBuilderComponent } from './input-builder/input-builder.component';
import { InputBuilderService } from './input-builder/input-builder.service';
import { TranslateModule } from '@ngx-translate/core';
import { InputCepComponent } from './input-cep/input-cep.component';
import { InputDateRangeComponent } from './input-date-range/input-date-range.component';
import { InputMultiSelectWithDesactiveModeComponent } from './input-multi-select-with-desactive-mode/input-multi-select-with-desactive-mode.component';
import { InputSelectClassificacaoComponent } from './selects/input-select-classificacao/input-select-classificacao.component';
import { InputMultiSelectDescritoresConteudoComponent } from './selects/input-multi-select-descritores-conteudo/input-multi-select-descritores-conteudo.component';
import { InputSelectPairAtribuirClassificacaoComponent } from './selects/input-select-pair-atribuir-classificacao/input-select-pair-atribuir-classificacao.component';
import { InputSelectCategoriaComponent } from './selects/input-select-categoria/input-select-categoria.component';
import { InputLinkComponent } from './input-link/input-link.component';
import { InputSelectCategorizedComponent } from './selects/input-select-categorized/input-select-categorized.component';

@NgModule({
    declarations: [
        AbstractInputComponent,
        InputTextComponent,
        InputTextAreaComponent,
        InputNumberComponent,
        InputDateComponent,
        InputSimpleSelectComponent,
        InputMultiSelectComponent,
        InputDateOnlyYearComponent,
        InputCpfCnpjComponent,
        InputEmailComponent,
        InputPhoneComponent,
        InputBuilderComponent,
        InputCepComponent,
        InputDateRangeComponent,
        InputMultiSelectWithDesactiveModeComponent,
        InputSelectClassificacaoComponent,
        InputMultiSelectDescritoresConteudoComponent,
        InputSelectPairAtribuirClassificacaoComponent,
        InputSelectCategoriaComponent,
        InputLinkComponent,
        InputSelectCategorizedComponent,
    ],
    imports: [
        CommonModule,
        MaterialDesignModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        NgxMaskModule.forChild(),
        TextMaskModule,
        TranslateModule
    ],
    exports: [
        InputTextComponent,
        InputTextAreaComponent,
        InputNumberComponent,
        InputDateComponent,
        InputSimpleSelectComponent,
        InputMultiSelectComponent,
        InputDateOnlyYearComponent,
        InputCpfCnpjComponent,
        InputEmailComponent,
        InputPhoneComponent,
        InputBuilderComponent,
        InputCepComponent,
        InputDateRangeComponent,
        InputMultiSelectWithDesactiveModeComponent,
        InputSelectClassificacaoComponent,
        InputMultiSelectDescritoresConteudoComponent,
        InputSelectPairAtribuirClassificacaoComponent,
        InputSelectCategoriaComponent,
        InputLinkComponent,
        InputSelectCategorizedComponent
    ],
    providers: [InputBuilderService]
})
export class InputsModule { }
