<div [formGroup]="formGroup">
    <mat-form-field class="full-width">
        <mat-label>
            {{title}}<span class="required">{{ isRequired ? ' *' : ''}}</span>
        </mat-label>
        
        <input type="email" matInput [readonly]=readOnly [placeholder]="placeholder" [name]="controlName"
            [formControlName]="controlName" [maxlength]="maxlength">
        
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl,'required')">
            {{ 'TRADUCAO' | translate: validatorMessageValues.validator.required }}
        </mat-error>
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl, 'email')">
            {{ 'TRADUCAO' | translate:validatorMessageValues.validator.email}}
        </mat-error>
    </mat-form-field>
</div>
