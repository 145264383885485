import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class AuthHelper {
    constructor(
        private keycloak: KeycloakService
    ) {
    }

    public hasPermissao(permissao?: string): boolean {
        return this.keycloak.isUserInRole(permissao, environment.ssoClientId);
    }

    public logout() {
        localStorage.clear();
        this.keycloak.logout(window.location.origin);
    }
}
