import { Injectable, Injector } from '@angular/core';
import { TipoDocumento } from '../../shared-models/model/tipo-documento.model';
import { BaseResourceService } from './base-resource.service';
import { TipoDocumentoVisualizacaoDTO } from './../../shared-models/dto/tipo-documento-visualizacao-dto';

@Injectable({
    providedIn: 'root'
})
export class TipoDocumentoResourceService extends BaseResourceService<TipoDocumento>{
    
    constructor(protected injector: Injector) {
        super('/tipo-documento', injector, TipoDocumento.fromJson);
    }

    getTiposVisualizacao() {
        return this.http.get<TipoDocumentoVisualizacaoDTO[]>(`${this.urlResource}/tipo-visualizacao`);
    }

}
