import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
import * as jwt_decode from 'jwt-decode';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SessionStorageKey } from 'src/app/shared/shared-models/enums/session-storage-key.enum';
import { UsuarioModel } from 'src/app/shared/shared-models/model/usuario-model';

const TOKEN_QUERY_PARAM = 'token';

@Injectable({
    providedIn: 'root'
})
export class TokenUtilService {

    constructor(private sessionStorageService: SessionStorageService) {
    }

    decodificarToken(token): any {
        const parsedToken = jwt_decode(token);
        const usuario: any = {
            matricula: parsedToken.sub,
            nome: parsedToken.name,
            id: parsedToken.id,
            cnpjs: parsedToken.cnpj,
            perfis: parsedToken.perfis
        };
        usuario.nomeExibido = `${usuario.matricula} - ${usuario.nome}`;

        return usuario;
    }

    getModulosToken(token): any {
        const parsedToken = jwt_decode(token);
        const modulos: any[] = parsedToken.perfis;
        return modulos.map(mod => mod.modulo.nome);
    }

    getCpfDoToken(): string {
        const usuario = this.acessarUsuario();
        return usuario.matricula;
    }

    // getModulos(modulo: string): PerfilModuloDTO {
    //     const usuario = this.acessarUsuario();
    //     const perfis: PerfilModuloDTO[] = usuario.perfis;
    //     const modulos = perfis.filter(perfil => perfil.modulo.nome === modulo)[0];
    //     return modulos;
    // }

    possuiToken() {
        return !!this.acessarTokenNaLocalStorage();
    }

    acessarUsuario() {
        const token = this.acessarTokenNaLocalStorage();
        return this.decodificarToken(token);
    }

    validarToken(token) {
        return token && token.trim().toUpperCase();
    }

    acessarTokenNaRota(activatedRoute: ActivatedRouteSnapshot) {
        return activatedRoute.queryParams[TOKEN_QUERY_PARAM];
    }

    acessarTokenNaLocalStorage() {
        return this.sessionStorageService.getItem(SessionStorageKey.TOKEN);
    }

    setarTokenNaSessionStorage(token) {
        this.sessionStorageService.storage(SessionStorageKey.TOKEN, token);
    }

    setarUsuarioDaLocalStorage(): UsuarioModel {
        const token = this.acessarTokenNaLocalStorage();
        if (this.validarToken(token)) {
            this.setarTokenNaSessionStorage(token);
            return this.decodificarToken(token);
        }
        return undefined;
    }

    setarUsuarioDaRota(activatedRoute: ActivatedRouteSnapshot): UsuarioModel {
        const token = this.acessarTokenNaRota(activatedRoute);
        if (this.validarToken(token)) {
            this.setarTokenNaSessionStorage(token);
            return this.decodificarToken(token);
        }
        return undefined;
    }


}
