import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Values } from 'src/app/shared/shared-models/class/values';
import { GlobalValues } from 'src/app/shared/shared-values/global-values';
import { TraductionModel } from 'src/assets/i18n/traduction-model';

@Component({
    selector: 'app-adaptive-dialog',
    templateUrl: './adaptive-dialog.component.html',
    styleUrls: ['./adaptive-dialog.component.scss']
})
export class AdaptiveDialogComponent {
    values: Values;

    constructor(
        public dialogRef: MatDialogRef<AdaptiveDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AdaptiveDataModel
    ) {
        dialogRef.disableClose = true;
        this.values = {
            global: GlobalValues,
            component: data.dialogValues
        }
    }

    onConfirm(): void {
        this.dialogRef.close(true);
        if (this.data.callback) {
            this.data.callback();
        }
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}

export class AdaptiveDataModel {
    dialogValues: AdaptiveDialogValues;
    callback: Function;
    isTraduction: boolean;
}

export class AdaptiveDialogValues {
    title: TraductionModel;
    content: TraductionModel;
    btnSubmit: TraductionModel;
    btnCancel: TraductionModel;
}


