import { BaseResourceModel } from "./base-resource.model";
import { SolicitacaoClassificacao } from './solicitacao-classificacao.model';
import { DescritorConteudo } from 'src/app/shared/shared-models/model/descritor-conteudo.model';
import { StatusClassificacao } from './status-classificacao.model';

export class HistoricoDescritorConteudo extends BaseResourceModel {

    constructor(
        public id?: number,
        public solicitacaoClassificacao?: SolicitacaoClassificacao,
        public descritorConteudo?: DescritorConteudo,
        public statusClassificacao?: StatusClassificacao
    ) {
        super(id);
    }

    static fromJson(jsonData: any) {
        return Object.assign(new HistoricoDescritorConteudo(), jsonData);
    }
}