import { BaseResourceModel } from "./base-resource.model";

export class GeneroPrograma extends BaseResourceModel {

    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
    ) {
        super(id);
    }

    static fromJson(data: any): GeneroPrograma {
        return Object.assign(new GeneroPrograma(), data);
    }
    
}