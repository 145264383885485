import { LoginOrigemEnum } from '../enums/login-origem.enum';
import { PessoaOrigemEnum } from '../enums/pessoa-origem.enum';
import { UserNotification } from './notification.model';
import { Pessoa } from './pessoa.model';

export class InformacaoSistema {
    nome: string;
    sso: any;
    cpf: string;
    cnpj: string;
    sistema: string;
    sistemaDescricao: string;
    versaoFrontend: string;
    versaoBackend: string;
    pessoa: Pessoa;
    notificacoes: UserNotification;
    origem: PessoaOrigemEnum;


    isAcessoInterno(): boolean {
        return !!this.sso && this.sso.attributes.origem.includes(LoginOrigemEnum.MJ, 0);
    }

    get ssoOrigem() {
        return this.sso.attributes.origem[0];
    }

    get isOrigemGovBr(): boolean {
        return ([PessoaOrigemEnum.GOV_BR_CNPJ, PessoaOrigemEnum.GOV_BR_CPF]).includes(this.origem);
    }

    get isOrigemExtrangeiro(): boolean {
        return [PessoaOrigemEnum.ESTRANGEIRO].includes(this.origem);
    }

    setOrigem(): void {
        if (!this.sso) {
            return;
        }

        let ssoOrigem: string = this.sso.attributes.origem[0];
        switch (ssoOrigem) {
            case LoginOrigemEnum.GOV_BR:
                this.origem = this._defineWhichUserGovBR();
                break;
            case LoginOrigemEnum.MJ:
                this.origem = PessoaOrigemEnum.MJ;
                break;
            default:
                this.origem = PessoaOrigemEnum.ESTRANGEIRO;
                break;
        }
    }

    private _defineWhichUserGovBR() {
        if (this.cnpj !== "") {
            return PessoaOrigemEnum.GOV_BR_CNPJ;
        }
        return PessoaOrigemEnum.GOV_BR_CPF;
    }

}
