import { Observable } from 'rxjs';
import { ModeloTextoCustomizado } from './../../shared-models/model/modelo-texto-customizado.model';
import { BaseResourceService } from './base-resource.service';
import { Injectable, Injector } from '@angular/core';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';

@Injectable({
    providedIn: 'root'
})
export class EditorCustomTemplateResourceService extends BaseResourceService<ModeloTextoCustomizado> {

    constructor(protected injector: Injector) {
        super('/modelos-texto-customizado', injector, ModeloTextoCustomizado.fromJson);
    }

    getModeloTextoCustomizadoByTipoModelo(tipoModelo: number): Observable<any> {
        return this.http.get<MensagemRetorno>(`${this.urlResource}/tipo-modelo/${tipoModelo}`);
    }
}
