import { Injectable, Injector } from '@angular/core';
import { Historico } from '../../shared-models/model/historico.model';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class HistoricoResourceService extends BaseResourceService<Historico> {
    
    constructor(protected injector: Injector) {
        super('/historico-processos', injector, Historico.fromJson);
    }

    getUltimoHistoricoBySolicitacaoClassificacaoID(idSolicitacao: string) {
        return this.http.get<MensagemRetorno>(`${this.urlResource}/requerente/${idSolicitacao}`);
    }
}
