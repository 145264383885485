import { SegmentoMercado } from './segmento-mercado.model';
import { BaseResourceModel } from './base-resource.model';

export class ModuloAnaliseSegmentoMercado extends BaseResourceModel {
    
    constructor(
        public id?: number,
        public moduloAnalise?: any,
        public segmentoMercado?: SegmentoMercado,
    ) {
        super(id);
    }

    static fromJson(jsonData: any): ModuloAnaliseSegmentoMercado {
        return Object.assign(new ModuloAnaliseSegmentoMercado(), jsonData);
    }
    
}