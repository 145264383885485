export const CONST_ROUTER_UTIL = {
    ROTA_RAIZ: '/',
    PRIVATE_ROTA: 'private',
    LOGIN_ROTA: 'login',
    DASHBOARD_ROTA: 'dashboard',
    TERMO_ROTA: 'termo',
    ADMINISTRACAO: 'admin',
    TRIAGEM: 'triagem',
    PUBLICACAO: 'publicacao',
    RELATORIO: 'relatorio',
    PROCESSO: 'processo',
    ANALISE: 'analise',
    USUARIO: 'usuario',
    CADASTRO_ACESSO: 'cadastro-acesso',
    EXTRACAO_DADOS: 'extracao-dados',
    AUDITORIA: 'auditoria',
    AVISO_CADASTRO_CPF: 'aviso-cadastro-cpf',
    AVISO_CADASTRO_ESTRANGEIRO: 'aviso-cadastro-estrangeiro'
};
