import { BaseResourceModel } from "./base-resource.model";

export class TermoAceite extends BaseResourceModel {
    constructor(
        public idPessoa?: number,
        public descricao?: string,
        public situacao?: string
    ) {
        super();
    }

    static fromJson(jsonData: any): TermoAceite {
        return Object.assign(new TermoAceite(), jsonData);
    }

}
