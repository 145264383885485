import { StatusSolicitacaoClassificacaoEnum } from "src/app/shared/shared-models/enums/status-solicitacao-classificacao.enum";

export enum GerenciadorProcessoItemButtonTypeEnum {
    BUTTON,
    MENU,
}

export class GerenciadorProcessoListPermissoes {
    editar: string;
    visualizar: string;
}

export class GerenciadorProcessoListData {
    title: string;
    statusId: StatusSolicitacaoClassificacaoEnum;
    permissoes: GerenciadorProcessoListPermissoes;
    itemButtonType: GerenciadorProcessoItemButtonTypeEnum;
    hideIfEmpty: boolean = false;
    isOrigemLegado?: boolean;
}
