import { Component, HostListener, Input } from '@angular/core';
import { ValidatorMessageValues } from '../../constants/validator-message-values';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { FieldValidatorService } from '../field-validator.service';

@Component({
    selector: 'app-input-email',
    templateUrl: './input-email.component.html',
    styleUrls: ['../abstract-input/abstract-input.component.scss']
})
export class InputEmailComponent extends AbstractInputComponent {

    @Input() maxlength: number;
    @Input() blockPaste: boolean = false;
    
    validatorMessageValues: {
        validator: {
            required: any;
            email: any;
        };
    };

    constructor(public fieldValidator: FieldValidatorService) {
        super();
        this.validatorMessageValues = ValidatorMessageValues;
    }

    @HostListener('paste', ['$event']) doBlockPaste(e: KeyboardEvent) {
        if(this.blockPaste) {
            e.preventDefault();
        }        
    }

}
