<h2 class="title" mat-dialog-title [innerHTML]="'TRADUCAO'|translate:titulo"></h2>

<div mat-dialog-content>
    <div class="mensagem" [innerHTML]="'TRADUCAO'|translate:mensagem"></div>
</div>

<div mat-dialog-actions>
    <button class="full-width mt-3" mat-raised-button color="primary" (click)="onConfirm()">
        {{'TRADUCAO'|translate:btnTitulo}}
    </button>
</div>
