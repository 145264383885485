import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-abstract-input',
    template: '',
    styleUrls: []
})
export class AbstractInputComponent {

    @Input() title: string;
    @Input() placeholder: string;
    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() readOnly: boolean;

    @Output() onChange = new EventEmitter<any>();

    get formControl(): AbstractControl {
        return this.formGroup.controls[this.controlName];
    }

    get isRequired() {
        return this.formControl.hasError('required');
    }

    sendChange($event: any) {
        this.onChange.emit($event.target.value);
    }

}
