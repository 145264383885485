import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { GenericResourceService } from '../generic-resource.service';
import { MensagemRetorno } from 'src/app/shared/shared-models/model/mensagem-retorno';
import { CorretorRegistrosDTO } from 'src/app/shared/shared-models/dto/corretor-registros/corretor-registros.dto';
import { JustificativaCorretorRegistro } from 'src/app/shared/shared-models/model/corretor-registro/justificativa-corretor-registro.model';

const JUSTIFICATIVA_PATH = "justificativas"
@Injectable({
    providedIn: 'root'
})


export class CorretorRegistrosResource extends GenericResourceService {

    constructor(protected injector: Injector) {
        super('/corretor-registros', injector);
    }

    getByIdSolicitacao(idSolicitacao: number): Observable<CorretorRegistrosDTO> {
        return this.http.get<CorretorRegistrosDTO>(`${this.urlResource}/${idSolicitacao}`);
    }

    update(dto: any): Observable<MensagemRetorno> {
        return this.http.put<MensagemRetorno>(`${this.urlResource}`, dto);
    }

    getAllJustificativasBySolicitacao(idSolicitacao: number): Observable<JustificativaCorretorRegistro> {
        return this.http.get<JustificativaCorretorRegistro>(`${this.urlResource}/${JUSTIFICATIVA_PATH}/${idSolicitacao}`);
    }
}
