import { Inject, Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RecursoResourceService } from 'src/app/shared/shared-services/resources/recurso-resource.service';
import { PROCESSO_ROUTE } from 'src/app/views/private/processo/processo-routes.values';
import { AnexoProcessoDTO } from '../../shared-models/dto/anexo-processo-dto';
import { AnexoSolicitacaoClassificacaoDTO } from '../../shared-models/dto/anexo-solicitacao-classificacao-dto';
import { SolicitacaoClassificacaoPaginacaoDTO } from '../../shared-models/dto/processo-paginacao/solicitacao-classificacao-paginacao-dto';
import { PaginacaoDTO } from '../../shared-models/dto/paginacao-dto';
import { JustificativaCorretorRegistro } from '../../shared-models/model/corretor-registro/justificativa-corretor-registro.model';
import { InformacaoSistema } from '../../shared-models/model/informacao-sistema';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';
import { Pessoa } from '../../shared-models/model/pessoa.model';
import { AnexoProcessoResourceService } from '../resources/anexo-processo-resource.service';
import { AnexoSolicitacaoClassificacaoResourceService } from '../resources/anexo-solicitacao-classificacao-resource.service';
import { AtributoSolicitacaoClassificacaoResourceService } from '../resources/atributo-solicitacao-classificacao-resource.service';
import { CategoriaResourceService } from '../resources/categoria-resource.service';
import { ClassificacaoResourceService } from '../resources/classificacao-resource.service';
import { CorretorRegistrosResource } from '../resources/corretor-registros/corretor-registros-resource.service';
import { DescritorConteudoResourceService } from '../resources/descritor-conteudo-resource.service';
import { DiaSemanaResourceService } from '../resources/dia-semana-resource.service';
import { ElementoInterativoResource } from '../resources/elemento-interativo-resource.service';
import { EmissoraResourceService } from '../resources/emissora-resource.service';
import { GeneroResourceService } from '../resources/genero-resource.service';
import { HistoricoClassificacaoResourceService } from '../resources/historico-classificacao-resource.service';
import { HistoricoResourceService } from '../resources/historico-resource.service';
import { HistoricoSolicitacaoClassificacaoOcultaResource } from '../resources/historico-solicitacao-classificacao-oculta-resource.service';
import { ModeloPortariaResourceService } from '../resources/modelo-portaria-resource.service';
import { ModuloAnaliseSegmentoMercadoResourceService } from '../resources/modulo-analise-resource.service';
import { NaturezaAnaliseResourceService } from '../resources/natureza-analise-resource.service';
import { PaisOrigemResourceService } from '../resources/pais-origem-resource.service';
import { PeriodicidadeResourceService } from '../resources/periodicidade-resource.service';
import { PlataformaResourceService } from '../resources/plataforma-resource.service';
import { PracaExibicaoResourceService } from '../resources/praca-exibicao-resource.service';
import { SegmentoMercadoResourceService } from '../resources/segmento-mercado-resource.service';
import { SolicitacaoClassificacaoResourceService } from '../resources/solicitacao-classificacao-resource.service';
import { StatusSolicitacaoClassificacaoResourceService } from '../resources/status-solicitacao-classificacao-resource.service';
import { TipoAnuncioResourceService } from '../resources/tipo-anuncio-resource.service';
import { TipoClassificacaoResourceService } from '../resources/tipo-classificacao-resource.service';
import { TipoDocumentoResourceService } from '../resources/tipo-documento-resource.service';
import { TipoMidiaResourceService } from '../resources/tipo-midia-resource.service';
import { SortOptionEnum } from './../../shared-models/enums/sort-option.enum';
import { TipoBuscaSolicitacaoClassificacaoEnum } from './../../shared-models/enums/tipo-busca-solicitacao-classificacao.enum';
import { GeneroProgramaResourceService } from './../resources/genero-programa-resource.service';
import { HistoricoDescritorConteudoResourceService } from './../resources/historico-descritor-conteudo-resource.service';
import { RequerimentoReconsideracaoResourceService } from './../resources/requerimento-reconsideracao-resource.service';
import { SolicitacaoClassificacaoAnalisePaginacaoDTO } from '../../shared-models/dto/processo-paginacao/solicitacao-classificacao-analise-paginacao-dto';
import { SolicitacaoClassificacaoTriagemPaginacaoDTO } from '../../shared-models/dto/processo-paginacao/solicitacao-classificacao-triagem-paginacao-dto';

@Injectable({ providedIn: 'root' })
export class ApoioFacadeService {

    private readonly _PAGE_SIZE_DEFAULT = 10;

    private _informacoes: InformacaoSistema;

    constructor(
        @Inject('InformacaoSistema') sistema: BehaviorSubject<InformacaoSistema>,
        private router: Router,
        private solicitacaoClassificacaoResource: SolicitacaoClassificacaoResourceService,
        private categoriaResourceService: CategoriaResourceService,
        private segmentoMercadoResourceService: SegmentoMercadoResourceService,
        private tipoMidiaResourceService: TipoMidiaResourceService,
        private classificacaoResourceService: ClassificacaoResourceService,
        private naturezaAnaliseResourceService: NaturezaAnaliseResourceService,
        private paisOrigemResourceService: PaisOrigemResourceService,
        private emissoraResourceService: EmissoraResourceService,
        private pracaExibicaoResourceService: PracaExibicaoResourceService,
        private periodicidadeResourceService: PeriodicidadeResourceService,
        private statusSolicitacaoClassificacaoResourceService: StatusSolicitacaoClassificacaoResourceService,
        private plataformaResourceService: PlataformaResourceService,
        private tipoAnuncioResourceService: TipoAnuncioResourceService,
        private tipoDocumentoResourceService: TipoDocumentoResourceService,
        private historicoResourceService: HistoricoResourceService,
        private tipoClassificacaoResourceService: TipoClassificacaoResourceService,
        private generoResourceService: GeneroResourceService,
        private descritorConteudoResourceService: DescritorConteudoResourceService,
        private atributoSolicitacaoClassificacaoResourceService: AtributoSolicitacaoClassificacaoResourceService,
        private modeloPortariaResourceService: ModeloPortariaResourceService,
        private anexoProcessoResource: AnexoProcessoResourceService,
        private anexoSolicitacaoClassificacaoResource: AnexoSolicitacaoClassificacaoResourceService,
        private requerimentoReconsideracaoResource: RequerimentoReconsideracaoResourceService,
        private diaSemanaResourceService: DiaSemanaResourceService,
        private historicoClassificacaoResource: HistoricoClassificacaoResourceService,
        private historicoDescritorConteudoResource: HistoricoDescritorConteudoResourceService,
        private recursoResource: RecursoResourceService,
        private generoProgramaResource: GeneroProgramaResourceService,
        private corretorRegistrosResource: CorretorRegistrosResource,
        private historicoSolicitacaoClassificacaoOcultaResource: HistoricoSolicitacaoClassificacaoOcultaResource,
        private elementoInterativoResource: ElementoInterativoResource,
        private moduloAnaliseSegmentoMercadoResource: ModuloAnaliseSegmentoMercadoResourceService,
    ) {
        sistema.subscribe((infos) => {
            this._informacoes = infos;
        });
    }

    getProcessos(dto: PaginacaoDTO): Observable<MensagemRetorno> {
        return this.solicitacaoClassificacaoResource.getAllByBody(dto);
    }

    getProcessosPorStatus(dto: SolicitacaoClassificacaoPaginacaoDTO) {
        return this.solicitacaoClassificacaoResource.getAllByStatus({
            currentPage: dto.currentPage,
            pageSize: dto.pageSize,
            ordenacao: dto.ordenacao,
            idSegmento: dto.idSegmento,
            protocolo: dto.protocolo,
            tituloBrasil: dto.tituloBrasil,
            tituloOriginal: dto.tituloOriginal,
            idStatus: dto.idStatus,
            isOrigemLegado: dto.isOrigemLegado,
        } as SolicitacaoClassificacaoTriagemPaginacaoDTO);
    }

    getSolicitacaoClassificacao(id) {
        return this.solicitacaoClassificacaoResource.getById(id);
    }

    getSolicitacaoClassificacaoArquivadaByRevisao(idSolicitacaoRevisao: number) {
        return this.solicitacaoClassificacaoResource.getArquivadaByRevisao(idSolicitacaoRevisao);
    }

    getSolicitacaoClassificacaoNormalizado(id): Observable<MensagemRetorno> {
        return this.solicitacaoClassificacaoResource.getNormalizadoById(id);
    }

    getCategorias(): Observable<any> {
        return this.categoriaResourceService.getAll(this.montarPaginacaoDTO());
    }

    getCategoriasByIdSegmentoMercado(segmentoMercadoId: number): Observable<any> {
        return this.categoriaResourceService.getByIdSegmentoMercado(segmentoMercadoId);
    }

    getGeneros(): Observable<any> {
        return this.generoResourceService.getAll(this.montarPaginacaoDTO());
    }

    getTipoMidias(): Observable<MensagemRetorno> {
        return this.tipoMidiaResourceService.getAll(this.montarPaginacaoDTO());
    }

    getClassificacoes(): Observable<MensagemRetorno> {
        return this.classificacaoResourceService.getAll(this.montarPaginacaoDTO());
    }

    getNaturezaAnalises(): Observable<any> {
        return this.naturezaAnaliseResourceService.getAll(this.montarPaginacaoDTO());
    }

    getSegmentosMercado(): Observable<any> {
        return this.segmentoMercadoResourceService.getAll(this.montarPaginacaoDTO());
    }

    getTipoClassificacoes(): Observable<any> {
        return this.tipoClassificacaoResourceService.getAll(this.montarPaginacaoDTO());
    }

    getPaisesOrigem(): Observable<any> {
        return this.paisOrigemResourceService.getAll(this.montarPaginacaoDTO());
    }

    getEmissoras(): Observable<any> {
        return this.emissoraResourceService.getAll(this.montarPaginacaoDTO());
    }

    getPracasExibicao(): Observable<any> {
        return this.pracaExibicaoResourceService.getAll(this.montarPaginacaoDTO());
    }

    getTiposDocumento(filterByTipoVisualizacao: boolean): Observable<any> {
        let paginacaoDTO = this.montarPaginacaoDTO();
        paginacaoDTO.filterByTipoVisualizacao = filterByTipoVisualizacao;
        return this.tipoDocumentoResourceService.getAllByBody(paginacaoDTO);
    }

    getAllTipoDocumentoByIds(ids: number[], filterByTipoVisualizacao: boolean): Observable<any> {
        let paginacaoDTO = this.montarPaginacaoDTO();
        paginacaoDTO.filterByTipoVisualizacao = filterByTipoVisualizacao;
        paginacaoDTO.ids = ids;
        return this.tipoDocumentoResourceService.getAllByBody(paginacaoDTO);
    }

    getStatusSolicitacaoClassificacao(): Observable<any> {
        return this.statusSolicitacaoClassificacaoResourceService.getAll(this.montarPaginacaoDTO());
    }

    getPeriodicidades(): Observable<any> {
        return this.periodicidadeResourceService.getAll(this.montarPaginacaoDTO());
    }

    getAtributosSolicitacaoClassificacao(): Observable<any> {
        return this.atributoSolicitacaoClassificacaoResourceService.getAll();
    }

    getUsuarioAtual(): Observable<Pessoa> {
        return of(this._informacoes['pessoa']);
    }

    getPlataformas(): Observable<MensagemRetorno> {
        return this.plataformaResourceService.getAll(this.montarPaginacaoDTO());
    }

    getPlataformasByIdSegmentoMercado(segmentoMercadoId: number): Observable<any> {
        return this.plataformaResourceService.getByIdSegmentoMercado(segmentoMercadoId);
    }

    getTiposAnuncios(): Observable<any> {
        return this.tipoAnuncioResourceService.getAll(this.montarPaginacaoDTO());
    }

    getDescritorConteudo(): Observable<MensagemRetorno> {
        return this.descritorConteudoResourceService.getAll(this.montarPaginacaoDTO());
    }

    getUltimoHistoricoBySolicitacaoClassificacaoID(id: string) {
        return this.historicoResourceService.getUltimoHistoricoBySolicitacaoClassificacaoID(id);
    }

    getSegmentosMercadoByModuloID(idModulo: number): Observable<any> {
        return this.moduloAnaliseSegmentoMercadoResource.getAllSegmentosMercadoByModulo(idModulo);
    }

    buscarProcessosPorModulo(dto: SolicitacaoClassificacaoAnalisePaginacaoDTO): Observable<any> {
        return this.solicitacaoClassificacaoResource.getAllByModuloAnalise(dto);
    }

    getHistoricosBySolicitacaoClassificacaoID(id: number) {
        return this.historicoResourceService.getById(id);
    }

    getAllCorretorRegistroJustificativasBySolicitacaoClassificacaoID(id: number): Observable<JustificativaCorretorRegistro> {
        return this.corretorRegistrosResource.getAllJustificativasBySolicitacao(id);
    }

    getAllHistoricoClassificacaoBySolicitacaoClassificacao(idSolicitacaoClassificacao: number) {
        return this.historicoClassificacaoResource.getAllBySolicitacaoClassificacao(idSolicitacaoClassificacao);
    }

    getAllHistoricoDescritorConteudoBySolicitacaoClassificacao(idSolicitacaoClassificacao: number) {
        return this.historicoDescritorConteudoResource.getAllBySolicitacaoClassificacao(idSolicitacaoClassificacao);
    }

    getModelosPortaria() {
        return this.modeloPortariaResourceService.getAll(this.montarPaginacaoDTO());
    }

    getModelosPortariaBySegmento(id: number): Observable<MensagemRetorno> {
        return this.modeloPortariaResourceService.getModelosPortariaBySegmento(id);
    }

    goToSolicitacaoForm(id: number) {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.SOLICITACAO_FORM}/${id}`], { skipLocationChange: true });
    }

    goToSolicitacaoView(id: number) {
        this.router.navigate([`/processo/${PROCESSO_ROUTE.SOLICITACAO_VIEW}/${id}`], { skipLocationChange: true });
    }

    goToTriagemAvaliacao(id: number) {
        this.router.navigate([`/triagem/triagem-avaliacao/${id}`], { skipLocationChange: true });
    }

    goToHome() {
        this.router.navigate([`/`]);
    }

    montarPaginacaoDTO(): any {
        return {
            nome: '',
            descricao: '',
            situacao: 'A',
            currentPage: 0,
            pageSize: 1000,
            sortItem: null,
            totalResults: null,
            itens: null
        };
    }

    montarDadosProcessoPaginacaoDTO(paginator: MatPaginator, tipoBusca?: TipoBuscaSolicitacaoClassificacaoEnum) {
        return {
            currentPage: 0,
            pageSize: this._extractPageSize(paginator),
            sortItem: null,
            ordenacao: SortOptionEnum.ASC,
            totalResults: null,
            itens: null,
            cnpj: this._informacoes.cnpj || null,
            idSegmento: null,
            idStatus: null,
            protocolo: '',
            tituloBrasil: '',
            tituloOriginal: '',
            tipoBusca: tipoBusca || TipoBuscaSolicitacaoClassificacaoEnum.NORMAL,
            dataDouInicial: '',
            dataDouFinal: '',
            tituloObraSerie: ''
        } as SolicitacaoClassificacaoPaginacaoDTO;
    }

    private _extractPageSize(paginator: MatPaginator): number {
        try {
            return paginator.pageSize || this._PAGE_SIZE_DEFAULT;
        } catch (error) {
            return this._PAGE_SIZE_DEFAULT;
        }
    }

    /* Anexos */

    getAllAnexosOfSolicitacaoClassificacao(solicitacaoClassificacaoId: number) {
        return this.anexoProcessoResource.getAllBySolicitacaoClassificacao(solicitacaoClassificacaoId);
    }

    downloadAnexo(anexo: AnexoProcessoDTO) {
        return this.anexoProcessoResource.getArquivo(anexo);
    }

    /* AnexoSolicitacaoClassificacao */

    createAnexoSolicitacaoClassificacao(anexo): Observable<MensagemRetorno> {
        return this.anexoSolicitacaoClassificacaoResource.create(anexo);
    }

    downloadAnexoSolicitacaoClassificacao(elemento: AnexoSolicitacaoClassificacaoDTO) {
        return this.anexoSolicitacaoClassificacaoResource.getArquivoById(elemento.id);
    }

    deleteAnexoSolicitacaoClassificacao(id: number): Observable<MensagemRetorno> {
        return this.anexoSolicitacaoClassificacaoResource.delete(id);
    }

    getAllAnexoSolicitacaoClassificacao(idSolicitacaoClassificacao: number): Observable<any> {
        return this.anexoSolicitacaoClassificacaoResource.getAllBySolicitacaoClassificacaoId(this.montarPaginacaoDTO(), idSolicitacaoClassificacao);
    }

    getRequerimentoReconsideracao(idSolicitacaoClassificacao: number): Observable<any> {
        return this.requerimentoReconsideracaoResource.getBySolicitacaoId(idSolicitacaoClassificacao);
    }

    getRecurso(idSolicitacaoClassificacao: number) {
        return this.recursoResource.getBySolicitacaoClassificacao(idSolicitacaoClassificacao);
    }

    getDiasSemana(): Observable<MensagemRetorno> {
        return this.diaSemanaResourceService.getAll(this.montarPaginacaoDTO());
    }

    getGenerosPrograma() {
        return this.generoProgramaResource.getAllByBody(this.montarPaginacaoDTO());
    }

    getAllHistoricoOcultaBySolicitacaoClassificacao(idSolicitacao: number) {
        return this.historicoSolicitacaoClassificacaoOcultaResource.getAllBySolicitacaoClassificacao(idSolicitacao);
    }

    getAllElementosInterativos() {
        return this.elementoInterativoResource.getAllByBody(this.montarPaginacaoDTO());
    }

}
