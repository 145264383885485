import { Injectable, Injector } from '@angular/core';
import { Categoria } from '../../shared-models/model/categoria.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class CategoriaResourceService extends BaseResourceService<Categoria> {

    constructor(protected injector: Injector) {
        super('/categorias', injector, Categoria.fromJson);
    }
}
