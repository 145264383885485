import { Injectable, Injector } from '@angular/core';
import { DescritorConteudo } from '../../shared-models/model/descritor-conteudo.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class DescritorConteudoResourceService extends BaseResourceService<DescritorConteudo> {

    constructor(protected injector: Injector) {
        super('/descritores-conteudo', injector, DescritorConteudo.fromJson);
    }
}
