import { BaseResourceModel } from './base-resource.model';

export class TipoAnuncio extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(jsonData: any): TipoAnuncio {
        return Object.assign(new TipoAnuncio(), jsonData);
    }
}
