import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BaseService {
    protected urlBase = environment.apiUrl;
    protected path: string;
    protected options = '/options';

    constructor(protected http: HttpClient) {
    }
}
