import { GerenciadorProcessoItemButtonTypeEnum, GerenciadorProcessoListData } from 'src/app/shared/shared-components/gerenciador-processo/model/gerenciador-processo-list-data.model';
import { ModuloAnaliseEnum } from 'src/app/shared/shared-models/enums/modulo-analise.enum';
import { StatusSolicitacaoClassificacaoEnum } from "src/app/shared/shared-models/enums/status-solicitacao-classificacao.enum";
import { ROLES } from "src/app/shared/shared-utils/consts/const-roles";

export const RadioValues = {

    moduloAnaliseId: ModuloAnaliseEnum.RADIO,

    header: {
        title: 'Rádio'
    },

    lists: [
        {
            title: 'Aguardando análise',
            statusId: StatusSolicitacaoClassificacaoEnum.AGUARDANDO_ANALISE,
            permissoes: {
                editar: ROLES.AREAS_TECNICAS_RADIO_EDITAR,
                visualizar: ROLES.AREAS_TECNICAS_RADIO_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
        } as GerenciadorProcessoListData,

        {
            title: 'Aguardando reconsideração',
            statusId: StatusSolicitacaoClassificacaoEnum.EM_RECONSIDERACAO,
            permissoes: {
                editar: ROLES.REQUERIMENTO_RECONSIDERACAO_ANALISAR,
                visualizar: ROLES.REQUERIMENTO_RECONSIDERACAO_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
        } as GerenciadorProcessoListData,

        {
            title: 'Aguardando recurso',
            statusId: StatusSolicitacaoClassificacaoEnum.AGUARDANDO_RECURSO,
            permissoes: {
                editar: ROLES.REQUERIMENTO_RECURSO_ANALISAR,
                visualizar: ROLES.REQUERIMENTO_RECURSO_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
        } as GerenciadorProcessoListData,

        {
            title: 'Revisão de classificação',
            statusId: StatusSolicitacaoClassificacaoEnum.REVISAO_CLASSIFICACAO,
            permissoes: {
                editar: ROLES.REVISAO_CLASSIFICACAO_ANALISAR,
                visualizar: ROLES.REVISAO_CLASSIFICACAO_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.BUTTON,
        } as GerenciadorProcessoListData,
    ],

    btn: {
        action: {
            label: 'Ir para análise por unidade',
            tooltip: 'Analisar processo',
            icon: 'grading'
        }
    }

};
