import { LoginOrigemEnum } from './../shared-models/enums/login-origem.enum';
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { take, switchMap, map } from 'rxjs/operators';
import { InformacaoSistema } from "../shared-models/model/informacao-sistema";
import { EmpresasVinculadasService } from "./empresas-vinculadas-service";
import { MensageriaService } from "./mensageria-service";
import { version } from 'package.json';
import { HttpErrorResponse } from "@angular/common/http";
import { AuthHelper } from "../shared-utils/auth-helper";
import { CadastroAcessoService } from "src/app/views/private/dashboard/cadastro-acesso/cadastro-acesso.service";
import { SpinnerService } from "./utilitarios/spinner-vinheta/spinner.service";
import { PendenciasUsuario } from "./pendencias-usuario.service";
import { UserNotification } from "../shared-models/model/notification.model";
import { ROLES } from "../shared-utils/consts/const-roles";

const cpnjSelecionado = 'cnpjSelecionado';
@Injectable({
    providedIn: 'root'
})
export class InformacaoSistemaService {

    private _user: any;

    constructor(
        @Inject('InformacaoSistema') private sistema: BehaviorSubject<InformacaoSistema>,
        private empresasVinculadasService: EmpresasVinculadasService,
        private mensageriaService: MensageriaService,
        private authHelper: AuthHelper,
        private cadastroAcessoService: CadastroAcessoService,
        private pendenciasUsuario: PendenciasUsuario,
    ) { }

    private _logout() {
        setTimeout(() => {
            this.authHelper.logout();
        }, 2500);
    }

    public configurarInformacoesSistema(user: any) {
        this._user = user;
        this._checkAttributesForEstrangeiro();

        if (this._isOrigemGovBr) {
            this._configurarUsuarioGovBr();
        } else {
            this._addInformacaoSistemaPessoa();
        }
    }

    private _checkAttributesForEstrangeiro() {
        if (!this._user.attributes.origem) {
            this._user.attributes.origem = [ LoginOrigemEnum.ESTRANGEIRO ];
            this._user.attributes.cpf = [ '' ];
        }
    }

    private get _isOrigemGovBr(): boolean {
        return this._user.attributes.origem[0] === LoginOrigemEnum.GOV_BR;
    }

    private _configurarUsuarioGovBr() {
        this.empresasVinculadasService.getCnpjs()
            .pipe(take(1))
            .subscribe(
                empresas => {
                    let cpnjSelecionadoLocal = empresas.cnpjs.find(cnpjs => cnpjs.cnpj === localStorage.getItem(cpnjSelecionado));
                    if (cpnjSelecionadoLocal) {
                        this._addInformacaoSistema(cpnjSelecionadoLocal);
                    } else {
                        this._selecionarEmpresa(empresas.cnpjs);
                    }
                },
                (errorResponse: HttpErrorResponse) => {
                    this.mensageriaService.handleApiError(errorResponse);
                    this._logout();
                });
    }

    private _selecionarEmpresa(cnpjs: any) {
        if (cnpjs.length > 1) {
            this.empresasVinculadasService.openDialogEmpresasVinculadas(cnpjs).subscribe(cnpj => {
                this._addInformacaoSistema(cnpj);
            });
        } else {
            this._addInformacaoSistema(cnpjs[0]);
        }
    }

    private _addInformacaoSistemaPessoaJuridica(cnpj: any) {
        localStorage.setItem(cpnjSelecionado, cnpj.cnpj);
        this.cadastroAcessoService.getPessoaByNrDocumento(cnpj.cnpj)
            .pipe(switchMap(pessoa => {
                if (this.authHelper.hasPermissao(ROLES.NOTIFICACAO_PENDECIAS_VISUALIZAR)) {
                    return this.pendenciasUsuario.getPendenciasUsuario("")
                        .pipe(map(pendencias => {
                            return this._buildInformacaoSistema(pessoa, cnpj.nome, cnpj.cnpj, pendencias);
                        }));
                }
                return of(this._buildInformacaoSistema(pessoa, cnpj.nome, cnpj.cnpj, null));
            }))
            .subscribe(
                informacaoSistema => {
                    informacaoSistema.setOrigem();
                    this.sistema.next(informacaoSistema);
                },
                (error: HttpErrorResponse) => {
                    this.mensageriaService.handleApiError(error);
                    this._logout();
                }
            );
    }

    private _addInformacaoSistemaPessoa() {
        this.cadastroAcessoService.getPessoaByLoggedUserKeycloakId()
            .pipe(switchMap(pessoa => {
                let nome = this._user.firstName + ' ' + this._user.lastName;
                if (this.authHelper.hasPermissao(ROLES.NOTIFICACAO_PENDECIAS_VISUALIZAR)) {
                    return this.pendenciasUsuario.getPendenciasUsuario("")
                        .pipe(map(pendencias => {
                            return this._buildInformacaoSistema(pessoa, nome, '', pendencias);
                        }));
                } 
                return of(this._buildInformacaoSistema(pessoa, nome, '', null));
            }))
            .subscribe(
                informacaoSistema => {
                    informacaoSistema.setOrigem();
                    this.sistema.next(informacaoSistema);
                },
                (error: HttpErrorResponse) => {
                    this.mensageriaService.handleApiError(error);
                    this._logout();
                }
            );
    }

    private _buildInformacaoSistema(pessoa: any, nome: string, cnpj: any, notificacoes: UserNotification) {
        let info = new InformacaoSistema();
        info.nome = nome;
        info.sso = this._user;
        info.cpf = (this._user.attributes.cpf ? this._user.attributes.cpf[0] : '');
        info.cnpj = cnpj;
        info.sistema = 'CLASSIND';
        info.sistemaDescricao = 'Sistema de Classificação Indicativa';
        info.versaoFrontend = version;
        info.versaoBackend = '';
        info.pessoa = pessoa;
        info.notificacoes = notificacoes;
        return info;
    }

    private _addInformacaoSistema(cnpj?: any) {
        if (!this._user) {
            return;
        }

        if (cnpj) {
            this._addInformacaoSistemaPessoaJuridica(cnpj);
        } else {
            this._addInformacaoSistemaPessoa();
        }
    }

    public aguardaInformacaoSistema(spinnerService: SpinnerService): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.sistema.subscribe(res => {
                if (Object.values(res).length > 0) {
                    spinnerService.endSpinnerVinheta(0);
                    resolve(true);
                }
            });
        });
    }

}
