import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GerenciadorProcessoListData } from 'src/app/shared/shared-components/gerenciador-processo/model/gerenciador-processo-list-data.model';
import { ModuloAnaliseEnum } from 'src/app/shared/shared-models/enums/modulo-analise.enum';
import { SegmentoMercadoEnum } from "src/app/shared/shared-models/enums/segmento-mercado.enum";
import { StatusSolicitacaoClassificacaoEnum } from 'src/app/shared/shared-models/enums/status-solicitacao-classificacao.enum';
import { Processo } from "src/app/shared/shared-models/model/processo.model";
import { MensageriaService } from 'src/app/shared/shared-services/mensageria-service';
import { ModuloAnaliseSegmentoMercadoResourceService } from 'src/app/shared/shared-services/resources/modulo-analise-resource.service';
import { SpinnerVinhetaKeys } from "src/app/shared/shared-services/utilitarios/spinner-vinheta/spinner-vinheta-keys";
import { SpinnerService } from 'src/app/shared/shared-services/utilitarios/spinner-vinheta/spinner.service';
import { CONST_ROUTER_UTIL } from "src/app/shared/shared-utils/consts/const-router-util";
import { ProcessoService } from "../processo/processo.service";
import { AnaliseValues } from "./analise-values";
import { CinemaHomeVideoValues } from "./cinema-home-video/cinema-home-video-values";
import { JogosEletronicosRPGValues } from "./jogos-eletronicos-rpg/jogos-eletronicos-rpg-values";
import { RadioValues } from "./radio/radio-values";
import { SeacVodValues } from "./seac-vod/seac-vod-values";
import { TvAbertaValues } from "./tv-aberta/tv-aberta-values";

class ModuloAnaliseRouteInfo {
    path: string;
    lists: GerenciadorProcessoListData[];
}

@Injectable({
    providedIn: 'root'
})
export class AnaliseService {

    private _values = AnaliseValues;

    constructor(
        private router: Router,
        private processoService: ProcessoService,
        private moduloAnaliseSegmentoMercadoResource: ModuloAnaliseSegmentoMercadoResourceService,
        private spinnerService: SpinnerService,
        private mensageriaService: MensageriaService,
    ) { }

    public goToProcesso(processo: Processo, moduloAnalise: string) {
        switch(processo.status) {
            case StatusSolicitacaoClassificacaoEnum.EM_RECONSIDERACAO:
                this.processoService.goToRequerimentoReconsideracao(processo.id);
                break;

            case StatusSolicitacaoClassificacaoEnum.AGUARDANDO_RECURSO:
                this.processoService.goToRecurso(processo.id);
                break;

            default:
                this._goToAtribuirClassificacao(processo.id, moduloAnalise);
        }
    }

    private _goToAtribuirClassificacao(idSolicitacao: number, moduloAnalise: string) {
        this.router.navigate([`/analise/atribuir-classificacao/${idSolicitacao}`], { 
            skipLocationChange: true, 
            queryParams: { modulo: moduloAnalise } 
        });
    }


    goToAnaliseTecnica(idSegmentoMercado: number, statusSolicitacao: StatusSolicitacaoClassificacaoEnum) {
        this.spinnerService.startSpinnerVinheta(SpinnerVinhetaKeys.ACESSO_DADOS);

        this.moduloAnaliseSegmentoMercadoResource.getModuloAnaliseIdBySegmento(idSegmentoMercado)
            .subscribe(
                idModuloAnalise => {
                    this.spinnerService.endSpinnerVinheta(0);
                    if (!idModuloAnalise) {
                        return this._handleErrorAtGoToAnaliseTecnica(idSegmentoMercado);
                    }
                    this._goToAnaliseTecnicaByModulo(idModuloAnalise, idSegmentoMercado, statusSolicitacao);
                },
                error => {
                    this.spinnerService.endSpinnerVinheta(0);
                    this.mensageriaService.handleApiError(error);
                }
            );
    }

    private _goToAnaliseTecnicaByModulo(idModuloAnalise: number, idSegmentoMercado: number,
        statusSolicitacao: StatusSolicitacaoClassificacaoEnum
    ) {
        let moduloAnaliseRouteInfo = this._getModuloAnaliseRouteInfo(idModuloAnalise);
        if(!moduloAnaliseRouteInfo) {
            return this._handleErrorAtGoToAnaliseTecnica(idSegmentoMercado);
        }

        let listIndex = moduloAnaliseRouteInfo.lists.findIndex(list => list.statusId === statusSolicitacao);
        if(listIndex < 0) {
            return this._handleErrorAtGoToAnaliseTecnica(idSegmentoMercado);
        }

        this.router.navigate([`${CONST_ROUTER_UTIL.ANALISE}/${moduloAnaliseRouteInfo.path}`], { 
            state: { listIndex: listIndex } 
        });
    }

    private _getModuloAnaliseRouteInfo(idModuloAnalise: number): ModuloAnaliseRouteInfo {
        return ({
            [ModuloAnaliseEnum.CINEMA_HOME_VIDEO]: {
                path: this._values.PATH_CINEMA_HOME_VIDEO,
                lists: CinemaHomeVideoValues.lists,
            } as ModuloAnaliseRouteInfo,

            [ModuloAnaliseEnum.JOGO_ELETRONICO_RPG]: {
                path: this._values.PATH_JOGOS_RPG,
                lists: JogosEletronicosRPGValues.lists,
            } as ModuloAnaliseRouteInfo,

            [ModuloAnaliseEnum.SEAC_VOD]: {
                path: this._values.PATH_SEAC_VOD,
                lists: SeacVodValues.lists
            } as ModuloAnaliseRouteInfo,

            [ModuloAnaliseEnum.TV_ABERTA]: {
                path: this._values.PATH_TV_ABERTA,
                lists: TvAbertaValues.lists
            } as ModuloAnaliseRouteInfo,

            [ModuloAnaliseEnum.RADIO]: {
                path: this._values.PATH_RADIO,
                lists: RadioValues.lists
            } as ModuloAnaliseRouteInfo,
        })
        [idModuloAnalise];
    }

    private _handleErrorAtGoToAnaliseTecnica(idSegmentoMercado: SegmentoMercadoEnum) {
        let segmentoMercadoName = SegmentoMercadoEnum[idSegmentoMercado].replace(/_/g, ' ');
        alert(`Segmento ${segmentoMercadoName} sem Área Técnica para redirecionar`);
    }

}
