import { GerenciadorProcessoItemButtonTypeEnum, GerenciadorProcessoListData } from "src/app/shared/shared-components/gerenciador-processo/model/gerenciador-processo-list-data.model";
import { StatusSolicitacaoClassificacaoEnum } from "src/app/shared/shared-models/enums/status-solicitacao-classificacao.enum";
import { ROLES } from "src/app/shared/shared-utils/consts/const-roles";

export const PublicacaoValues = {

    header: {
        title: 'Publicação',
        diario: {
            titulo: {
                principal: 'Publicação do Diário',
                card: 'Dados do Processo',
                numeroProcesso: 'Processo Nº',
                dataCriacao: 'Criado em',
                formulario: 'Informações da publicação'
            }
        }
    },

    lists: [
        {
            title: 'Gerar documento para publicação',
            statusId: StatusSolicitacaoClassificacaoEnum.AGUARDANDO_DOCUMENTO,
            permissoes: {
                editar:     ROLES.PUBLICACAO_GERAR_PORTARIA_EDITAR,
                visualizar: ROLES.PUBLICACAO_GERAR_PORTARIA_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.MENU,
        } as GerenciadorProcessoListData,

        {
            title: 'Aguardando publicação',
            statusId: StatusSolicitacaoClassificacaoEnum.AGUARDANDO_PUBLICACAO,
            permissoes: {
                editar:     ROLES.PUBLICACAO_AGUARDANDO_PUBLICACAO_EDITAR,
                visualizar: ROLES.PUBLICACAO_AGUARDANDO_PUBLICACAO_VISUALIZAR
            },
            itemButtonType: GerenciadorProcessoItemButtonTypeEnum.MENU,
        } as GerenciadorProcessoListData,
    ],

    status: {
        info: {
            semDocs: {
                tooltip: 'Anexo de portaria publicada pendente',
                icon: 'report_problem'
            },
            ok: {
                tooltip: 'Anexo de portaria incluído',
                icon: 'task_alt'
            }
        }
    },

    menu: {
        gerarPortaria: {
            title: { pt: 'Criar portaria' },
            icon: 'note_add'
        },
        publicacao: {
            title: { pt: 'Publicação do diário' },
            icon: 'note_add'
        },
        publicado: {
            title: { pt: 'Publicado' },
            icon: 'note_add'
        }
    },

    btn: {
        action: {
            label: 'Publicação',
            tooltip: 'Publicação',
            icon: 'grading'
        },
        ativarModoEdicao: {
            tooltip: 'Editar documento',
            icon: 'edit'
        },
        gerarPortaria: {
            tooltip: 'Gerar Portaria',
            icon: 'note_add'
        },
        cancelAdd: {
            tooltip: 'Cancelar criação',
            icon: 'clear'
        },
        cancelarEdicao: {
            tooltip: 'Cancelar edição',
            icon: 'clear'
        },

        limparEdicao: {
            tooltip: 'Limpar Alterações',
            icon: 'cleaning_services'
        },

        aplicarMudancas: {
            tooltip: 'aplicar mudanças temporárias',
            icon: 'task_alt'
        },

        addPublicacaoDou: {
            title: 'Criar publicação',
            icon: 'add_circle'
        },
        excluir: {
            title: 'Excluir publicação',
            icon: 'delete'
        },

        finalizePublicacaoDou: {
            title: 'Finalizar publicação',
            icon: 'gavel'
        },

        viewProcessoArquivado: {
            icon: 'info_outline',
            tooltip: 'Visualizar processo arquivado',
        },
    },

    formBtn: {
        save: {
            icon: 'save',
            title: 'salvar'
        },
        clear: {
            icon: 'undo',
            title: 'Recuperar'
        },
        cancel: {
            icon: 'clear',
            title: 'cancelar'
        }
    },

    visualizar: {
        title: 'Visualizar Processo'
    },

    dialogo: {
        finalizacao: {
            titulo: {
                pt: `Publicação de processo`
            },
            mensagem: {
                pt: `Deseja confirmar a finalização de publicação do processo nº`
            },
            btnTitulo: {
                pt: `Sim`,
            }
        },
        advertencia: {
            titulo: {
                pt: 'Advertência'
            },
            mensagem: {
                pt: `Detectamos a ausência do anexo de PORTARIA PUBLICADA em alguma publicação do diário.
            Por favor, volte e revise conteúdo sinalizado`
            },
            btnTitulo: {
                pt: `Revisar`
            }
        },

        publicacaoConcluida: {
            titulo: {
                pt: 'Publicação finalizada'
            },
            mensagem: {
                pt: `<div>Status atualizado com sucesso.
                <em>Será feito o redirecionamento para área de processos.<em></div>`
            },
            btnTitulo: {
                pt: `OK`
            }
        }
    }
};
