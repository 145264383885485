import { Injectable, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RedirectService } from '../services/redirect.service';
import { AuthTokenService } from './auth-token.service';

@Injectable()
export class AuthService {

    constructor(
        private redirectService: RedirectService,
        private tokenService: AuthTokenService,
        protected rendererFactory: RendererFactory2) { }

    logout(): void {
        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        const urlAcessoUnico = `${environment.LOGIN_UNICO_SSO_URL}`;
        const urlLogout = `${environment.LOGOUT_REDIRECT_URL}`;
        const urlAction = urlAcessoUnico.concat("/logout?post_logout_redirect_uri=").concat(urlLogout);
        form.setAttribute("action", urlAction);
        document.body.appendChild(form);
        form.submit();
        this.tokenService.revoke();
    }

    handleAccessToken(): void {
        this.redirectService.goToPrivateArea();
    }
}
