import { Injectable, Injector } from '@angular/core';
import { TipoClassificacao } from '../../shared-models/model/tipo-classificacao.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class TipoClassificacaoResourceService extends BaseResourceService<TipoClassificacao> {

    constructor(protected injector: Injector) {
        super('/tipoClassificacao', injector, TipoClassificacao.fromJson);
    }
}
