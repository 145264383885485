import { Component, Input } from '@angular/core';
import { ValidatorMessageValues } from '../../constants/validator-message-values';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { FieldValidatorService } from '../field-validator.service';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['../abstract-input/abstract-input.component.scss'],
})
export class InputTextComponent extends AbstractInputComponent {
    
    @Input() maxlength: number;
    @Input() minlength: number;

    validatorMessageValues: {
        validator: {
            required: any;
            minlength: any;
            maxlength: any;
            pattern: any
        };
    };

    constructor(public fieldValidator: FieldValidatorService) {
        super();
        this.validatorMessageValues = ValidatorMessageValues;
    }

}
