import { Injectable, Injector } from '@angular/core';
import { TipoMidia } from '../../shared-models/model/tipo-midia.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class TipoMidiaResourceService extends BaseResourceService<TipoMidia> {

    constructor(protected injector: Injector) {
        super('/tipo-midias', injector, TipoMidia.fromJson);
    }
}
