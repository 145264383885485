import { BaseResourceService } from './base-resource.service';
import { Injectable, Injector } from '@angular/core';
import { AnexoPublicacaoDou } from '../../shared-models/model/anexo-publicacao-dou.model';
import { Observable } from 'rxjs';
import { ArquivoDTO } from '../../shared-models/dto/arquivo-dto';

@Injectable({
    providedIn: 'root'
})
export class AnexoPublicacaoDouResourceService extends BaseResourceService<AnexoPublicacaoDou> {

    constructor(protected injector: Injector) {
        super('/anexos-publicacao', injector, AnexoPublicacaoDou.fromJson);
    }

    getArquivoById(idAnexo: number): Observable<ArquivoDTO> {
        return this.http.get<ArquivoDTO>(`${this.urlResource}/${idAnexo}`);
    }

    getAllByPublicacao(idPublicacaoDou: number) {
        return this.http.get<any>(`${this.urlResource}/get-all-by-publicacao/${idPublicacaoDou}`);
    }
    
}
