import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


export class GenericResourceService {

    protected http: HttpClient;
    protected urlResource: string;

    constructor(
        protected apiPath: string,
        protected injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource = environment.apiUrl + apiPath;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

}
