import { SessionStorageService } from './session-storage.service';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { SessionStorageKey } from 'src/app/shared/shared-models/enums/session-storage-key.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { InformacaoSistema } from 'src/app/shared/shared-models/model/informacao-sistema';

const TOKEN_QUERY_PARAM = 'token';

@Injectable({
    providedIn: 'root'
})
export class TokenGuardService implements CanActivate, CanActivateChild {
    constructor(
        private sessionStorageService: SessionStorageService,
        @Inject('InformacaoSistema') private sistema: BehaviorSubject<InformacaoSistema>
    ) {
    }

    checkForToken(activatedRoute: ActivatedRouteSnapshot) {
        return new Promise<boolean>(resolve => {
            const token = activatedRoute.queryParams[TOKEN_QUERY_PARAM];
            if (token && token.trim().toUpperCase()) {
                this.sessionStorageService.storage(SessionStorageKey.TOKEN, token);
                const parsedToken = jwt_decode(token);
                const usuario: any = this.sistema.value;
                usuario.matricula = parsedToken.documento;
                usuario.nome = parsedToken.nome;
                usuario.id = parsedToken.id;
                usuario.nomeExibido = `${usuario.matricula} - ${usuario.nome}`;
                this.sistema.next(usuario);
            }
            resolve(true);
        });
    }

    checkAuthentication() {
        const token = this.sessionStorageService.getItem(SessionStorageKey.TOKEN);
        return (!!token);
    }

    canActivate(activatedRoute: ActivatedRouteSnapshot): boolean | Promise<boolean> {
        return this.checkForToken(activatedRoute);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkForToken(childRoute);
    }
}
