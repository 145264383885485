import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceService } from './base-resource.service';
import { PublicacaoDou } from '../../shared-models/model/publicacao-dou.model';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';

@Injectable({
    providedIn: 'root'
})
export class PublicacaoDouResourceService extends BaseResourceService<PublicacaoDou> {

    constructor(protected injector: Injector) {
        super('/publicacoes-dou', injector, PublicacaoDou.fromJson);
    }

    getAllByIdSolicitacao(idSolicitacao: number): Observable<PublicacaoDou[]> {
        return this.http.get<PublicacaoDou[]>(`${this.urlResource}/idsolicitacao/${idSolicitacao}`);
    }

    finalizePublicacaoDou(idPublicacaoDou: number): Observable<MensagemRetorno> {
        return this.http.put<MensagemRetorno>(`${this.urlResource}/finalize`, idPublicacaoDou);
    }
}

