export const MensagemRetornoKeys = {

    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    UNAUTHORIZED: 'UNAUTHORIZED',

    UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',

    GET_LOGGED_USER_ACCESS_TYPE_ERROR: 'GET_LOGGED_USER_ACCESS_TYPE_ERROR',
    GET_LOGGED_USER_INFO: 'GET_LOGGED_USER_INFO',
    LOGGED_USER_ORIGIN_UNKNOWN: 'LOGGED_USER_ORIGIN_UNKNOWN',

    USER_NOT_FOUND: 'USER_NOT_FOUND',
    USER_EMAIL_NOT_VERIFIED: 'USER_EMAIL_NOT_VERIFIED',

    RESOURCE_ACCESS_NOT_ALLOWED: 'RESOURCE_ACCESS_NOT_ALLOWED',
    TOKEN_NAO_RETORNA_EMPRESAS_VINCULADAS: 'TOKEN_NAO_RETORNA_EMPRESAS_VINCULADAS',

    SAVE_FILE_ERROR: 'SAVE_FILE_ERROR',
    GET_FILE_ERROR: 'GET_FILE_ERROR',
    DELETE_FILE_ERROR: 'DELETE_FILE_ERROR',
    
    SAVE_SUCCESS: 'SAVE_SUCCESS',
    SAVE_ERROR: 'SAVE_ERROR',

    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_ERROR: 'DELETE_ERROR',

    NO_RESULT_FOUND: 'NO_RESULT_FOUND',
    ITEM_ALREADY_REGISTERED: 'ITEM_ALREADY_REGISTERED',
    NON_UNIQUE_RESULT: 'NON_UNIQUE_RESULT',

    INCONSISTENT_DATA: 'INCONSISTENT_DATA',

    // Data validation
    INVALID_DATA_FOR_REQUEST: 'INVALID_DATA_FOR_REQUEST',
    INVALID_DATA_FOR_INSERT: 'INVALID_DATA_FOR_INSERT',
    INVALID_DATA_FOR_SAVE: 'INVALID_DATA_FOR_SAVE',
    
    MISSING_DATA_FOR_REQUEST: 'MISSING_DATA_FOR_REQUEST',
    MISSING_DATA_FOR_SAVE: 'MISSING_DATA_FOR_SAVE',
    MISSING_ANEXO_FOR_SAVE: 'MISSING_ANEXO_FOR_SAVE',

    FIELD_NOT_SUPPORTED: 'FIELD_NOT_SUPPORTED',
    FORMATO_CAMPO_NOME_INVALIDO: 'FORMATO_CAMPO_NOME_INVALIDO',

    ANO_PRODUCAO_MENOR_ANO_CORRENTE: 'ANO_PRODUCAO_MENOR_ANO_CORRENTE',
    INSCRICAO_NAO_PODE_SER_EXCLUIDA: 'INSCRICAO_NAO_PODE_SER_EXCLUIDA',

    INSERT_URL_AS_TIPO_MIDIA: 'INSERT_URL_AS_TIPO_MIDIA',

    NAO_EXISTE_TERMO_ASSINADO: 'NAO_EXISTE_TERMO_ASSINADO',
    PORTARIA_GERADA_COM_SUCESSO: 'PORTARIA_GERADA_COM_SUCESSO',

    // Publicacao
    CAN_NOT_SAVE_PUBLICACAO_WITH_ANOTHER_UNFINALIZED: 'CAN_NOT_SAVE_PUBLICACAO_WITH_ANOTHER_UNFINALIZED',
    PUBLICACAO_WITH_INVALID_DATES_FOR_SAVE: 'PUBLICACAO_WITH_INVALID_DATES_FOR_SAVE',
    CAN_NOT_UPDATE_FINALIZED_PUBLICACAO: 'CAN_NOT_UPDATE_FINALIZED_PUBLICACAO',
    CAN_NOT_FINALIZE_PUBLICACAO_ALREADY_FINALIZED: 'CAN_NOT_FINALIZE_PUBLICACAO_ALREADY_FINALIZED',
    PUBLICACAO_FINISHED_SUCCESS: 'PUBLICACAO_FINISHED_SUCCESS',
    
    // Processo Change Request
    PROCESSO_CHANGE_REQUEST_CHOOSE_DEFERIMENTO_SIM: 'PROCESSO_CHANGE_REQUEST_CHOOSE_DEFERIMENTO_SIM',

    // Reconsideracao
    REQUERIMENTO_RECONSIDERACAO_MUST_CONTAIN_SOME_CHANGE: 'REQUERIMENTO_RECONSIDERACAO_MUST_CONTAIN_SOME_CHANGE',
    REQUERIMENTO_RECONSIDERACAO_SUBMISSION_SUCCESS: 'REQUERIMENTO_RECONSIDERACAO_SUBMISSION_SUCCESS',
    ANALISE_RECONSIDERACAO_MUST_CONTAIN_SOME_CHANGE: 'ANALISE_RECONSIDERACAO_MUST_CONTAIN_SOME_CHANGE',
    ANALISE_RECONSIDERACAO_SUBMISSION_SUCCESS: 'ANALISE_RECONSIDERACAO_SUBMISSION_SUCCESS',

    DATA_EXTRACTION_TOO_LARGE_OUT_MEMORY_ERROR: 'DATA_EXTRACTION_TOO_LARGE_OUT_MEMORY_ERROR',

    SOLICITACAO_BY_PROTOCOL_NOT_FOUND: 'SOLICITACAO_BY_PROTOCOL_NOT_FOUND',
    REABERTURA_PRAZO_OPENED: 'REABERTURA_PRAZO_OPENED',
    START_DATE_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_THE_END_DATE: 'START_DATE_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_THE_END_DATE',
    
    PROCESSO_LEGADO_NOT_AUDITED: 'PROCESSO_LEGADO_NOT_AUDITED',

    // Notificacao email
    EMAIL_ALREADY_REGISTERED: 'EMAIL_ALREADY_REGISTERED',
    SUBSCRIBE_EMAIL_SUCCESS: 'SUBSCRIBE_EMAIL_SUCCESS',
    SUBSCRIBE_RESEND_CONFIRMATION_EMAIL_SUCCESS: 'SUBSCRIBE_RESEND_CONFIRMATION_EMAIL_SUCCESS',
    INVALID_TOKEN_ACCESS: 'INVALID_TOKEN_ACCESS',
    EXPIRED_SUBSCRIPTION_EMAIL_TOKEN: 'EXPIRED_SUBSCRIPTION_EMAIL_TOKEN',

    // Corretor de Registros
    CAN_NOT_SAVE_CORRETOR_REGISTRO_ITEM_WITH_NO_CHANGE: 'CAN_NOT_SAVE_CORRETOR_REGISTRO_ITEM_WITH_NO_CHANGE',

    // Legado Recycle
    PROCESSO_ORIGIN_IS_NOT_LEGADO: 'PROCESSO_ORIGIN_IS_NOT_LEGADO',
    LEGADO_ALREADY_RECYCLED: 'LEGADO_ALREADY_RECYCLED',
    LEGADO_WITH_NO_RESPONSAVEL: 'LEGADO_WITH_NO_RESPONSAVEL',
    RESPONSAVEL_LEGADO_WITH_NO_NOME_OR_NUMERO_DOCUMENTO: 'RESPONSAVEL_LEGADO_WITH_NO_NOME_OR_NUMERO_DOCUMENTO',

    // Front end messages
    SUCCESS: 'SUCCESS',
    INCORRET_EXTENSION: 'INCORRET_EXTENSION',
    FORM_INVALID: 'FORM_INVALID',
    EMPTY_TEMPLATE: 'EMPTY_TEMPLATE',
    CAN_NOT_DELETE_DATA_IN_USE: 'CAN_NOT_DELETE_DATA_IN_USE',
    EMAILS_DOES_NOT_MATCH: 'EMAILS_DOES_NOT_MATCH',
    NO_CHANGES_FOR_SAVE: 'NO_CHANGES_FOR_SAVE',
    LINK_FTP_COPY_SUCCESS: 'LINK_FTP_COPY_SUCCESS',

};
