import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from '@ngx-translate/core';
import { TruncateModule } from 'ng2-truncate/dist/truncate.module';
import { ClipboardModule } from 'ngx-clipboard';
import { MaterialDesignModule } from '../../core/material-design/material-design.module';
import { HasPermissionDirective } from '../shared-directives/has.permission.directive';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AdaptiveDialogComponent } from './dialog/adaptive-dialog/adaptive-dialog.component';
import { DialogAlertComponent } from './dialog/dialog-alert/dialog-alert.component';
import { DialogEmpresasVinculadasComponent } from './dialog/dialog-empresas-vinculadas/dialog-empresas-vinculadas.component';
import { DialogJustificationComponent } from './dialog/dialog-justification/dialog-justification.component';
import { DialogRecuperarSenhaComponent } from './dialog/dialog-recuperar-senha/dialog-recuperar-senha.component';
import { DialogSubscribeComponent } from './dialog/dialog-subscribe/dialog-subscribe.component';
import { DialogTermoComponent } from './dialog/dialog-termo-responsabilidade/dialog-termo.component';
import { DialogoConfirmacaoComponent } from './dialog/dialogo-confirmacao/dialogo-confirmacao.component';
import { DialogoInformacaoComponent } from './dialog/dialogo-informacao/dialogo-informacao.component';
import { DialogoPreparacaoComponent } from './dialog/dialogo-preparacao/dialogo-preparacao.component';
import { EditorCustomTemplateComponent } from './editor/editor-custom-template/editor-custom-template.component';
import { EditorTemplatePortariaComponent } from './editor/editor-template-portaria/editor-template-portaria.component';
import { ContextoFormComponent } from './gerenciador-contexto/form/contexto-form/contexto-form.component';
import { ReaberturaPrazoFormComponent } from './gerenciador-contexto/form/reabertura-prazo-form/reabertura-prazo-form.component';
import { RepresentanteLegalFormComponent } from './gerenciador-contexto/form/representante-legal-form/representante-legal-form.component';
import { TipoDocumentoFormComponent } from './gerenciador-contexto/form/tipo-documento-form/tipo-documento-form.component';
import { GerenciadorContextoComponent } from './gerenciador-contexto/gerenciador-contexto.component';
import { GerenciadorProcessoFilterComponent } from './gerenciador-processo/gerenciador-processo-filter/gerenciador-processo-filter.component';
import { GerenciadorProcessoListComponent } from './gerenciador-processo/gerenciador-processo-list/gerenciador-processo-list.component';
import { GerenciadorProcessoComponent } from './gerenciador-processo/gerenciador-processo.component';
import { HistoricoCorrecaoRegistroComponent } from './historico/historico-list/historico-correcao-registro/historico-correcao-registro.component';
import { HistoricoListComponent } from './historico/historico-list/historico-list.component';
import { HistoricoProcessoOcultoComponent } from './historico/historico-processo-oculto/historico-processo-oculto.component';
import { InfoVersaoComponent } from './info-versao/info-versao.component';
import { InputsModule } from './inputs/inputs.module';
import { InternacionalizacaoComponent } from './internacionalizacao/internacionalizacao.component';
import { PessoaFormComponent } from './pessoa-form/pessoa-form.component';
import { SolicitacaoClassificacaoViewFirstClassificacaoAtribuidaComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-first-classificacao-atribuida/solicitacao-classificacao-view-first-classificacao-atribuida.component';
import { SolicitacaoClassificacaoViewMainDataComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-main-data/solicitacao-classificacao-view-main-data.component';
import { SolicitacaoClassificacaoViewPublicacoesComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-publicacoes/solicitacao-classificacao-view-publicacoes.component';
import { SolicitacaoClassificacaoViewReconsideracaoComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-reconsideracao/solicitacao-classificacao-view-reconsideracao.component';
import { SolicitacaoClassificacaoViewRecursoComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-recurso/solicitacao-classificacao-view-recurso.component';
import { SolicitacaoClassificacaoViewComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TableModule } from './table/table.module';
import { RepublicaFooterComponent } from './template/republica/republica-footer/republica-footer.component';
import { RepublicaHeaderComponent } from './template/republica/republica-header/republica-header.component';
import { UploadArquivosComponent } from './upload-arquivos/upload-arquivos.component';
import { UploadDocumentosComponent } from './upload-arquivos/upload-documentos/upload-documentos.component';
import { ViewerSectionTitleComponent } from './viewer-section-title/viewer-section-title.component';
import { SolicitacaoClassificacaoViewRepresentanteLegalComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-representante-legal/solicitacao-classificacao-view-representante-legal.component';
import { FieldViewComponent } from './field-view/field-view.component';
import { SolicitacaoClassificacaoViewInfoEssenciaisComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-info-essenciais/solicitacao-classificacao-view-info-essenciais.component';
import { SolicitacaoClassificacaoViewChildComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-child/solicitacao-classificacao-view-child.component';
import { SolicitacaoClassificacaoViewInfoEspecificasComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-info-especificas/solicitacao-classificacao-view-info-especificas.component';
import { SolicitacaoClassificacaoViewFirstClassificacaoComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-first-classificacao/solicitacao-classificacao-view-first-classificacao.component';
import { SolicitacaoClassificacaoViewAnexosComponent } from './solicitacao-classificacao-view/solicitacao-classificacao-view-anexos/solicitacao-classificacao-view-anexos.component';
import { AbstractCustomContextoFormComponent } from './gerenciador-contexto/form/abstract-custom-contexto-form/abstract-custom-contexto-form.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialDesignModule,
        FlexModule,
        FlexLayoutModule,
        TruncateModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        InputsModule,
        CKEditorModule,
        ClipboardModule,
        RouterModule,
        TableModule,
    ],
    declarations: [
        DialogTermoComponent,
        DialogoConfirmacaoComponent,
        DialogRecuperarSenhaComponent,
        DialogJustificationComponent,
        GerenciadorContextoComponent,
        ContextoFormComponent,
        InfoVersaoComponent,
        DialogoInformacaoComponent,
        DialogAlertComponent,
        SpinnerComponent,
        UploadArquivosComponent,
        GerenciadorProcessoComponent,
        GerenciadorProcessoListComponent,
        HistoricoListComponent,
        SolicitacaoClassificacaoViewComponent,
        InternacionalizacaoComponent,
        EditorTemplatePortariaComponent,
        BreadcrumbComponent,
        HasPermissionDirective,
        DialogEmpresasVinculadasComponent,
        PessoaFormComponent,
        DialogoPreparacaoComponent,
        RepresentanteLegalFormComponent,
        TipoDocumentoFormComponent,
        UploadDocumentosComponent,
        ReaberturaPrazoFormComponent,
        SolicitacaoClassificacaoViewFirstClassificacaoAtribuidaComponent,
        SolicitacaoClassificacaoViewMainDataComponent,
        SolicitacaoClassificacaoViewReconsideracaoComponent,
        SolicitacaoClassificacaoViewPublicacoesComponent,
        EditorCustomTemplateComponent,
        SolicitacaoClassificacaoViewRecursoComponent,
        RepublicaHeaderComponent,
        RepublicaFooterComponent,
        DialogSubscribeComponent,
        HistoricoCorrecaoRegistroComponent,
        HistoricoProcessoOcultoComponent,
        ViewerSectionTitleComponent,
        AdaptiveDialogComponent,
        GerenciadorProcessoFilterComponent,
        SolicitacaoClassificacaoViewRepresentanteLegalComponent,
        FieldViewComponent,
        SolicitacaoClassificacaoViewInfoEssenciaisComponent,
        SolicitacaoClassificacaoViewChildComponent,
        SolicitacaoClassificacaoViewInfoEspecificasComponent,
        SolicitacaoClassificacaoViewFirstClassificacaoComponent,
        SolicitacaoClassificacaoViewAnexosComponent,
        AbstractCustomContextoFormComponent,
    ],
    entryComponents: [
        DialogTermoComponent,
        DialogoConfirmacaoComponent,
        DialogRecuperarSenhaComponent,
        DialogJustificationComponent,
        DialogoInformacaoComponent,
        DialogAlertComponent,
        DialogEmpresasVinculadasComponent,
        DialogoPreparacaoComponent,
        DialogSubscribeComponent,
        AdaptiveDialogComponent
    ],
    exports: [
        DialogTermoComponent,
        DialogoConfirmacaoComponent,
        DialogRecuperarSenhaComponent,
        DialogJustificationComponent,
        GerenciadorContextoComponent,
        ContextoFormComponent,
        InfoVersaoComponent,
        DialogoInformacaoComponent,
        DialogAlertComponent,
        SpinnerComponent,
        UploadArquivosComponent,
        GerenciadorProcessoComponent,
        HistoricoListComponent,
        SolicitacaoClassificacaoViewComponent,
        InternacionalizacaoComponent,
        EditorTemplatePortariaComponent,
        BreadcrumbComponent,
        HasPermissionDirective,
        PessoaFormComponent,
        UploadDocumentosComponent,
        EditorCustomTemplateComponent,
        SolicitacaoClassificacaoViewMainDataComponent,
        SolicitacaoClassificacaoViewFirstClassificacaoAtribuidaComponent,
        SolicitacaoClassificacaoViewReconsideracaoComponent,
        SolicitacaoClassificacaoViewPublicacoesComponent,
        SolicitacaoClassificacaoViewRecursoComponent,
        TableModule,
        RepublicaHeaderComponent,
        RepublicaFooterComponent,
        DialogSubscribeComponent,
        HistoricoProcessoOcultoComponent,
        ViewerSectionTitleComponent,
        AdaptiveDialogComponent,
    ]
})
export class SharedComponentsModule {
}
