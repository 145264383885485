import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'app-dialog-alert',
    templateUrl: './dialog-alert.component.html',
    styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent {
    titulo: string;
    mensagem: string;

    constructor(public dialogRef: MatDialogRef<DialogAlertComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogAlertComponent) {
        this.titulo = data.titulo;
        this.mensagem = data.mensagem;
    }

}

export class DialogAlertModel {

    constructor(public titulo: string, public mensagem: string) {
    }
}


