import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DialogoInformacaoModel } from 'src/app/shared/shared-components/dialog/dialogo-informacao/dialogo-informacao.component';
import { SolicitacaoClassificacaoPaginacaoDTO } from 'src/app/shared/shared-models/dto/processo-paginacao/solicitacao-classificacao-paginacao-dto';
import { ProtocolarSeiDTO } from 'src/app/shared/shared-models/dto/protocolar-sei-dto';
import { Historico } from 'src/app/shared/shared-models/model/historico.model';
import { InformacaoSistema } from 'src/app/shared/shared-models/model/informacao-sistema';
import { JustificativaDevolucao } from 'src/app/shared/shared-models/model/justificativa-devolucao.model';
import { MensagemRetorno } from 'src/app/shared/shared-models/model/mensagem-retorno';
import { Pessoa } from 'src/app/shared/shared-models/model/pessoa.model';
import { SolicitacaoClassificacao } from 'src/app/shared/shared-models/model/solicitacao-classificacao.model';
import { ApoioFacadeService } from 'src/app/shared/shared-services/facades/apoio-facade.service';
import { IntegracaoSeiService } from 'src/app/shared/shared-services/integracao-sei.service';
import { HistoricoResourceService } from 'src/app/shared/shared-services/resources/historico-resource.service';
import { ConverterPdfService } from 'src/app/shared/shared-services/utilitarios/converter-pdf.service';
import { TriagemValues } from 'src/app/shared/shared-values/triagem-values';
import { DialogService } from 'src/app/shared/shared-services/utilitarios/dialog.service';
import { StatusSolicitacaoClassificacaoEnum } from 'src/app/shared/shared-models/enums/status-solicitacao-classificacao.enum';
import { CONST_ROUTER_UTIL } from 'src/app/shared/shared-utils/consts/const-router-util';

const SKIP_LOCATION = { skipLocationChange: true };

@Injectable({
    providedIn: 'root'
})
export class TriagemService {
    values: any;

    constructor(
        private historicoResourceService: HistoricoResourceService,
        private integracaoSeiService: IntegracaoSeiService,
        private router: Router,
        private dialogService: DialogService,
        private apoioFacadeService: ApoioFacadeService,
        @Inject('InformacaoSistema') sistema: BehaviorSubject<InformacaoSistema>,
        private converterPdfService: ConverterPdfService,
        private ngZone: NgZone
    ) {
        sistema.subscribe(() => {
            this.values = TriagemValues;
        });
    }

    getSegmentosMercadoByModuloID() {
        return this.apoioFacadeService.getSegmentosMercado()
            .pipe(pluck('itens'));
    }

    goToProcesso(solicitacao: SolicitacaoClassificacao) {
        this.router.navigate([`/triagem/triagem-avaliacao/${solicitacao.id}`], SKIP_LOCATION);
    }

    buscarProcessos(dto: SolicitacaoClassificacaoPaginacaoDTO) {
        return this.apoioFacadeService.getProcessosPorStatus(dto);
    }

    criarHistoricoCandidatoByStatus(usuarioAtual: Pessoa, solicitacaoClassificacao: SolicitacaoClassificacao, justificativas: JustificativaDevolucao[], statusID: number): Historico {
        let historicoCandidato = Historico.builder(usuarioAtual, solicitacaoClassificacao, justificativas);
        historicoCandidato.status = statusID.toString();
        return historicoCandidato;
    }

    criarHistorico(historico: Historico) {
        return this.historicoResourceService.create(historico);
    }

    construirProtocolarSei(idSolicitacao, conteudoPdf) {
        let protocolar = new ProtocolarSeiDTO();
        protocolar.id = idSolicitacao;
        protocolar.conteudo = conteudoPdf;
        return protocolar;
    }

    criarNumeroProtocoloSei(protocolarSeiDto: ProtocolarSeiDTO): Observable<MensagemRetorno> {
        return this.integracaoSeiService.criarNumeroProtocolo(protocolarSeiDto);
    }

    converterHtmlEmPDF(element: Element, flags: any) {
        return this.converterPdfService.converterElementEmPDF(element, flags);
    }

    getHistoricosBySolicitacaoClassificacaoID(id: number) {
        return this.historicoResourceService.getById(id);
    }

    chamarAvisoDevolucaoSolicitacao(): void {
        let dialogRef = this.dialogService.openInfoDialog(
            new DialogoInformacaoModel(
                this.values.conferenciaDocumental.devolucao.titulo,
                this.values.conferenciaDocumental.devolucao.mensagem,
                this.values.conferenciaDocumental.devolucao.btnTitulo
            )
        );

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (!dialogResult) {
                return;
            }
            this.goToTriagem();
        });
    }

    goToTriagem() {
        this.ngZone.run(() => this.router.navigate([CONST_ROUTER_UTIL.TRIAGEM], SKIP_LOCATION));
    }

    goToTriagemByStatus(status: StatusSolicitacaoClassificacaoEnum) {
        let listIndex = TriagemValues.lists.findIndex(obj => obj.statusId === status);

        if(listIndex < 0) {
            let statusName = StatusSolicitacaoClassificacaoEnum[status].replace(/_/g, ' ');
            alert(`Status ${statusName} sem destino de Triagem para redirecionar`);
            return;
        }

        this.router.navigate([CONST_ROUTER_UTIL.TRIAGEM], { state: { listIndex: listIndex } });
    }
}

