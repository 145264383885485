import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AtributoSolicitacaoClassificacao } from '../../shared-models/model/atributo-solicitacao-classificacao.model';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class AtributoSolicitacaoClassificacaoResourceService extends BaseResourceService<AtributoSolicitacaoClassificacao> {

    constructor(protected injector: Injector) {
        super('/atributos', injector, AtributoSolicitacaoClassificacao.fromJson);
    }

    getAll(): Observable<MensagemRetorno> {
        return this.http.get<MensagemRetorno>(`${this.urlResource}`);
    }
}
