import { BaseResourceModel } from "./base-resource.model";
import { TipoDocumentoVisualizacao } from "./tipo-documento-visualizacao.model";

export class TipoDocumento extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
        public permanente?: boolean,
        public idTipoDocumentoSei?: string,
        public tipoVisualizacao?: TipoDocumentoVisualizacao,
        public publicaPortal?: boolean
    ) {
        super();
    }

    static fromJson(jsonData: any): TipoDocumento {
        return Object.assign(new TipoDocumento(), jsonData);
    }
}
