import { Component, Input, OnInit } from '@angular/core';
import { GlobalValues } from '../../shared-values/global-values';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    @Input() heightCenter: boolean = true;
    
    msgSpinner: any;

    ngOnInit(): void {
        this.msgSpinner = GlobalValues.msg.spinner[0];
    }

}
