<div fxLayoutAlign="center center">
    <h1 mat-dialog-title>{{titulo}}</h1>
</div>
<div fxLayoutAlign="center center" class="mb-3">
    <p>{{mensagem}}</p>
</div>

<mat-chip-list>
    <mat-button-toggle-group (change)=" onChangeFlag($event)">
        <mat-button-toggle *ngFor="let cnpj of cnpjs" [value]="cnpj">
            <mat-chip color="primary" selected [disabled]="true">
                <strong>{{ formatarMascara(cnpj.cnpj)}}</strong>
            </mat-chip>
            <span class="ml-2">{{ cnpj.nome}}</span>
        </mat-button-toggle>
    </mat-button-toggle-group>
</mat-chip-list>