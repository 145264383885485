import { MensagemRetorno } from './../../shared-models/model/mensagem-retorno';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TermoAceite } from '../../shared-models/model/termo-aceite.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class TermoAceiteResourceService extends BaseResourceService<TermoAceite> {

    constructor(protected injector: Injector) {
        super('/termos-aceite', injector, TermoAceite.fromJson);
    }

    createForLoggedUser(cnpj: string) {
        return this.http.post<any>(`${this.urlResource}/${cnpj}`, {});
    }

    existsByLoggedUser(cnpj: string) {
        return this.http.get<boolean>(`${this.urlResource}/${cnpj}`);
    }

    deleteAll(): Observable<MensagemRetorno> {
        return this.http.delete<MensagemRetorno>(`${this.urlResource}/delete-all`);
    }

}
