import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/pt-br';
import { take } from 'rxjs/operators';
import { DialogoConfirmacaoModel } from 'src/app/shared/shared-components/dialog/dialogo-confirmacao/dialogo-confirmacao.component';
import { Values } from 'src/app/shared/shared-models/class/values';
import { InformacaoSistema } from 'src/app/shared/shared-models/model/informacao-sistema';
import { MensageriaService } from 'src/app/shared/shared-services/mensageria-service';
import { NavegacaoUtilService } from 'src/app/shared/shared-services/navegacao-util.service';
import { AuthHelper } from 'src/app/shared/shared-utils/auth-helper';
import { ROLES } from 'src/app/shared/shared-utils/consts/const-roles';
import { ModeloTextoCustomizadoEnum } from 'src/app/shared/shared-models/enums/modelo-texto-customizado.enum';
import { ModeloTextoCustomizado } from 'src/app/shared/shared-models/model/modelo-texto-customizado.model';
import { EditorCustomTemplateValues } from './editor-custom-template-values';
import { EditorCustomTemplateService } from './editor-custom-template.service';
import { DialogService } from 'src/app/shared/shared-services/utilitarios/dialog.service';

export interface EditorArtefato {
    template?: {
        pt?: any,
        en?: any,
        es?: any
    };
    acoes?: any;
}
@Component({
    selector: 'app-editor-custom-template',
    templateUrl: './editor-custom-template.component.html',
    styleUrls: ['./editor-custom-template.component.scss']
})

export class EditorCustomTemplateComponent implements OnInit, AfterViewChecked, OnChanges {

    @Input() readonly: boolean = true;
    @Input() data: any;
    @Input() template: any;
    @Input() custom: any;
    @Input() modeloTextoEscolhido: ModeloTextoCustomizadoEnum;
    @Input() onlyRead: boolean;
    @Input() languageAcronym: string;
    @Output() editorPreparadoEmmiter = new EventEmitter();

    informacoes: InformacaoSistema;
    editorLib = DecoupledEditor;
    editor: EditorArtefato;
    @Input() config = {
        language: 'pt-br',
        toolbar: {
            removeItems: ['imageUpload']
        },
        placeholder: 'Construa seu modelo aqui.'
    };

    values: Values;
    modeloTextoCustomizado: ModeloTextoCustomizado
    flag: any;

    constructor(
        private service: EditorCustomTemplateService,
        private navegacaoUtilService: NavegacaoUtilService,
        private mensageriaService: MensageriaService,
        private dialogService: DialogService,
        protected authHelper: AuthHelper,
    ) { }

    ngOnInit() {
        this.flag = {
            dadosCarregados: false,
            editMode: false,
            editModeView: this.authHelper.hasPermissao(ROLES.CUSTOM_TEMPLATE_EDITAR),
        }
        this.prepararArtefatosEditores();
        this.values = { component: EditorCustomTemplateValues };
    }

    prepararArtefatosEditores() {
        this.editor = {
            template: {}
        };
        this.getModeloTextoCustomizadoByTipoModelo();
    }

    ngAfterViewChecked(): void {
        this._updateCustomTextTemplate();
    }

    private _updateCustomTextTemplate(): void {
        if (this.modeloTextoCustomizado) {
            this.editor.template = this.modeloTextoCustomizado[this.languageAcronym];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.languageAcronym['currentValue'] !== changes.languageAcronym['previousValue']) {
            this.ngOnInit();
        }
    }

    chosenLanguage(languageAcronym: string) {
        if (this.editor && this.modeloTextoCustomizado) {
            this.editor.acoes.setData(this.modeloTextoCustomizado[languageAcronym]);
        }
    }

    isTermoUso(): boolean {
        return this.modeloTextoEscolhido == ModeloTextoCustomizadoEnum.TERMO_RESPONSABILIDADE
    }

    getModeloTextoCustomizadoByTipoModelo() {
        this.service.getModeloTextoCustomizadoByTipoModelo(this.modeloTextoEscolhido)
            .pipe(take(1))
            .subscribe(
                (modelo: ModeloTextoCustomizado) => {
                    if (modelo) {
                        this.modeloTextoCustomizado = modelo;
                    } else {
                        this.modeloTextoCustomizado = new ModeloTextoCustomizado();
                        this.modeloTextoCustomizado.tipoModelo = this.modeloTextoEscolhido;
                    }
                }, 
                (errorResponse: HttpErrorResponse) => {
                    this.navegacaoUtilService.toProcessos();
                    this.mensageriaService.handleApiError(errorResponse);
                }
            )
            .add(() => {
                this.flag.dadosCarregados = true;
                this.editorPreparadoEmmiter.emit(this.flag.dadosCarregados);
            });
    }

    ativateModeEdition() {
        this.flag.editMode = true;
    }

    salvarModeloTextoCustomizado() {
        this.confirmarEscolha(this.modeloTextoCustomizado['id'] == null ?
            acaoEnum.SAVE :
            acaoEnum.UPDATE
        );
    }

    updateTermoUso() {
        this.confirmarEscolha(acaoEnum.UPDATE_TERMO_USO);
    }

    clear() {
        this.confirmarEscolha(acaoEnum.CLEAR);
    }

    cancel() {
        this.confirmarEscolha(acaoEnum.CANCEL);
    }

    public confirmarEscolha(acao: acaoEnum) {
        let msg: string;
        let title: string;
        let callback: any;
        switch (acao) {
            case acaoEnum.SAVE:
                title = this.values.component.dialog.save.title;
                msg = this.values.component.dialog.save.msg;
                callback = this._createModeloTextoCustomizado;
                break;
            case acaoEnum.UPDATE:
                title = this.values.component.dialog.update.title
                msg = this.values.component.dialog.update.msg;
                callback = this._updateModeloTextoCustomizado;
                break;
            case acaoEnum.CLEAR:
                title = this.values.component.dialog.clear.title;
                msg = this.values.component.dialog.clear.msg;
                callback = this._clearModeloTextoCustomizado;
                break;
            case acaoEnum.CANCEL:
                title = this.values.component.dialog.cancel.title
                msg = this.values.component.dialog.cancel.msg;
                callback = this._cancelEditModeloTextoCustomizado;
                break;
            case acaoEnum.UPDATE_TERMO_USO:
                title = this.values.component.dialog.updateTermoUso.title
                msg = this.values.component.dialog.updateTermoUso.msg;
                callback = this._updateTermoUso;
                break;
        }

        let dialogRef = this.dialogService.openConfirmationDialog(
            new DialogoConfirmacaoModel(title, msg)
        );

        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(
                dialogResult => {
                    if (!dialogResult) {
                        return;
                    }
                    callback();
                });
    }

    private _createModeloTextoCustomizado = (): void => {
        this.modeloTextoCustomizado[this.languageAcronym] = this.editor.acoes.getData();

        this.flag.dadosCarregados = false;
        this.service.persist(this.modeloTextoCustomizado)
            .pipe(take(1))
            .subscribe(
                res => {
                    this.mensageriaService.showMensagemSucesso(res.mensagem);
                    this.getModeloTextoCustomizadoByTipoModelo();
                },
                (errorResponse: HttpErrorResponse) => {
                    this.navegacaoUtilService.toProcessos();
                    this.mensageriaService.handleApiError(errorResponse);
                }
            )
            .add(() => {
                this._cancelEditModeloTextoCustomizado();
            });
    }

    private _updateModeloTextoCustomizado = (): void => {
        let candidate = { ...this.modeloTextoCustomizado };
        candidate[this.languageAcronym] = this.editor.acoes.getData();

        this.flag.dadosCarregados = false;
        this.service.persist(candidate)
            .pipe(take(1))
            .subscribe(
                res => {
                    this.mensageriaService.showMensagemSucesso(res.mensagem);
                    this.getModeloTextoCustomizadoByTipoModelo();
                    if (this.isTermoUso()) {
                        this._dropTermoAceiteTable();
                    }
                },
                (errorResponse: HttpErrorResponse) => {
                    this.navegacaoUtilService.toHome();
                    this.mensageriaService.handleApiError(errorResponse);
                }
            )
            .add(() => {
                this._cancelEditModeloTextoCustomizado();
            });
    }

    private _clearModeloTextoCustomizado = (): void => {
        if (!this.modeloTextoCustomizado) {
            this.editor.acoes.setData('');
        } else {
            this.editor.acoes.setData(this.modeloTextoCustomizado[this.languageAcronym]);
        }
    }

    private _cancelEditModeloTextoCustomizado = (): void => {
        this.languageAcronym = 'pt';
        this.ngOnInit();
    }

    private _updateTermoUso = (): void => {
        this._updateModeloTextoCustomizado();
    }

    private _dropTermoAceiteTable() {
        this.service.dropTermoAceiteTable()
            .subscribe(
                () => {
                    this.mensageriaService.showMensagemSucesso();
                },
                (errorResponse: HttpErrorResponse) => {
                    this.navegacaoUtilService.toHome();
                    this.mensageriaService.handleApiError(errorResponse);
                }
            )
    }

    onReady(evento, artefato) {
        evento.ui.getEditableElement().parentElement.insertBefore(
            evento.ui.view.toolbar.element,
            evento.ui.getEditableElement(),
            this.getEditorInstance(evento, artefato)
        );
    }

    onlyView(evento, artefato) {
        this.getEditorInstance(evento, artefato);
    }

    getEditorInstance(evento, artefato) {
        artefato.acoes = evento;
        artefato.acoes.setData(artefato.template);
    }
}

export enum acaoEnum {
    SAVE,
    UPDATE,
    CLEAR,
    CANCEL,
    UPDATE_TERMO_USO
}
