import { Injectable, Injector } from '@angular/core';
import { Emissora } from '../../shared-models/model/emissora.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class EmissoraResourceService extends BaseResourceService<Emissora> {

    constructor(protected injector: Injector) {
        super('/emissoras', injector, Emissora.fromJson);
    }
}
