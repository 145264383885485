import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MensageriaService } from 'src/app/shared/shared-services/mensageria-service';
import { GlobalValues } from 'src/app/shared/shared-values/global-values';
import { FormUtil } from 'src/app/shared/shared-utils/form.util';
import { MensagemRetornoKeys } from 'src/app/shared/shared-values/mensagem-retorno-keys';
import { NotificacaoGrupoSegmentoMercado } from 'src/app/shared/shared-models/model/notificacao/notificacao-grupo-segmento-mercado.model';
import { NotificacaoGrupoSegmentoMercadoEnum } from 'src/app/shared/shared-models/enums/notificacao/notificacao-grupo-segmento-mercado.enum';
import { NotificacaoInscricao } from 'src/app/shared/shared-models/model/notificacao/notificacao-inscricao.model';
import { NotificacaoInscricaoOrigemEnum } from 'src/app/shared/shared-models/enums/notificacao/notificacao-inscricao-origem.enum';

@Component({
    selector: 'app-dialog-subscribe',
    templateUrl: './dialog-subscribe.component.html',
    styleUrls: ['./dialog-subscribe.component.scss']
})
export class DialogSubscribeComponent implements OnInit {

    readonly MAX_LENGHT = {
        NAME: 60, 
        EMAIL: 40
    }

    translationValues: any;
    form: FormGroup;
    grupoSegmentoMercadoOptions: NotificacaoGrupoSegmentoMercado[];
    grupoAttribute: string;

    private _grupoSegmentoMercadoTODOS: NotificacaoGrupoSegmentoMercado;
    private _language: string;
    

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogSubscribeComponent>,
        private fb: FormBuilder,
        private mensageriaService: MensageriaService,
    ) { }

    ngOnInit() {
        this.translationValues = GlobalValues;
        this._language = this.mensageriaService.getLanguage();
        this._initForm();
        this._setGruposSegmentoMercado();
    }

    private _initForm() {
        this.form = this.fb.group({
            nome:           [null, [Validators.required, Validators.maxLength(this.MAX_LENGHT.NAME)]],
            email:          [null, [Validators.required, Validators.email, Validators.maxLength(this.MAX_LENGHT.EMAIL)]],
            emailConfirm:   [null, [Validators.required, Validators.email, Validators.maxLength(this.MAX_LENGHT.EMAIL)]],
            grupos:         [null, [Validators.required]]
        });
        
        if(this.data.inscricao) {
            this._fillForm();
        }
    }

    private _fillForm() {
        let inscricao: NotificacaoInscricao = this.data.inscricao;
        
        let group = inscricao.grupoSegmentoMercado;
        if(group.id == NotificacaoGrupoSegmentoMercadoEnum.TODOS) {
            group = this._grupoSegmentoMercadoTODOS;
        }

        this.form.patchValue({
            nome: inscricao.nome,
            email: inscricao.email,
            emailConfirm: inscricao.email,
            grupos: [group]
        });
    }

    private _setGruposSegmentoMercado() {
        const TODOS_ID =  NotificacaoGrupoSegmentoMercadoEnum.TODOS;
        let grupos: NotificacaoGrupoSegmentoMercado[] = this.data.gruposSegmentoMercado;
        this._grupoSegmentoMercadoTODOS = grupos.find(x => x.id == TODOS_ID);
        this.grupoSegmentoMercadoOptions = grupos.filter(x => x.id != TODOS_ID);

        this.grupoAttribute = `${this._language}Nome`;
    }


    subscribe() {        
        FormUtil.markFormAsTouched(this.form);
        if(this.form.invalid) {
            return;
        }

        let data = this.form.value;

        if(data.email !== data.emailConfirm) {
            this.mensageriaService.showMensagemErro(MensagemRetornoKeys.EMAILS_DOES_NOT_MATCH);
            return;
        }

        let grupo = this._getSelectedGrupoSegmentoMercado(data.grupos);

        this.dialogRef.close(
            new NotificacaoInscricao(
                null, 
                grupo, 
                data.nome, 
                data.email, 
                this._language, 
                NotificacaoInscricaoOrigemEnum.CLASSIND
            )
        );
    }

    private _getSelectedGrupoSegmentoMercado(grupos: NotificacaoGrupoSegmentoMercado[]) {
        if(grupos.length > 1) {
            return this._grupoSegmentoMercadoTODOS;
        }
        return grupos[0];
    }

}
