import { BaseResourceModel } from "./base-resource.model";
import { SegmentoMercado } from "./segmento-mercado.model";

export class ModeloPortaria extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
        public template?: string,
        public segmentosMercado?: SegmentoMercado[],
        public permanente?: boolean
    ) {
        super();
    }
    static fromJson(jsonData: any): ModeloPortaria {
        return Object.assign(new ModeloPortaria(), jsonData);
    }
}
