import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceService } from './base-resource.service';
import { RequerimentoReconsideracao } from './../../shared-models/model/requerimento-reconsideracao.model';

@Injectable({
    providedIn: 'root'
})
export class RequerimentoReconsideracaoResourceService extends BaseResourceService<RequerimentoReconsideracao> {

    constructor(protected injector: Injector) {
        super('/requerimentos-reconsideracao', injector, RequerimentoReconsideracao.fromJson);
    }

    getBySolicitacaoId(idSolicitacao: number): Observable<RequerimentoReconsideracao> {
        return this.http.get<RequerimentoReconsideracao>(`${this.urlResource}/idsolicitacao/${idSolicitacao}`);
    }
}
