import { BaseResourceService } from './base-resource.service';
import { Injectable, Injector } from '@angular/core';
import { Recurso } from '../../shared-models/model/recurso.model';

@Injectable({
    providedIn: 'root'
})
export class RecursoResourceService extends BaseResourceService<Recurso> {

    constructor(protected injector: Injector) { 
        super('/recursos', injector, Recurso.fromJson);
    }

    getBySolicitacaoClassificacao(idSolicitacao: number) {
        return this.http.get<Recurso>(`${this.urlResource}/solicitacao-classificacao/${idSolicitacao}`);
    }
    
}
