import { TokenModel } from '../../shared/shared-models/model/token-model';
import * as jwt_decode from 'jwt-decode';

export class AuthToken implements TokenModel {
    exp: number;
    iat: number;
    id: number;
    name: string;
    sub: string;

    constructor(rawToken: string) {
        if (rawToken) {
            const t = jwt_decode(rawToken);
            this.exp = t.exp;
            this.iat = t.iat;
            this.name = t.name;
            this.sub = t.sub;
            this.id = t.id;
        } else {
            this.exp = 0;
            this.iat = 0;
            this.name = 'USUÁRIO LOCAL';
            this.sub = '00000000000';
            this.id = 0;
        }
    }

}
