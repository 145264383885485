import { Injectable, Injector } from '@angular/core';
import { BaseResourceService } from './base-resource.service';
import { HistoricoSolicitacaoClassificacaoOculta } from '../../shared-models/model/historico-solicitacao-classificacao-oculta.model';

@Injectable({
    providedIn: 'root'
})
export class HistoricoSolicitacaoClassificacaoOcultaResource extends BaseResourceService<HistoricoSolicitacaoClassificacaoOculta> {

    constructor(protected injector: Injector) {
        super('/historico-processos-ocultos', injector,
            HistoricoSolicitacaoClassificacaoOculta.fromJson
        );
    }

    getAllBySolicitacaoClassificacao(idSolicitacao: number) {
        return this.http.get<HistoricoSolicitacaoClassificacaoOculta[]>(
            `${this.urlResource}/all-by-solicitacao-classificacao/${idSolicitacao}`
        );
    }

}
