/* tslint:disable:no-string-literal */
export const environment = {
    production: true,
    apiUrl: window['env']['apiUrl'],
    ssoUrl: window['env']['ssoUrl'],
    ssoClientId: window['env']['ssoClientId'],
    ssoRealm: window['env']['ssoRealm'],
    LOGIN_UNICO_SSO_URL: window['env']['loginUnicoSsoUrl'],
    LOGIN_UNICO_CLIENT_ID: window['env']['loginUnicoClientId'],
    LOGIN_UNICO_REDIRECT_URL: window['env']['loginUnicoRedirectUrl'],
    LOGOUT_REDIRECT_URL: window['env']['loginUnicoLogoutRedirectUrl']

};
