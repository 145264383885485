<div *ngIf="!data.isTraduction else showTitleWithTraduction" class="dialog-title">
    <h1 mat-dialog-title>{{values.component.title.pt}}</h1>
</div>
<ng-template #showTitleWithTraduction>
    <div class="dialog-title">
        <h1 mat-dialog-title>{{'TRADUCAO'| translate: values.component.title}}</h1>
    </div>
</ng-template>

<div mat-dialog-content class="mb-3">
    <div *ngIf="!data.isTraduction else showContentWithTraduction" class="dialog-content">
        <div [innerHtml]="values.component.content.pt"></div>
    </div>
    <ng-template #showContentWithTraduction>
        <div class="dialog-content">
            <div [innerHtml]="'TRADUCAO'| translate: values.component.content"></div>
        </div>
    </ng-template>
</div>

<div *ngIf=" !data.isTraduction else showBtnsWithTraduction" class="dialog-btns">
    <button *ngIf="values.component.btnCancel" mat-button mat-dialog-close>
        {{values.component.btnCancel.pt}}
    </button>
    <button *ngIf="values.component.btnSubmit" mat-raised-button color="primary" (click)="onConfirm()">
        {{values.component.btnSubmit.pt}}
    </button>
</div>
<ng-template #showBtnsWithTraduction>
    <div class="dialog-btns">
        <button *ngIf="values.component.btnCancel" mat-button mat-dialog-close>
            {{'TRADUCAO'| translate: values.component.btnCancel}}
        </button>
        <button *ngIf="values.component.btnSubmit" mat-raised-button color="primary" (click)="onConfirm()">
            {{'TRADUCAO'| translate: values.component.btnSubmit}}
        </button>
    </div>
</ng-template>