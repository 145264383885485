import { InputTypeEnum } from "../shared-models/enums/input-type.enum";

export class Attribute {
    name: string;
    title: any;
    inputType?: InputTypeEnum;
    maxLength?: number;
}

export type AttributeDict = { [inputName: string]: Attribute };

export const GlobalAttributeValues: AttributeDict = {

    naturezaAnalise: {
        name: 'naturezaAnalise',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'Natureza da análise',
            en: 'Nature of analysis',
            es: 'Naturaleza del análisis'
        }
    } as Attribute,

    tipoClassificacao: {
        name: 'tipoClassificacao',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'Tipo de classificação',
            en: 'Classification type',
            es: 'Tipo de clasificación'
        }
    } as Attribute,

    classificacaoPretendida: {
        name: 'classificacaoPretendida',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'Classificação pretendida',
            en: 'Intended classification',
            es: 'Clasificación prevista'
        }
    } as Attribute,

    tituloBrasil: {
        name: 'tituloBrasil',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Título no Brasil',
            en: 'Title in Brazil',
            es: 'Título en Brasil'
        }
    } as Attribute,

    tituloNacional: {
        name: 'tituloBrasil',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Título nacional',
            en: 'National title',
            es: 'Título nacional'
        }
    } as Attribute,

    tituloOriginal: {
        name: 'tituloOriginal',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Título original',
            en: 'Original title',
            es: 'Título original'
        }
    } as Attribute,

    dsComplementoIdentificacao: {
        name: 'dsComplementoIdentificacao',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Complemento de identificação',
            en: 'Identification Complement',
            es: 'Complemento de identificación'
        }
    } as Attribute,

    anoProducao: {
        name: 'anoProducao',
        inputType: InputTypeEnum.DATE_ONLY_YEAR,
        title: {
            pt: 'Ano de produção',
            en: 'Year of manufacture',
            es: 'Año de fabricación'
        }
    } as Attribute,

    paises: {
        name: 'paises',
        inputType: InputTypeEnum.MULTI_SELECT,
        title: {
            pt: 'País de origem',
            en: 'Country of origin',
            es: 'Pais de origen'
        }
    } as Attribute,

    midiasAnalise: {
        name: 'midiasAnalise',
        inputType: InputTypeEnum.MULTI_SELECT,
        title: {
            pt: 'Mídias de Análise',
            en: 'Analysis Media',
            es: 'Medios de análisis'
        }
    } as Attribute,

    dsComplementoTipoMidia: {
        name: 'dsComplementoTipoMidia',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Complemento do Tipo de mídia',
            en: 'Media Type Supplement',
            es: 'Suplemento de tipo de medio'
        }
    } as Attribute,

    linkMidiaExterna: {
        name: 'linkMidiaExterna',
        title: {
            pt: 'Link de internet',
            en: 'Internet link',
            es: 'Enlace de internet'
        }
    } as Attribute,

    urlLinkMidiaExterna: {
        name: 'urlLinkMidiaExterna',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Link de Internet',
            en: 'Internet link',
            es: 'Enlace de internet'
        }
    } as Attribute,

    loginLinkMidiaExterna: {
        name: 'loginLinkMidiaExterna',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Login',
            en: 'Login',
            es: 'Acceso'
        }
    } as Attribute,

    senhaLinkMidiaExterna: {
        name: 'senhaLinkMidiaExterna',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Senha',
            en: 'Password',
            es: 'Contraseña'
        }
    } as Attribute,

    autor: {
        name: 'autor',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Autor',
            en: 'Author',
            es: 'Autor'
        }
    } as Attribute,

    categoria: {
        name: 'categoria',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'Categoria',
            en: 'Category',
            es: 'Categoría'
        }
    } as Attribute,

    categoriaObra: {
        name: 'categoria',
        title: {
            pt: 'Categoria da obra',
            en: 'Work category',
            es: 'Categoría de trabajo'
        }
    } as Attribute,

    distribuidor: {
        name: 'distribuidor',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Distribuidor',
            en: 'Distributor',
            es: 'Distribuidor'
        }
    } as Attribute,

    criador: {
        name: 'criador',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Produtor / Criador',
            en: 'Producer / Creator',
            es: 'Productor / Creador'
        }
    } as Attribute,

    diretor: {
        name: 'diretor',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Diretor',
            en: 'Director',
            es: 'Director'
        }
    } as Attribute,

    dataEstreia: {
        name: 'dataEstreia',
        inputType: InputTypeEnum.DATE,
        title: {
            pt: 'Data prevista de estréia/Exibição',
            en: 'Expected premiere/Exhibition date',
            es: 'Fecha prevista de estreno/Exhibición'
        }
    } as Attribute,

    dataLancamento: {
        name: 'dataLancamento',
        inputType: InputTypeEnum.DATE,
        title: {
            pt: 'Data prevista de lançamento',
            en: 'Expected release date',
            es: 'Fecha de lanzamiento prevista'
        }
    } as Attribute,

    editora: {
        name: 'editora',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Editora',
            en: 'Publishing company',
            es: 'Compañia de publicidad'
        }
    } as Attribute,

    elencoPrincipal: {
        name: 'elencoPrincipal',
        inputType: InputTypeEnum.TEXT_AREA,
        title: {
            pt: 'Elenco principal',
            en: 'Main cast',
            es: 'Reparto principal'
        }
    } as Attribute,

    emissora: {
        name: 'emissora',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'Emissora',
            en: 'Broadcaster',
            es: 'Emisora'
        }
    } as Attribute,

    pracaExibicao: {
        name: 'pracaExibicao',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'Praça de exibição',
            en: 'Exhibition square',
            es: 'plaza de exposiciones'
        }
    } as Attribute,

    nrEpisodios: {
        name: 'nrEpisodios',
        inputType: InputTypeEnum.NUMBER,
        title: {
            pt: 'Número de episódios',
            en: 'Number of episodes',
            es: 'Número de episodios'
        }
    } as Attribute,

    tempoDuracao: {
        name: 'tempoDuracao',
        inputType: InputTypeEnum.NUMBER,
        title: {
            pt: 'Tempo de duração (min)',
            en: 'Running time (min)',
            es: 'Tiempo de funcionamiento (min)'
        }
    } as Attribute,

    tempoDuracaoEpisodio: {
        name: 'tempoDuracaoEpisodio',
        inputType: InputTypeEnum.NUMBER,
        title: {
            pt: 'Tempo de duração por episódios (min)',
            en: 'Running time per episodes (min)',
            es: 'Tiempo de ejecución por episodios (min)'
        }
    } as Attribute,

    temporada: {
        name: 'temporada',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Temporadas',
            en: 'Seasons',
            es: 'Estaciones'
        }
    } as Attribute,

    tipoAnuncio: {
        name: 'tipoAnuncio',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'O jogo foi anunciado publicamente?',
            en: 'Was the game publicly announced?',
            es: '¿Se anunció públicamente el juego?'
        },
    } as Attribute,

    plataformas: {
        name: 'plataformas',
        inputType: InputTypeEnum.MULTI_SELECT,
        title: {
            pt: 'Plataformas',
            en: 'Platforms',
            es: 'Plataformas'
        }
    } as Attribute,

    dsComplementoPlataforma: {
        name: 'dsComplementoPlataforma',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Complemento de Plataforma',
            en: 'Platform Complement',
            es: 'Complemento de plataforma'
        }
    } as Attribute,

    paginas: {
        name: 'paginas',
        inputType: InputTypeEnum.NUMBER,
        title: {
            pt: 'Páginas',
            en: 'Pages',
            es: 'Paginas'
        }
    } as Attribute,

    periodicidade: {
        name: 'periodicidade',
        inputType: InputTypeEnum.SINGLE_SELECT,
        title: {
            pt: 'Periodicidade da obra',
            en: 'Periodicity of the work',
            es: 'Periodicidad de la obra'
        }
    } as Attribute,

    diasSemana: {
        name: 'diasSemana',
        inputType: InputTypeEnum.MULTI_SELECT_WITH_DESACTIVE_MODE,
        title: {
            pt: 'Dias da semana',
            en: 'Days of the week',
            es: 'Dias de la semana'
        }
    } as Attribute,

    generos: {
        name: 'generos',
        title: {
            pt: 'Gêneros',
            en: 'Genres',
            es: 'Géneros'
        }
    } as Attribute,

    generoPrograma: {
        name: 'generoPrograma',
        title: {
            pt: 'Gênero de programa',
            en: 'Show genre',
            es: 'Género del programa'
        }
    } as Attribute,

    segmentoMercado: {
        name: 'segmentoMercado',
        title: {
            pt: 'Segmento de mercado',
            en: 'Market segment',
            es: 'Segmento de mercado'
        }
    } as Attribute,

    protocolo: {
        name: 'protocolo',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Protocolo',
            en: 'Protocol',
            es: 'Protocolo'
        }
    } as Attribute,

    idRelatorioSei: {
        name: 'idRelatorioSei',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'ID Relatório SEI',
            en: '',
            es: ''
        }
    } as Attribute,

    idDocumentoPublicacaoSei: {
        name: 'idDocumentoPublicacaoSei',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'ID Documento Publicação SEI',
            en: '',
            es: ''
        }
    } as Attribute,

    tipoDocumento: {
        name: 'tipoDocumento',
        title: {
            pt: 'Tipo de documento',
            en: 'Document type',
            es: 'Tipo de documento',
        }
    } as Attribute,

    classificacao: {
        name: 'classificacao',
        title: {
            pt: 'Classificação',
            en: 'Classification',
            es: 'Clasificación'
        }
    } as Attribute,

    classificacaoAtribuida: {
        name: 'classificacaoAtribuida',
        title: {
            pt: 'Classificação Atribuída',
            en: 'Assigned Classification',
            es: 'Calificación Asignada'
        }
    } as Attribute,

    descritoresConteudos: {
        name: 'descritoresConteudos',
        title: {
            pt: 'Descritores de Conteúdo',
            en: 'Content Descriptors',
            es: 'Descriptores de Contenido'
        }
    } as Attribute,

    descritoresConteudoAtribuidos: {
        name: 'descritoresConteudoAtribuidos',
        title: {
            pt: 'Descritores de Conteúdo Atribuídos',
            en: 'Attributed Content Descriptors',
            es: 'Descriptores de Contenido Atribuidos'
        }
    } as Attribute,

    horarioRecomendado: {
        name: 'horarioRecomendado',
        title: {
            pt: 'Horário Recomendado',
            en: 'Recommended Time',
            es: 'Tiempo Recomendado'
        }
    } as Attribute,

    nmTituloObraSerie: {
        name: 'nmTituloObraSerie',
        title: {
            pt: 'Título da série',
            en: 'Series title',
            es: 'Título de la serie'
        }
    } as Attribute,

    dsObservacaoProcessoObra: {
        name: 'dsObservacaoProcessoObra',
        inputType: InputTypeEnum.TEXT_AREA,
        maxLength: 6000,
        title: {
            pt: 'Observações',
            en: 'Comments',
            es: 'Comentarios'
        }
    } as Attribute,

    /* Pessoa/Requerente */

    nome: {
        name: 'nome',
        title: {
            pt: 'Nome',
            en: 'Name',
            es: 'Nombre'
        }
    } as Attribute,

    email: {
        name: 'email',
        title: {
            pt: 'Email',
            en: 'Email',
            es: 'Correo electrónico'
        }
    } as Attribute,

    telefone: {
        name: 'telefone',
        title: {
            pt: 'Telefone',
            en: 'Phone',
            es: 'Teléfono'
        }
    } as Attribute,

    codigoPostal: {
        name: 'codigoPostal',
        title: {
            pt: 'CEP',
            en: 'ZIP code',
            es: 'código postal'
        }
    } as Attribute,

    endereco: {
        name: 'endereco',
        title: {
            pt: 'Endereço',
            en: 'Address',
            es: 'Dirección'
        }
    } as Attribute,

    numero: {
        name: 'numero',
        title: {
            pt: 'Número',
            en: 'Number',
            es: 'Número'
        }
    } as Attribute,

    bairro: {
        name: 'bairro',
        title: {
            pt: 'Bairro',
            en: 'Neighborhood',
            es: 'Vecindario'
        }
    } as Attribute,

    cidade: {
        name: 'cidade',
        title: {
            pt: 'Cidade',
            en: 'City',
            es: 'Ciudad'
        }
    } as Attribute,

    estado: {
        name: 'estado',
        title: {
            pt: 'UF',
            en: 'State',
            es: 'Estado'
        }
    } as Attribute,

    pais: {
        name: 'pais',
        title: {
            pt: 'País',
            en: 'Country',
            es: 'País'
        }
    } as Attribute,

    elementosInterativos: {
        name: 'elementosInterativos',
        inputType: InputTypeEnum.MULTI_SELECT,
        title: {
            pt: 'Elementos Interativos',
            en: 'Interactive Elements',
            es: 'Elementos interactivos'
        }
    } as Attribute,


    /* Publicacao */

    dataPortaria: {
        name: 'dataPortaria',
        inputType: InputTypeEnum.DATE,
        title: {
            pt: 'Data assinatura',
            en: 'Signature date',
            es: 'Fecha de firma'
        }
    } as Attribute,

    numeroPortaria: {
        name: 'numeroPortaria',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Nº documento',
            en: 'Document number',
            es: 'Número del Documento'
        }
    } as Attribute,

    dataDou: {
        name: 'dataDou',
        inputType: InputTypeEnum.DATE,
        title: {
            pt: 'Data DOU',
            en: 'DOU date',
            es: 'Fecha DOU'
        }
    } as Attribute,

    secaoIPagina: {
        name: 'secaoIPagina',
        inputType: InputTypeEnum.TEXT,
        title: {
            pt: 'Seção I / Página',
            en: 'Section I / Page',
            es: 'Sección I / Página'
        }
    } as Attribute,

    dataFinalizacao: {
        name: 'dataFinalizacao',
        inputType: InputTypeEnum.DATE,
        title: {
            pt: 'Data finalização',
            en: 'Completion date',
            es: 'Fecha de terminación'
        }
    } as Attribute,

    tipoPublicacao: {
        name: 'tipoPublicacao',
        title: {
            pt: 'Tipo de Publicação',
            en: 'Publication Type',
            es: 'Tipo de Publicación'
        }
    } as Attribute,

}
