import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Values } from 'src/app/shared/shared-models/class/values';
import { DialogoPreparacaoComponent, DialogoPreparacaoModel } from '../../../shared-components/dialog/dialogo-preparacao/dialogo-preparacao.component';
import { SpinnerVinhetaValues } from './spinner-vinheta-values';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    dialogRef: MatDialogRef<DialogoPreparacaoComponent>;
    values: Values;
    dialogData;

    constructor(
        private dialog: MatDialog,
    ) {
        this.values = {
            component: SpinnerVinhetaValues
        };
    }

    startSpinnerVinheta(msg: string = null) {
        this.dialogData = new DialogoPreparacaoModel(this.values.component[msg]);
        setTimeout(() => {
            this.dialogRef = this.dialog.open(DialogoPreparacaoComponent, {
                width: '260px',
                data: this.dialogData,
                backdropClass: "bdrop"
            });
        }, 0);
    }

    startSpinnerVinhetaImediato(msg: string = null) {
        this.dialogData = new DialogoPreparacaoModel(this.values.component[msg]);
        this.dialogRef = this.dialog.open(DialogoPreparacaoComponent, {
            width: '260px',
            data: this.dialogData,
            backdropClass: "bdrop"
        });
    }

    endSpinnerVinheta(timeOut: number) {
        setTimeout(() => {
            this.dialogRef.close();
        }, timeOut);
    }
}
