import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CONST_ROUTER_UTIL } from 'src/app/shared/shared-utils/consts/const-router-util';

@Injectable()
export class RedirectService {

    constructor(private router: Router) { }

    goToPrivateArea(): void {
        this.goTo(this.returnRotaRaiz());
    }

    returnRotaRaiz(): UrlTree {
        return this.router.parseUrl(CONST_ROUTER_UTIL.ROTA_RAIZ);
    }

    private goTo(dest: string | UrlTree): void {
        this.router.navigateByUrl(dest);
    }

}
