import { BaseResourceModel } from "./base-resource.model";

export class PublicacaoDou extends BaseResourceModel {
    constructor(
        public id?: number,
        public dataPortaria?: string,
        public numeroPortaria?: string,
        public dataDou?: string,
        public secaoIPagina?: string,
        public documentos?: [],
        public idSolicitacaoClassificacao?: string,
        public dataFinalizacao?: string,
        // not persisted
        public tipoPublicacao?: string
    ) {
        super(id);
    }

    static fromJson(jsonData: any): PublicacaoDou {
        return Object.assign(new PublicacaoDou(), jsonData);
    }
}
