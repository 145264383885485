import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-recuperar-senha',
    templateUrl: './dialog-recuperar-senha.component.html',
    styleUrls: ['./dialog-recuperar-senha.component.scss']
})
export class DialogRecuperarSenhaComponent {

    email: string;

    constructor(public dialogRef: MatDialogRef<DialogRecuperarSenhaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogRecuperarSenhaModel) {
        dialogRef.disableClose = true;
    }

    fechar() {
        this.dialogRef.close();
    }
}

export class DialogRecuperarSenhaModel {
    constructor(public email: string) {
    }
}
