import { PessoaOrigemEnum } from '../enums/pessoa-origem.enum';
import { SituacaoPadraoEnum } from '../enums/situacao-padrao.enum';
import { BaseResourceModel } from './base-resource.model';
import { Endereco } from './endereco.model';

export class Pessoa extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public numeroDocumento?: string,
        public numeroTelefone?: string,
        public idKeycloak?: string,
        public situacao?: SituacaoPadraoEnum,
        public endereco?: Endereco,
        public email?: string,
        public isRequerente?: boolean,
        public tipoInsercao?: string,
        public origem?: PessoaOrigemEnum,
    ) {
        super(id);
    }

    static fromJson(jsonData: any): Pessoa {
        return Object.assign(new Pessoa(), jsonData);
    }
}
