import { MatPaginatorIntl } from '@angular/material/paginator';

export function getPortuguesPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    paginatorIntl.nextPageLabel = 'Próxima página';
    paginatorIntl.previousPageLabel = 'Página anterior';
    paginatorIntl.firstPageLabel = "Primeira página";
    paginatorIntl.lastPageLabel = "Última página";
    // tslint:disable-next-line: no-use-before-declare
    paginatorIntl.getRangeLabel = portugueseRangeLabel;

    return paginatorIntl;
}

const portugueseRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) { return `0 de ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} de ${length}`;
};
