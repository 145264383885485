import { Injectable, Injector } from '@angular/core';
import { Periodicidade } from '../../shared-models/model/periodicidade.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class PeriodicidadeResourceService extends BaseResourceService<Periodicidade>{

    constructor(protected injector: Injector) {
        super('/periodicidades', injector, Periodicidade.fromJson);
    }
}
