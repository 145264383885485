import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceService } from './base-resource.service';
import { AnexoSolicitacaoClassificacao } from './../../shared-models/model/anexo-solicitacao-classificacao.model';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';
import { ArquivoDTO } from '../../shared-models/dto/arquivo-dto';

@Injectable({
    providedIn: 'root'
})
export class AnexoSolicitacaoClassificacaoResourceService extends BaseResourceService<AnexoSolicitacaoClassificacao> {

    constructor(protected injector: Injector) {
        super('/anexos-solicitacao', injector, AnexoSolicitacaoClassificacao.fromJson);
    }

    save(dto: AnexoSolicitacaoClassificacao) {
        return this.create(dto);
    }

    getArquivoById(id: number): Observable<ArquivoDTO> {
        return this.http.get<ArquivoDTO>(`${this.urlResource}/${id}`);
    }

    getAllBySolicitacaoClassificacaoId(dto: any, solicitacaoClassificacaoId: number): Observable<MensagemRetorno> {
        let searchPage = `?currentPage=${dto.currentPage}&id=${solicitacaoClassificacaoId}&pageSize=${dto.pageSize}`;
        return this.http.get<MensagemRetorno>(`${this.urlResource}${searchPage}`);
    }
}
