export enum SpinnerVinhetaKeys {

    LOGIN = 'login',
    ACESSO_DADOS = 'acessoDados',
    ENVIANDO_DADOS = 'enviandoDados',
    EXCLUINDO_DADOS = 'excluindoDados',
    ACESSANDO_ARQUIVOS = 'acessandoArquivos',
    ENVIANDO_ARQUIVO = 'enviandoArquivo',
    EXCLUINDO_ARQUIVO = 'excluindoArquivo',
    FINALIZANDO_PROCESSO = 'finalizandoProcesso',
    BUSCANDO_ENDERECO = 'buscandoEndereco',
    SINCRONIZANDO_MECANISMO = 'sincronizandoMecanismo',
    ADICIONANDO_REPRESENTANTE = 'adicionandoRepresentante',
    CONFIGURANDO_USUARIO_ADM = 'configurandoUsuarioADM',
    ATUALIZANDO_STATUS = 'atualizandoStatus',
    PROTOCOLANDO_SEI = 'protocolandoSEI',
    GERANDO_PORTARIA = 'gerandoPortaria',
    ATUALIZANDO_PUBLICACAO = 'atualizandoPublicacao',
    KEYCLOAK_AUSENTE = 'keycloakAusente',
    GERANDO_ARQUIVO = 'gerandoArquivo',
    ENVIANDO_CORRECAO = 'enviandoCorrecao'
}
