import { Injectable, Injector } from '@angular/core';
import { Plataforma } from '../../shared-models/model/plataforma.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class PlataformaResourceService extends BaseResourceService<Plataforma> {

    constructor(protected injector: Injector) {
        super('/plataformas', injector, Plataforma.fromJson);
    }
}
