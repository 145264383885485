<div [formGroup]="formGroup">
    <mat-form-field class="full-width" appearance="legacy">
        <mat-label>
            {{title}}<span class="required">{{ isRequired ? ' *' : ''}}</span>
        </mat-label>
        
        <textarea matInput #message [maxlength]=maxlength [rows]="rows" [placeholder]="placeholder" [name]="controlName"
            [formControlName]="controlName">
        </textarea>
        <mat-hint align="end">{{message.value.length}} / {{maxlength}}</mat-hint>
        
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl,'required')">
            {{ 'TRADUCAO' | translate: validatorMessageValues.validator.required }}
        </mat-error>
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl, 'minlength')">
            {{validatorMessageValues.validator.minLength}} {{fieldValidator.lengthValidate(formControl, 'minlength')}}
        </mat-error>
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl, 'maxlength')">
            {{validatorMessageValues.validator.maxlength}} {{fieldValidator.lengthValidate(formControl, 'maxlength')}}
        </mat-error>
        <mat-error *ngIf="fieldValidator.hasErrorValidate(formControl, 'pattern')">
            {{validatorMessageValues.validator.pattern}}
        </mat-error>
    </mat-form-field>
</div>
