import { MessageStatusEnum } from "./message-status.enum";

export const MessageStatusValues = {

    [MessageStatusEnum.SUCCESS]: {
        pt: 'OK',
        en: 'OK',
        es: 'OK'
    },

    [MessageStatusEnum.ERROR]: {
        pt: 'Erro',
        en: 'Error',
        es: 'Error'
    },

    [MessageStatusEnum.ALERT]: {
        pt: 'Alerta',
        en: 'Alert',
        es: 'Alerta'
    },

    [MessageStatusEnum.INFO]: {
        pt: 'Informativa',
        en: 'Informative',
        es: 'Informativo'
    },

}