import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { GlobalValues } from 'src/app/shared/shared-values/global-values';
import { Values } from '../../../shared-models/class/values';
import { ModeloTextoCustomizadoEnum } from '../../../shared-models/enums/modelo-texto-customizado.enum';
import { MensageriaService } from '../../../shared-services/mensageria-service';
import { NavegacaoUtilService } from '../../../shared-services/navegacao-util.service';
import { TermoService } from '../../../shared-services/termo.service';

@Component({
    selector: 'app-dialog-termo-responsabilidade',
    templateUrl: './dialog-termo.component.html',
    styleUrls: ['./dialog-termo.component.scss']
})
export class DialogTermoComponent implements OnInit {

    readonly TERMO_RESPONSABILIDADE_REF = ModeloTextoCustomizadoEnum.TERMO_RESPONSABILIDADE;
    
    values: Values;
    allDataLoaded: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<DialogTermoComponent>,
        private navegacaoUtilService: NavegacaoUtilService,
        private termoService: TermoService,
        private mensageriaService: MensageriaService,
    ) { }

    ngOnInit(): void {
        this.values = {
            global: GlobalValues
        }
        this.allDataLoaded = false;
    }

    onFinishEditor() {
        this.allDataLoaded = true;
    }

    accept() {
        this.allDataLoaded = false;

        this.termoService.createForLoggedUser(this.data.cnpj)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.mensageriaService.showSaveSuccess();
                    this.allDataLoaded = true;
                    this.dialogRef.close();
                },
                (errorResponse: HttpErrorResponse) => {
                    this.navegacaoUtilService.toHome();
                    this.mensageriaService.handleApiError(errorResponse);
                }
            );
    }

    deny() {
        this.dialogRef.close();
        this.navegacaoUtilService.toLogin();
    }

}
