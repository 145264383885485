import { ElementoInterativo } from 'src/app/shared/shared-models/model/elemento-interativo.model';
import { AnexoSolicitacaoClassificacaoDTO } from '../dto/anexo-solicitacao-classificacao-dto';
import { SolicitacaoClassificacaoPendencyDTO } from './../dto/solicitacao-classificacao-pendency.dto';
import { BaseResourceModel } from './base-resource.model';
import { Categoria } from './categoria.model';
import { Classificacao } from './classificacao.model';
import { DescritorConteudo } from './descritor-conteudo.model';
import { DiaSemana } from './dia-semana.model';
import { Emissora } from './emissora.model';
import { GeneroPrograma } from './genero-programa.model';
import { Genero } from './genero.model';
import { LinkMidiaExterna } from './link-midia-externa.model';
import { NaturezaAnalise } from './natureza-analise.model';
import { Pais } from './pais.model';
import { Periodicidade } from './periodicidade.model';
import { Pessoa } from './pessoa.model';
import { Plataforma } from './plataforma.model';
import { PracaExibicao } from './praca-exibicao.model';
import { ResponsavelLegado } from './responsavel-legado.model';
import { SegmentoMercado } from './segmento-mercado.model';
import { StatusSolicitacaoClassificacao } from './status-solicitacao-classificacao.model';
import { TipoAnuncio } from './tipo-anuncio.model';
import { TipoClassificacao } from './tipo-classificacao.model';
import { TipoMidia } from './tipo-midia.model';

export class SolicitacaoClassificacao extends BaseResourceModel {
    constructor(
        public tituloBrasil?: string,
        public tituloOriginal?: string,
        public dsComplementoIdentificacao?: string,
        public temporada?: string,
        public protocolo?: string,
        public nrEpisodios?: number,
        public anoProducao?: number,
        public distribuidor?: string,
        public criador?: string,
        public diretor?: string,
        public elencoPrincipal?: string,
        public tempoDuracao?: number,
        public dataCadastro?: Date,
        public dataEstreia?: Date,
        public categoria?: Categoria,
        public classificacaoAtribuida?: Classificacao,
        public classificacaoPretendida?: Classificacao,
        public emissora?: Emissora,
        public pracaExibicao?: PracaExibicao,
        public periodicidade?: Periodicidade,
        public diasSemana?: DiaSemana[],
        public pessoa?: Pessoa,
        public tipoClassificacao?: TipoClassificacao,
        public naturezaAnalise?: NaturezaAnalise,
        public segmentoMercado?: SegmentoMercado,
        public paises?: Pais[],
        public midiasAnalise?: TipoMidia[],
        public dsComplementoTipoMidia?: string,
        public generos?: Genero[],
        public statusSolicitacaoClassificacao?: StatusSolicitacaoClassificacao,
        public tipoAnuncio?: TipoAnuncio,
        public plataformas?: Plataforma[],
        public descritoresConteudos?: DescritorConteudo[],
        public dsComplementoPlataforma?: string,
        public editora?: string,
        public autor?: string,
        public paginas?: number,
        public anexos?: AnexoSolicitacaoClassificacaoDTO[],
        public linkMidiaExterna?: LinkMidiaExterna,
        public tempoDuracaoEpisodio?: number,
        public generoPrograma?: GeneroPrograma,
        public elementosInterativos?: ElementoInterativo[],
        public responsavelLegado?: ResponsavelLegado,
        public isOrigemLegado?: boolean,
        // not persisted
        public podeSolicitarReconsideracao?: boolean,
        public pendency?: SolicitacaoClassificacaoPendencyDTO,
        public isRevisao?: boolean,
        public isConsultaPublica?: boolean,
        public dsObservacaoProcessoObra?: string,
        public nmTituloObraSerie?: string
    ) {
        super();
    }

    static fromJson(jsonData: any): SolicitacaoClassificacao {
        return Object.assign(new SolicitacaoClassificacao(), jsonData);
    }
}
