import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { MensagemRetorno } from '../../shared-models/model/mensagem-retorno';
import { ModeloPortaria } from '../../shared-models/model/modelo-portaria.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class ModeloPortariaResourceService extends BaseResourceService<ModeloPortaria> {

    constructor(protected injector: Injector) {
        super('/modelos-portaria', injector, ModeloPortaria.fromJson);
    }

    getModelosPortariaBySegmento(id: number): Observable<MensagemRetorno> {
        return this.http.get<MensagemRetorno>(`${this.urlResource}/modelos-segmentos/${id}`);
    }
}
