import { Injectable, Injector } from '@angular/core';
import { TipoAnuncio } from '../../shared-models/model/tipo-anuncio.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class TipoAnuncioResourceService extends BaseResourceService<TipoAnuncio>{

    constructor(protected injector: Injector) {
        super('/tipos-anuncios', injector, TipoAnuncio.fromJson);
    }
}
