import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { ValidatorMessageValues } from '../../constants/validator-message-values';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { FieldValidatorService } from '../field-validator.service';

@Component({
    selector: 'app-input-multi-select',
    templateUrl: './input-multi-select.component.html',
    styleUrls: ['../abstract-input/abstract-input.component.scss']
})
export class InputMultiSelectComponent extends AbstractInputComponent {

    @Input() titulo: string;
    @Input() atributo: string = 'nome';
    @Input() opcoes: any[] = [];
    @Input() valueAttribute: string;
    @Input() maxSelect: number = 0;
    @Input() allOptionLabel = null;
    @Input() maxNumSelectedOptions: number = null;

    @Output() notificaSelecao = new EventEmitter();
    @Output() matOptionDataClickedEmmiter = new EventEmitter();

    validatorMessageValues: any;
    allOption: any;

    private readonly _ALL_OPTION_VALUE = 'todas';

    @ViewChild('allOption') 
    private _allMatOption: MatOption;

    private _allSelected = false;

    constructor(public fieldValidator: FieldValidatorService) {
        super();
        this.validatorMessageValues = ValidatorMessageValues;
    }

    ngOnInit() {
        this.titulo = this.titulo || this.title;
        if(this.allOptionLabel) {
            this._setAllOption();
        }
    }

    private _setAllOption() {
        this.allOption = {
            id: this._ALL_OPTION_VALUE,
            nome: this._ALL_OPTION_VALUE
        }

        if(this.atributo) {
            this.allOption[this.atributo] = this._ALL_OPTION_VALUE;
        }
        
        if(this.valueAttribute) {
            this.allOption[this.valueAttribute] = this._ALL_OPTION_VALUE;
        }
    }

    getOptionValue(option: any) {
        if(option && this.valueAttribute) {
            return option[this.valueAttribute];
        }
        return option;
    }

    comparePerfis(estatico, contexto) {
        return contexto && estatico.id === contexto.id;
    }

    onChangeSelection($eventValues: any) {
        if(this._allSelected) {
            this.formControl.setValue([]);
            this.notificaSelecao.emit([]);
            this._allSelected = false;
            return;
        }

        if(this.allOptionLabel && $eventValues.includes(this._ALL_OPTION_VALUE)) {
            this.formControl.setValue(this.opcoes.concat([this._ALL_OPTION_VALUE]));
            this.notificaSelecao.emit([this._ALL_OPTION_VALUE]);
            this._allSelected = true;
            return;
        }

        this._allSelected = false;
        this.notificaSelecao.emit($eventValues);
    }

    get allOptionSelected() {
        try {
            return !!this._allMatOption && this._allMatOption.selected;
        } catch (error) {
            return false;
        }
    }

    captureMatOptionClicked(matOption: MatOption) {
        this.matOptionDataClickedEmmiter.emit(matOption);
    }
}
