<h1 mat-dialog-title>Password Recovery</h1>

<div mat-dialog-content>
    <mat-form-field class="container" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="data.email" type="email" maxlength="60">
    </mat-form-field>
</div>

<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="280px" mat-dialog-actions>
    <div fxLayoutAlign="space-evenly center">
        <button mat-raised-button color="primary" [mat-dialog-close]="data.email" cdkFocusInitial
            [disabled]="data.email.length < 7">
            <em class="material-icons icon-card">mail</em> Recover Password</button>
    </div>
    <div fxLayoutAlign="space-evenly center">
        <button mat-raised-button (click)="fechar()">
            <em class="material-icons icon-card">keyboard_arrow_left</em>Back
        </button>
    </div>
</div>
