import { Injectable, Injector } from '@angular/core';
import { NaturezaAnalise } from '../../shared-models/model/natureza-analise.model';
import { BaseResourceService } from './base-resource.service';

@Injectable({
    providedIn: 'root'
})
export class NaturezaAnaliseResourceService extends BaseResourceService<NaturezaAnalise>{

    constructor(protected injector: Injector) {
        super('/naturezas', injector, null);
    }
}
