export enum StatusSolicitacaoClassificacaoEnum {
    TRIAGEM = 1,
    EM_ANALISE = 2,
    INCOMPLETO = 3,
    DEVOLVIDO = 4,
    SOBRESTADO = 5,
    AGUARDANDO_ANALISE = 6,
    AGUARDANDO_PUBLICACAO = 7,
    AGUARDANDO_DOCUMENTO = 8,
    PUBLICADO = 9,
    AGUARDANDO_AUTORIZACAO = 10,
    LEGADO = 11,
    EM_RECONSIDERACAO = 12,
    PUBLICADO_RECONSIDERACAO = 13,
    AGUARDANDO_RECURSO = 14,
    PUBLICADO_RECURSO = 15,
    ARQUIVADO = 16,
    REVISAO_CLASSIFICACAO = 17,
}

export class StatusSolicitacaoClassificacaoHelper {

    static isPublicado(idStatus: number) {
        return this.getAllPublicado().includes(idStatus);
    }

    static isPublicadoOrLegado(idStatus: number) {
        return this.getAllPublicadoWithLegado().includes(idStatus);
    }

    static getAllPublicado() {
        return [
            StatusSolicitacaoClassificacaoEnum.PUBLICADO,
            StatusSolicitacaoClassificacaoEnum.PUBLICADO_RECONSIDERACAO,
            StatusSolicitacaoClassificacaoEnum.PUBLICADO_RECURSO,
        ];
    }

    static getAllPublicadoWithLegado() {
        return this.getAllPublicado().concat(
            StatusSolicitacaoClassificacaoEnum.LEGADO
        );
    }

}
