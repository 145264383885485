import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResourceService } from './base-resource.service';
import { HistoricoDescritorConteudo } from './../../shared-models/model/historico-descritor-conteudo.model';


@Injectable({
    providedIn: 'root'
})
export class HistoricoDescritorConteudoResourceService extends BaseResourceService<HistoricoDescritorConteudo> {

    constructor(protected injector: Injector) {
        super('/historico-descritores-conteudo', injector, HistoricoDescritorConteudo.fromJson);
    }

    getAllBySolicitacaoClassificacao(idSolicitacaoClassificacao: number): Observable<HistoricoDescritorConteudo[]> {
        return this.http.get<HistoricoDescritorConteudo[]>(
            `${this.urlResource}/solicitacao-classificacao/${idSolicitacaoClassificacao}`
        );
    }
}
