import { Injectable } from '@angular/core';
import { SessionStorageKey } from 'src/app/shared/shared-models/enums/session-storage-key.enum';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    /**
     * Armazenar informação no LocalStorage
     *
     * @param key - Chave
     * @param value  - Valor que será armazenado
     */
    storage(key: SessionStorageKey, value: string): void {
        localStorage.setItem(key, value);
    }

    /**
     * Recuperar item do LocalStorage
     *
     * @param key - Chave do Item
     */
    getItem(key: SessionStorageKey): string {
        return localStorage.getItem(key);
    }

    /**
     * Remover item do LocalStorage
     *
     * @param key - Chave do Item
     */
    removeItem(key: SessionStorageKey): void {
        localStorage.removeItem(key);
    }

    /**
     * Limpar LocalStorage
     */
    clear(): void {
        localStorage.clear();
    }
}
