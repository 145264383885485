import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogJustificationModel } from './dialog-justification.model';

@Component({
    selector: 'app-dialog-justification',
    templateUrl: 'dialog-justification.component.html',
    styleUrls: []
})
export class DialogJustificationComponent implements OnInit {

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<DialogJustificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogJustificationModel,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this._initForm();
    }

    private _initForm() {
        this.form = this.formBuilder.group({
            justificativa: [this.data.content, [ Validators.required, Validators.maxLength(this.data.maxLength) ]]
        })
    }

    save() {
        let control = this.form.controls.justificativa;
        control.markAsTouched();

        if (this.form.invalid) {
            return;
        }

        let justificativa: string = control.value;
        if (!justificativa.trim()) {
            control.reset();
            return;
        }

        this.dialogRef.close(justificativa);
    }

}

