import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { RedirectService } from '../services/redirect.service';
import { AuthTokenService } from './auth-token.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { InformacaoSistema } from '../../shared/shared-models/model/informacao-sistema';
import { Usuario } from '../../shared/shared-models/model/usuario';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {


    constructor(
        @Inject('InformacaoSistema') private sistema: BehaviorSubject<InformacaoSistema>,
        protected authTokenService: AuthTokenService,
        protected redirectService: RedirectService,
        protected router: Router) {
    }

    isAccessAllowed(): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (this.authTokenService.isExpired()) {
                this.authTokenService.getTokenNaSessionStorage();
            } else {
                return this.redirectService.returnRotaRaiz();
            }
            const usuario: Usuario = this.authTokenService.setarUsuarioDaLocalStorage();
            this.configInformacoesSistema(usuario);
            resolve(true);
        });
    }

    configInformacoesSistema(usuario: Usuario) {
        if (usuario) {
            const informacoes = {
                nome: usuario.nome,
                nomeExibido: usuario.nomeExibido,
                matricula: usuario.matricula,
                cnpjs: usuario.cnpjs,
                sistema: 'CLASSIND',
                sistemaDescricao: 'Sistema de Classificação Indicativa',
                versaoBackend: '1.0.0',
                versaoFrontend: '1.0.0',
            } as any;
            this.sistema.next(informacoes);
        }
    }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean
        | UrlTree> | boolean | UrlTree {
        return this.isAccessAllowed();
    }

    canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean
        | UrlTree> | boolean | UrlTree {
        return this.isAccessAllowed();
    }


}
