import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DialogAlertModel, DialogAlertComponent } from '../../shared-components/dialog/dialog-alert/dialog-alert.component';
import { DialogoConfirmacaoComponent } from '../../shared-components/dialog/dialogo-confirmacao/dialogo-confirmacao.component';
import { DialogoInformacaoComponent, DialogoInformacaoModel } from '../../shared-components/dialog/dialogo-informacao/dialogo-informacao.component';
import { DialogJustificationModel } from '../../shared-components/dialog/dialog-justification/dialog-justification.model';
import { DialogJustificationComponent } from '../../shared-components/dialog/dialog-justification/dialog-justification.component';
import { AdaptiveDataModel, AdaptiveDialogComponent } from '../../shared-components/dialog/adaptive-dialog/adaptive-dialog.component';

const MAX_WIDTH = '500px';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private dialog: MatDialog) { }

    getMatDialog() {
        return this.dialog;
    }

    closeAll() {
        this.dialog.closeAll();
    }

    openAlertDialog(data: DialogAlertModel) {
        return this.dialog.open(DialogAlertComponent, {
            maxWidth: MAX_WIDTH,
            data: data
        });
    }

    openInfoDialog(data: DialogoInformacaoModel) {
        return this.dialog.open(DialogoInformacaoComponent, {
            maxWidth: MAX_WIDTH,
            data: data
        });
    }

    openConfirmationDialog(data: any) {
        return this.dialog.open(DialogoConfirmacaoComponent, {
            maxWidth: MAX_WIDTH,
            data: data
        });
    }

    openConfirmationDialogWithCallback(data: any, callback: Function, callbackArgs: any[] = null) {
        let dialogRef = this.openConfirmationDialog(data);
        this._setDialogRefCloseCallback(dialogRef, callback, callbackArgs);
    }

    private _setDialogRefCloseCallback(dialogRef: MatDialogRef<any>, callback: Function, callbackArgs: any[]) {
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            callback(callbackArgs);
        });
    }

    openJustificationDialog(data: DialogJustificationModel) {
        return this.dialog.open(DialogJustificationComponent, {
            maxWidth: '500px',
            data: data
        });
    }

    openJustificationDialogWithCallback(data: DialogJustificationModel, callback: Function) {
        let dialogRef = this.openJustificationDialog(data);
        dialogRef.afterClosed().subscribe(justificativa => {
            if (!justificativa) {
                return;
            }
            callback(justificativa);
        });
    }

    openCustomDialog<T>(component: ComponentType<T>, data: any) {
        return this.dialog.open(component, {
            maxWidth: MAX_WIDTH,
            data: data
        });
    }

    openAdaptativeDialog(data: AdaptiveDataModel) {
        return this.openCustomDialog(AdaptiveDialogComponent, data);
    }

}
