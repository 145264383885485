import { BaseResourceModel } from "./base-resource.model";

export class DescritorConteudo extends BaseResourceModel {
    constructor(
        public nome?: string,
        public descricao?: string,
        public situacao?: string,
        public permanente?: boolean
    ) {
        super();
    }

    static fromJson(jsonData: any): DescritorConteudo {
        return Object.assign(new DescritorConteudo(), jsonData);
    }
}
